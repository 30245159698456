import { 
    BANNER_DATA,
    BANNER_PHOTO
} from '../types';

const initialState = {
    loading: false,
    data: [],
    photos: []
}

const bannersReducer = (state = initialState, action) => {
    switch(action.type) {
        case BANNER_DATA:
            return {
                ...state,
                data: action.payload,
                loading: action.loading
            }
            break;
        case BANNER_PHOTO:
            return {
                ...state,
                photos: action.payload,
                loading: action.loading
            }
            break;
        default:
            return state;
            break;
    }
}   

export default bannersReducer;