import React from "react";
import Header from "components/Headers/AdminHeader";

import {
    Card,
    CardHeader,
    CardBody,
    Container,
    Row,
    Col
} from "reactstrap";

import { FaUsers, FaRegFileImage, FaMoneyBillAlt } from 'react-icons/fa';
import { AiTwotoneShop } from 'react-icons/ai';

const Dashboard = (props) => {
    
    return (
        <div>
            <Header />
            <Container className="mt--7" fluid>
                <Row>
                    <Col lg="6" md="3" sm="12" xs="12">
                        <Card>
                            <CardHeader>
                                <h3>Revenue</h3>
                            </CardHeader>
                            <CardBody>
                                <center>
                                    <FaMoneyBillAlt size={100}/>
                                    <br/><br/>
                                    <h1>SAR 0</h1>
                                </center>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg="6" md="3" sm="12" xs="12">
                        <Card>
                            <CardHeader>
                                <h3>Total Users</h3>
                            </CardHeader>
                            <CardBody>
                                <center>
                                    <FaUsers size={100}/>
                                    <br/><br/>
                                    <h1>0</h1>
                                </center>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <br/>

                <Row>
                    <Col lg="6" md="3" sm="12" xs="12">
                        <Card>
                            <CardHeader>
                                <h3>Total Orders / day</h3>
                            </CardHeader>
                            <CardBody>
                                <center>
                                    <FaRegFileImage size={100}/>
                                    <br/><br/>
                                    <h1>0</h1>
                                </center>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg="6" md="3" sm="12" xs="12">
                        <Card>
                            <CardHeader>
                                <h3>Total Vendors</h3>
                            </CardHeader>
                            <CardBody>
                                <center>
                                    <AiTwotoneShop size={100}/>
                                    <br/><br/>
                                    <h1>0</h1>
                                </center>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                
            </Container>
        </div>
    );
}

export default Dashboard;