import React, { useEffect, useState } from "react";

import {
    Modal, 
    ModalHeader, 
    ModalBody, 
    ModalFooter,
    Button,
    Form, 
    FormGroup, 
    Label, 
    Input,
    Spinner,
    Row,
    Col,
    Badge
} from "reactstrap";
import { AiOutlineClose } from 'react-icons/ai';
import Resizer from "react-image-file-resizer";
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { getBannersByKey, editBanners, uploadImageItemOnly, deleteImage, deleteBanners } from 'redux/actions/bannersAction';

const Edit = (props) => {

    const { selected } = props;

    const dispatch = useDispatch();
    const banners = useSelector(state => state.banners);

    const [_key, setKey] = useState('');
    const [id, setID] = useState('');
    const [photos, setPhotos] = useState([]);
    const [title_en, setTitleEn] = useState('');
    const [title_ar, setTitleAr] = useState('');
    const [desc_en, setDescEn] = useState('');
    const [desc_ar, setDescAr] = useState('');
    const [options, setOptions] = useState([]);
    const [price, setPrice] = useState('');

    const [optionModal, setOptionModal] = useState(false);
    
    const [optionAction, setOptionAction] = useState('');
    const [optionLabelEn, setOptionLabelEn] = useState('');
    const [optionLabelAr, setOptionLabelAr] = useState('');
    const [optionList, setOptionList] = useState([
        {label_en: '', label_ar: '', value: ''}
    ]);
    
    const [btnDisabled, setDisabled] = useState(false);

    useEffect(() => {
        setKey(selected._key);
        setID(selected.data.id);
        setTitleEn(selected.data.title_en);
        setTitleAr(selected.data.title_ar);
        setDescEn(selected.data.desc_en);
        setDescAr(selected.data.desc_ar);
        setPrice(selected.data.price);
        setOptions(selected.data.options);

        dispatch(getBannersByKey(selected._key));

    }, [selected]);

    return (
        <Modal isOpen={props.isOpen} size="lg">
            <ModalHeader>Edit Banner</ModalHeader>
            <ModalBody>
                <Form>
                    <div className="flex-item" style={{backgroundColor: '#ecf0f1', marginBottom: 10, padding: 20, marginTop: 10, borderRadius: 10}}>
                        <FormGroup>
                            <Label>Photos</Label>
                            <Input 
                                type="file"
                                name=""
                                onChange={(e) => _onCropChange(e)}
                                bsSize="sm"
                                accept="image/*"
                                />
                            <br/>
                            {banners.photos.length !== 0 ? 
                                <Row>
                                    {banners.photos.map((item, k) => {
                                        return (
                                            <Col key={k} md="4" xs="12">
                                                <img src={item.data.photo} alt="" style={{width: '100%', height: 200, objectFit: 'cover', marginBottom: 10}}/>
                                                {banners.photos.length > 1 ? 
                                                    <Button size="sm" block color="danger" onClick={() => _deleteImage(item)}>
                                                        Remove Photo
                                                    </Button>
                                                : null}
                                            </Col>
                                        )
                                    })}
                                </Row>
                            : null}
                        </FormGroup>
                    </div>

                    <div className="flex-item" style={{backgroundColor: '#ecf0f1', marginBottom: 10, padding: 20, marginTop: 10, borderRadius: 10}}>
                        <Row>
                            <Col lg="6" md="6" sm="12" xs="12">
                                <FormGroup>
                                    <Label>Title</Label>
                                    <Input 
                                        type="text"
                                        name="title_en"
                                        value={title_en}
                                        onChange={(e) => setTitleEn(e.target.value)}
                                        bsSize="sm"
                                        />
                                </FormGroup>
                            </Col>
                            <Col lg="6" md="6" sm="12" xs="12">
                                <FormGroup>
                                    <Label>Title in Arabic</Label>
                                    <Input 
                                        type="text"
                                        name="title_ar"
                                        value={title_ar}
                                        onChange={(e) => setTitleAr(e.target.value)}
                                        bsSize="sm"
                                        style={{textAlign: 'right'}}
                                        />
                                </FormGroup>
                            </Col>
                        </Row>
                    </div>

                    <div className="flex-item" style={{backgroundColor: '#ecf0f1', marginBottom: 10, padding: 20, marginTop: 10, borderRadius: 10}}>
                        <Row>
                            <Col lg="6" md="6" sm="12" xs="12">
                                <FormGroup>
                                    <Label>Description</Label>
                                    <Input 
                                        type="textarea"
                                        name="desc_en"
                                        value={desc_en}
                                        onChange={(e) => setDescEn(e.target.value)}
                                        bsSize="sm"
                                        />
                                </FormGroup>
                            </Col>
                            <Col lg="6" md="6" sm="12" xs="12">
                                <FormGroup>
                                    <Label>Description in Arabic</Label>
                                    <Input 
                                        type="textarea"
                                        name="desc_ar"
                                        value={desc_ar}
                                        onChange={(e) => setDescAr(e.target.value)}
                                        bsSize="sm"
                                        style={{textAlign: 'right'}}
                                        />
                                </FormGroup>
                            </Col>
                        </Row>
                    </div>

                    <div className="flex-item" style={{backgroundColor: '#ecf0f1', marginBottom: 10, padding: 20, marginTop: 10, borderRadius: 10}}>
                        <FormGroup>
                            <Label>Options</Label><br/>
                            {options.length !== 0 ? 
                                options.map((i, key) => {
                                    return (
                                        <div key={key}>
                                            <h2>{i.optionLabelEn} {_renderAction(i)}</h2>
                                            {i.optionList.map((j, jkey) => {
                                                return (
                                                    <Badge key={jkey} color="primary" style={{color: '#fff', marginRight: 10}}>
                                                        {j.label_en}
                                                    </Badge>
                                                )
                                            })}
                                            <br/><br/>
                                            <Button size="sm" color="danger" onClick={() => _deleteOptions(key)}>
                                                Remove Options
                                            </Button>
                                            <hr/>
                                        </div>
                                    )
                                })
                            : "No Options"}
                        </FormGroup>
                        <br/>
                        <Button size="sm" color="warning" onClick={() => setOptionModal(true)}>
                            Add Options
                        </Button>
                        <br/><br/>
                        <i style={{fontSize: 12}}>Example: Colours, sizes, number of guest etc</i>
                    </div>

                    <div className="flex-item" style={{backgroundColor: '#ecf0f1', marginBottom: 10, padding: 20, marginTop: 10, borderRadius: 10}}>
                        <FormGroup>
                            <Label>Price</Label>
                            <Input 
                                type="number"
                                name="price"
                                value={price}
                                onChange={(e) => setPrice(e.target.value)}
                                bsSize="sm"
                                />
                        </FormGroup>
                    </div>

                    <i style={{marginTop: 10, fontSize: 12}}>Price will be in SAR currency</i>
                </Form>
            </ModalBody>
            <ModalFooter>
                {btnDisabled === true ? <Spinner /> : 
                    <>
                        <Button color="primary" onClick={() => _submit()}>Save</Button>{' '}
                        <Button color="danger" onClick={() => _delete()}>Delete</Button>{' '}
                        <Button color="secondary" onClick={() => _onClose()}>Cancel</Button>
                    </>
                }
            </ModalFooter>

            <Modal isOpen={optionModal} size="lg">
                <ModalHeader>
                    Add Options
                </ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Label>Action</Label>
                        <Input 
                            type="select" 
                            name="option_action"
                            onChange={(e) => setOptionAction(e.target.value)}
                            >
                            <option value="">Select Action</option>
                            <option value="display_only">Display Only</option>
                            <option value="multiple_selection">Multiple Selection</option>
                            <option value="single_selection">Single Selection</option>
                        </Input>
                    </FormGroup>
                    <Row>
                        <Col md="6" xs="12">
                            <FormGroup>
                                <Label>Label</Label>
                                <Input 
                                    type="text"
                                    name={optionLabelEn}
                                    onChange={(e) => setOptionLabelEn(e.target.value)}
                                    bsSize="sm"
                                    />
                            </FormGroup>
                        </Col>
                        <Col md="6" xs="12">
                            <FormGroup>
                                <Label>Label Arabic</Label>
                                <Input 
                                    type="text"
                                    name={optionLabelAr}
                                    onChange={(e) => setOptionLabelAr(e.target.value)}
                                    bsSize="sm"
                                    style={{textAlign: 'right'}}
                                    />
                            </FormGroup>
                        </Col>
                    </Row>

                    {optionList.map((item, k) => {
                        return (
                            <div key={k} className="flex-item" style={{backgroundColor: '#ecf0f1', marginBottom: 10, padding: 20, marginTop: 10, borderRadius: 10}}>
                                <Row>
                                    <Col md="6" xs="12">
                                        <FormGroup>
                                            <Label>Option Label</Label>
                                            <Input 
                                                type="text"
                                                onChange={(e) => _onchangeMoreOptions(e, item, k, 'en')}
                                                bsSize="sm"
                                                />
                                        </FormGroup>
                                    </Col>
                                    <Col md="6" xs="12">
                                        <FormGroup>
                                            <Label>Option Label Arabic</Label>
                                            <Input 
                                                type="text"
                                                onChange={(e) => _onchangeMoreOptions(e, item, k, 'ar')}
                                                bsSize="sm"
                                                style={{textAlign: 'right'}}
                                                />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                {optionList.length > 1 ?
                                    <Button size="sm" color="danger" onClick={() => _deleteMoreOptionList(k)}>
                                        Delete
                                    </Button>
                                : null}
                            </div>
                        )
                    })}
                    <hr />
                    <Button size="sm" color="warning" onClick={() => _addMoreOptionList()}>
                        Add More
                    </Button>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => _addOptions()}>Add</Button>{' '}
                    <Button onClick={() => setOptionModal(false)}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </Modal>
    )

    function _renderAction(i) {
        switch(i.optionAction) {
            case 'multiple_selection':
                return '- (Multiple Selection)'
                break;
            case 'single_selection':
                return '- (Single Selection)'
                break;
            default:
                return '- (Display Only)'
                break;
        }
    }

    function _addMoreOptionList() {
        const newList = [...optionList, {label_en: '', label_ar: '', value: ''}];
        setOptionList(newList);
    }

    function _deleteMoreOptionList(index) {
        const newList = [...optionList];
        newList.splice(index, 1);
        setOptionList(newList);
    }

    function _onchangeMoreOptions(e, item, k, lang) {
        const newList = [...optionList];
        if(lang == "en") {
            newList[k].label_en = e.target.value;
        }else {
            newList[k].label_ar = e.target.value;
        }
        setOptionList(newList);
    }

    function _addOptions() {

        if(optionAction === "") {
            toast.error("Please select the option action", {
                theme: "colored"
            });
            return false;
        }

        if(optionLabelEn === "") {
            toast.error("Please add option label", {
                theme: "colored"
            });
            return false;
        }

        if(optionLabelAr === "") {
            toast.error("Please add option label in arabic", {
                theme: "colored"
            });
            return false;
        }

        optionList.map(i => {
            if(i.label_en === "") {
                toast.error("Please add sub option lebel", {
                    theme: "colored"
                });
                return false;
            }

            if(i.label_ar === "") {
                toast.error("Please add sub option lebel in arabic", {
                    theme: "colored"
                });
                return false;
            }
        });

        var arr = {
            optionAction: optionAction,
            optionLabelEn: optionLabelEn,
            optionLabelAr: optionLabelAr,
            optionList: optionList
        };
        const newOptions = [...options, arr];
        setOptions(newOptions);
        setOptionModal(false);
    }

    function _deleteOptions(k) {
        const newOptions = [...options];
        newOptions.splice(k, 1);
        setOptions(newOptions);
    }

    function _submit() {

        if(title_en === "") {
            toast.error("Please add title in english", {
                theme: "colored"
            });
            return false;
        }

        if(title_ar === "") {
            toast.error("Please add title in arabic", {
                theme: "colored"
            });
            return false;
        }

        if(desc_en === "") {
            toast.error("Please add description in english", {
                theme: "colored"
            });
            return false;
        }

        if(desc_ar === "") {
            toast.error("Please add description in arabic", {
                theme: "colored"
            });
            return false;
        }

        if(price === "") {
            toast.error("Please add item price", {
                theme: "colored"
            });
            return false;
        }

        var params = {
            _key: _key,
            title_en: title_en,
            title_ar: title_ar,
            desc_en: desc_en,
            desc_ar: desc_ar,
            price: price,
            options: options
        }

        setDisabled(true);

        dispatch(editBanners(params)).then(res => {
            if(res === 'saved') {
                setDisabled(false);
                toast.success("Banner successfully updated", {
                    theme: "colored"
                });
                
                _onClose();
            }else {
                setDisabled(false);
                toast.error("An error occur please trye again later", {
                    theme: "colored"
                });
                return false;
            }
        });
    }

    function _onCropChange(e) {
        window.removeEventListener('focus', handleFocusBack);
        Resizer.imageFileResizer(
            e.target.files[0],
            800, 400, "png", 70, 0,
            (uri) => {
                setDisabled(true);
                dispatch(uploadImageItemOnly(_key, id, uri)).then(res => {
                    if(res === "saved") {
                        setDisabled(false);
                        toast.success("New photos successfully added", {
                            theme: "colored"
                        });
                    }
                })
            },
            "blob",
            600,
            300
        );
    }

    function _deleteImage(item) {
        setDisabled(true);
        dispatch(deleteImage(_key, id, item._key, item.data.id)).then(res => {
            if(res === "saved") {
                setDisabled(false);
                toast.success("Photo successfully deleted", {
                    theme: "colored"
                });
            }
        });
    }

    function _delete() {
        if(window.confirm('Are you sure you want to delete?')) {
            dispatch(deleteBanners(_key, id, banners.photos)).then(res => {
                if(res === 'saved') {
                    _onClose();
                }
            });
        }
    }

    function handleFocusBack(){
        window.removeEventListener('focus', handleFocusBack);
    }

    function _onClose() {
        setKey('');
        setID('');
        setPhotos([]);
        setTitleEn('');
        setTitleAr('');
        setDescEn('');
        setDescAr('');
        setOptions([]);
        setPrice('');
        setOptionAction('');
        setOptionLabelEn('');
        setOptionLabelAr('');
        setOptionList([{label_en: '', label_ar: '', value: ''}]);
        props.onClose();
    }
}

export default Edit;