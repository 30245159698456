import { subCategoryRef, subCategoriesStorage } from '../../database/firebase';
import { 
    SUB_CATEGORY_DATA, 
    SUB_CATEGORY_ACTION_INPUT, 
    SUB_CATEGORY_ACTION_RESET,
    SUB_CATEGORY_ACTION_EDIT,
    SUB_CATEGORY_ACTION_FILE
} from '../types';
import moment from 'moment';

const getSubCategories = (vendor_key) => async dispatch => {
    dispatch({type: SUB_CATEGORY_DATA, payload: [], loading: true});
    subCategoryRef.orderByChild('vendor_key').equalTo(vendor_key).on('value', snap => {
        if(snap.val() === null) {
            dispatch({type: SUB_CATEGORY_DATA, payload: [], loading: false});
        }else {
            let item = [];
            snap.forEach(child => {
                item.push({
                    _key: child.key,
                    data: child.val()
                })
            });
            dispatch({type: SUB_CATEGORY_DATA, payload: item, loading: false});
        }
    });
}

const getSubCatByCategory = (category) => async dispatch => {
    return new Promise((resolve, reject) => {
        subCategoryRef.orderByChild('category_key').equalTo(category).once('value', snap => {
            if(snap.val() !== null) {
                var items = [];
                snap.forEach(child => {
                    items.push({
                        _key: child.key,
                        data: child.val()
                    })
                });
                resolve(items)
            }else {
                resolve([])
            }
        });
    });
}

const addCategory = (state) => async dispatch => {
    return new Promise((resolve, reject) => {
        var id = Math.floor(10000 + Math.random() * 90000); 
        var ref = subCategoryRef.push({
            id: id.toString(),
            created_date: moment().format('YYYY-MM-DD hh:mm:ss')
        });
        subCategoryRef.child(ref.key).update(state);
        dispatch(uploadImage(ref.key, id, state, resolve, reject));
    });
}

const uploadImage = (key, id, state, resolve, reject) => async dispatch => {
    var storageRef = subCategoriesStorage.child(id.toString()).child(id.toString()+'-sub-category.png').put(state.photo);
    storageRef.on('state_changed', function(snapshot) {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        if(progress === 100) {
            resolve('saved');
        }
    }, function(error) {
        resolve(error);
    }, function() {
        storageRef.snapshot.ref.getDownloadURL().then(function(downloadURL) {
            subCategoryRef.child(key).update({
                photo: downloadURL
            });
        });
    });
}

const editCategory = (state) => async dispatch => {
    return new Promise((resolve, reject) => {
        subCategoryRef.child(state._key).update(state);
        resolve('saved');
    });
}

const uploadImageItemOnly = (state, file) => async dispatch => {
    return new Promise((resolve, reject) => {
        var storageRef = subCategoriesStorage.child(state.id.toString()).child(state.id.toString()+'-sub-category.png').put(file);
        storageRef.on('state_changed', function(snapshot) {
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            if(progress === 100) {
                resolve('saved');
            }
        }, function(error) {
            resolve(error);
        }, function() {
            storageRef.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                subCategoryRef.child(state._key).update({
                    photo: downloadURL
                });
            });
        });
    });
}

const deleteCategory = (state) => async dispatch => {
    return new Promise((resolve, reject) => {
        if(state.photo !== '') {
            subCategoriesStorage.child(state.id.toString()).child(state.id.toString()+'-sub-category.png').delete();
        }
        subCategoryRef.child(state._key).remove();
        resolve('saved');
    });
}

const inputAction = (data) => {
    return {
        type: SUB_CATEGORY_ACTION_INPUT,
        payload: data
    }
}

const inputActionFile = (state) => {
    return {
        type: SUB_CATEGORY_ACTION_FILE,
        payload: state
    }
}

const inputActionReset = () => {
    return {
        type: SUB_CATEGORY_ACTION_RESET,
        payload: {}
    }
}

const inputActionEdit = (data) => {
    return {
        type: SUB_CATEGORY_ACTION_EDIT,
        payload: data
    }
}

export {
    getSubCategories,
    addCategory,
    editCategory,
    uploadImageItemOnly,
    deleteCategory,
    inputAction,
    inputActionReset,
    inputActionEdit,
    inputActionFile,
    getSubCatByCategory
}