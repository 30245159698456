import { SETTINGS_DATA } from '../types';
import { settingsRef } from '../../database/firebase';

const getSettings = () => async dispatch => {
    dispatch({type: SETTINGS_DATA, payload: null, loading: true});
    settingsRef.on('value', snap => {
        dispatch({type: SETTINGS_DATA, payload: snap.val(), loading: false});
    });
}

const editSettings = (delivery_charge) => async dispatch => {
    return new Promise((resolve, reject) => {
        settingsRef.update({
            delivery_charge: delivery_charge
        });
        resolve('saved');
    });
}

export {
    getSettings,
    editSettings
}