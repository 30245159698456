const cols = (btn, _renderImg, _renderStatus) =>  [
    {
        name: 'Photo',
        selector: row => _renderImg(row),
    },
    {
        name: 'Page to Show',
        selector: row => row.data.category_name,
    },
    {
        name: 'Name',
        selector: row => row.data.title_en,
    },
    {
        name: 'Price',
        selector: row => row.data.price+' '+row.data.currency,
    },
    {
        name: 'Commission',
        selector: row => (!row.data.commission) ? '0' : row.data.commission,
    },
    {
        name: 'Status',
        selector: row => _renderStatus(row),
    },
    {
        name: 'Date Created',
        selector: row => row.data.created_date,
    },
    {
        name: '',
        selector: row => btn(row),
        right: true
    },
];

export default cols