const cols = (btn, _renderCountry, _renderIndustry, _renderStatus) =>  [
    {
        name: 'Photo',
        width: '100px',
        selector: row => <img src={(row.data.photo === "") ? 'https://st3.depositphotos.com/23594922/31822/v/600/depositphotos_318221368-stock-illustration-missing-picture-page-for-website.jpg' : row.data.photo} width="50" height="50" style={{objectFit: 'contain'}}/>,
    },
    {
        name: 'Country',
        selector: row => _renderCountry(row)
    },
    {
        name: 'Industry',
        selector: row => _renderIndustry(row)
    },
    {
        name: 'Name',
        selector: row => row.data.merchant_name,
    },
    {
        name: 'Cost per Scan',
        selector: row => row.data.cost_per_scan,
    },
    {
        name: 'Credits',
        selector: row => row.data.credits,
    },
    {
        name: 'Admin Username',
        selector: row => row.data.admin_username,
    },
    {
        name: 'Date Created',
        selector: row => row.data.created_date,
    },
    {
        name: '',
        selector: row => btn(row),
        right: true
    },
];

export default cols