/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import 'react-data-table-component-extensions/dist/index.css';

import 'react-toastify/dist/ReactToastify.css';
import './assets/css/override.css';
import 'mapbox-gl/dist/mapbox-gl.css';

import { Provider } from 'react-redux';
import { store } from './redux/appRedux';

import AdminLayout from "layouts/Admin.js";
import AdminAuth from "layouts/AdminAuth";

import VendorAdmin from "layouts/VendorAdmin";
import VendorAuth from "layouts/VendorAuth";

import PublicPage from "views/public";
import Terms from "views/public/term";
import PrivacyPolicy from "views/public/privacy_policy";
import Support from "views/public/support";

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Switch>
        <Route path="/" exact render={(props) => <PublicPage {...props} />} />
        <Route path="/terms" render={(props) => <Terms {...props} />} />
        <Route path="/policy" render={(props) => <PrivacyPolicy {...props} />} />
        <Route path="/support" render={(props) => <Support {...props} />} />


        <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
        <Route path="/cpanel" render={(props) => <AdminAuth {...props} />} />

        <Route path="/vendor" render={(props) => <VendorAdmin {...props} />} />
        <Route path="/vpanel" render={(props) => <VendorAuth {...props} />} />

        <Redirect from="/" to="/" />
      </Switch>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
