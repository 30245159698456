import React, { useEffect, useState } from "react";
import Header from "components/Headers/AdminHeader";
import DataTable from 'react-data-table-component';
import {createFilter} from 'react-search-input';

import {
    Card,
    CardHeader,
    CardBody,
    Container,
    Input,
    Button
} from "reactstrap";

import { useDispatch, useSelector } from 'react-redux';
import { getAdmin, adminInputEdit } from 'redux/actions/adminAction';

import cols from './cols';
import Add from './add';
import Edit from './edit';

const Users = (props) => {

    const dispatch = useDispatch();
    const admin = useSelector(state => state.admin);

    useEffect(() => {
        dispatch(getAdmin());
    }, []);

    const [search, setSearch] = useState('');
    const [add, setAdd] = useState(false);
    const [edit, setEdit] = useState(false);
    
    const KEYS_TO_FILTERS = ['data.category_name']
    const filterData = admin.data.filter(createFilter(search, KEYS_TO_FILTERS));
    
    return (
        <div>
            <Header />
            <Container className="mt--7" fluid>
                <Card>
                    <CardHeader>
                        <h3>Users</h3>
                    </CardHeader>
                    <CardBody>
                        <Button color="primary" onClick={() => setAdd(true)}>Add Users</Button>
                        <DataTable
                            columns={cols(_renderBtn)}
                            data={filterData}
                            progressPending={admin.loading}
                            subHeader
                            subHeaderComponent={
                                <Input
                                    placeholder="Search"
                                    onChange={(e) => setSearch(e.target.value)}
                                    style={{width: 250}}
                                    />
                            }
                            pagination
                            />
                    </CardBody>
                </Card>
            </Container>
            <Add
                isOpen={add}
                onClose={() => setAdd(false)}
                />
            <Edit
                isOpen={edit}
                onClose={() => setEdit(false)}
                />
        </div>
    )

    function _renderBtn(row) {
        return <Button size="sm" color="warning" onClick={() => _edit(row)}>Edit</Button>
    }

    function _edit(row) {
       dispatch(adminInputEdit(row));
       setEdit(true);
    }
}

export default Users;