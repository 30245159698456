import { 
    ADMIN_DATA, 
    ADMIN_ACTION_INPUT, 
    ADMIN_ACTION_RESET,
    ADMIN_ACTION_EDIT
} from '../types';

const initialState = {
    loading: true,
    data: [],
    _key: '',
    fullname: '',
    username: '',
    role: 'admin',
    affiliate_code: ''
}

const adminReducer = (state = initialState, action) => {
    switch(action.type) {
        case ADMIN_DATA:
            return {
                ...state,
                data: action.payload,
                loading: action.loading
            }
            break;
        case ADMIN_ACTION_INPUT:
            return {...state, [action.payload.name]: action.payload.value}
            break;
        case ADMIN_ACTION_RESET:
            return {
                ...state, 
                _key: '',
                fullname: '',
                username: '',
                role: '',
                affiliate_code: ''
            }
            break;
        case ADMIN_ACTION_EDIT:
            return {
                ...state, 
                _key: action.payload._key,
                fullname: action.payload.data.fullname,
                username: action.payload.data.username,
                role: action.payload.data.role,
                affiliate_code: action.payload.data.affiliate_code
            }
            break;
        default:
            return state;
            break;
    }
}   

export default adminReducer;