import { cateringCatRef } from '../../database/firebase';
import { 
    CATERING_SUBCAT_DATA, 
    CATERING_SUBCAT_INPUT, 
    CATERING_SUBCAT_RESET,
    CATERING_SUBCAT_EDIT
} from '../types';
import moment from 'moment';

const getCateringCategory = () => async dispatch => {
    dispatch({type: CATERING_SUBCAT_DATA, payload: [], loading: true});
    cateringCatRef.on('value', snap => {
        if(snap.val() === null) {
            dispatch({type: CATERING_SUBCAT_DATA, payload: [], loading: false});
        }else {
            let item = [];
            snap.forEach(child => {
                item.push({
                    _key: child.key,
                    data: child.val()
                })
            });
            dispatch({type: CATERING_SUBCAT_DATA, payload: item, loading: false});
        }
    });
}

const getCateringCategoryByType = (type) => async dispatch => {
    return new Promise((resolve, reject) => {
        cateringCatRef.orderByChild('type').equalTo(type).once('value', snap => {
            if(snap.val() !== null) {
                let item = [];
                snap.forEach(child => {
                    item.push({
                        _key: child.key,
                        data: child.val()
                    })
                });
                resolve(item);
            }
        });
    });
}

const addCateringCategory = (state) => async dispatch => {
    return new Promise((resolve, reject) => {
        var id = Math.floor(10000 + Math.random() * 90000); 
        var ref = cateringCatRef.push({
            id: id.toString(),
            created_date: moment().format('YYYY-MM-DD hh:mm:ss')
        });
        cateringCatRef.child(ref.key).update(state);
        resolve('saved');
    });
}

const editCateringCategory = (state) => async dispatch => {
    return new Promise((resolve, reject) => {
        cateringCatRef.child(state._key).update(state);
        resolve('saved');
    });
}

const deleteCateringCategory = (state) => async dispatch => {
    return new Promise((resolve, reject) => {
        cateringCatRef.child(state._key).remove();
        resolve('saved');
    });
}

const inputAction = (data) => {
    return {
        type: CATERING_SUBCAT_INPUT,
        payload: data
    }
}

const inputActionReset = () => {
    return {
        type: CATERING_SUBCAT_RESET,
        payload: {}
    }
}

const inputActionEdit = (data) => {
    return {
        type: CATERING_SUBCAT_EDIT,
        payload: data
    }
}

export {
    getCateringCategory,
    addCateringCategory,
    editCateringCategory,
    deleteCateringCategory,
    inputAction,
    inputActionReset,
    inputActionEdit,
    getCateringCategoryByType
}