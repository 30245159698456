import { SETTINGS_DATA } from '../types';

const initialState = {
    loading: false,
    data: null
}

const settingsReducer = (state = initialState, action) => {
    switch(action.type) {
        case SETTINGS_DATA:
            return {
                ...state,
                data: action.payload,
                loading: action.loading
            }
            break;
        default:
            return state;
            break;
    }
}   

export default settingsReducer;