import React, { useEffect, useState } from "react";

import {
    Modal, 
    ModalHeader, 
    ModalBody, 
    ModalFooter,
    Button,
    Form, 
    FormGroup, 
    Label, 
    Input
} from "reactstrap";
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { addAdmin, adminInput, adminInputReset, checkAdminUsername } from 'redux/actions/adminAction';

const Add = (props) => {

    const dispatch = useDispatch();
    const admin = useSelector(state => state.admin);
    const [btnDisabled, setDisabled] = useState(false);

    return (
        <Modal isOpen={props.isOpen}>
            <ModalHeader>Add Users</ModalHeader>
            <ModalBody>
                <Form>
                    {/* <FormGroup>
                        <Label>Role</Label>
                        <Input 
                            type="select" 
                            name="role"
                            onChange={(e) => dispatch(adminInput(e.target))}
                            value={admin.role}
                            >
                            <option value="">Select</option>
                            <option value="admin">Admin</option>
                            <option value="coordinator">Coordinator</option>
                            <option value="affiliate">Affiliate</option>
                        </Input>
                    </FormGroup>
                    {admin.role === "affiliate" ? 
                        <FormGroup>
                            <Label>Affiliate Code</Label>
                            <Input 
                                type="text" 
                                name="affiliate_code"
                                onChange={(e) => dispatch(adminInput(e.target))}
                                value={admin.affiliate_code.toUpperCase()}
                                maxLength="10"
                                />
                        </FormGroup>
                    : null} */}
                    <FormGroup>
                        <Label>Full Name</Label>
                        <Input 
                            type="text" 
                            name="fullname"
                            onChange={(e) => dispatch(adminInput(e.target))}
                            value={admin.fullname}
                            />
                    </FormGroup>
                    <FormGroup>
                        <Label>Username</Label>
                        <Input 
                            type="text" 
                            name="username" 
                            onChange={(e) => dispatch(adminInput(e.target))}
                            value={admin.username}
                            />
                    </FormGroup>
                    <FormGroup>
                        <Label>Password</Label>
                        <Input type="text" name="password" readOnly value="******"/>
                        <i>Default password: 123456</i>
                    </FormGroup>
                    
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={() => _submit()}>Save</Button>{' '}
                <Button color="secondary" onClick={() => _onClose()}>Cancel</Button>
            </ModalFooter>
        </Modal>
    )

    function _submit() {
        // if(admin.role === '') {
        //     toast.error("Please select user role", {
        //         theme: "colored"
        //     });
        //     return false;
        // }

        // if(admin.role === "affiliate") {
        //     if(admin.affiliate_code === '') {
        //         toast.error("Please enter users affiliate code", {
        //             theme: "colored"
        //         });
        //         return false;
        //     }
        // }

        if(admin.fullname === '') {
            toast.error("Please enter fullname", {
                theme: "colored"
            });
            return false;
        }
        
        if(admin.username === '') {
            toast.error("Please enter username", {
                theme: "colored"
            });
            return false;
        }

        let params = {
            fullname: admin.fullname,
            username: admin.username,
            role: admin.role
        }

        dispatch(checkAdminUsername(admin.username)).then(res => {
            if(res === 'not_available') {
                setDisabled(false);
                toast.error("Username already taken", {
                    theme: "colored"
                });
                return false;
            }else {
                _onSubmit(params);
            }
        });
    }

    function _onSubmit(params) {
        dispatch(addAdmin(params)).then(res => {
            if(res === 'saved') {
                dispatch(adminInputReset());
                props.onClose();
            }
        });
    }

    function _onClose() {
        dispatch(adminInputReset());
        props.onClose();
    }
}

export default Add;