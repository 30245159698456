import React from 'react';

import { Link } from "react-router-dom";
import {
    Container,
    Navbar,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink
} from 'reactstrap';
import { AiFillFacebook, AiFillInstagram, AiOutlineTwitter } from 'react-icons/ai';
import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import '../styles.css';

const Header = (props) => {
    return (
        <Container>
            <Navbar
                color="transparent"
                expand="md"
                light
                >
                
                <NavbarBrand href="/">
                    {isMobile ? 
                        <img src={require('../../../assets/img/brand/appicon.png').default} style={{width: 30}}/> : 
                        <img src={require('../../../assets/img/brand/logo-main.png').default} style={{width: 50}}/>}
                    
                </NavbarBrand>

                <Nav className="ml-auto">
                    <NavItem>
                        <NavLink href="/">
                            <AiFillFacebook size={30}/> 
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink href="/">
                            <AiFillInstagram size={30}/> 
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink href="/">
                            <AiOutlineTwitter size={30}/> 
                        </NavLink>
                    </NavItem>
                </Nav>

            </Navbar>
        </Container>
    )
}

export default Header;