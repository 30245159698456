import { AUTH_ADMIN, AUTH_VENDOR } from '../types';

const initialState = {
    username: '',
    password: '',
}

const authReducer = (state = initialState, action) => {
    switch(action.type) {
        case AUTH_ADMIN:
            return {...state, [action.payload.name]: action.payload.value}
            break;
        case AUTH_VENDOR:
            return {...state, [action.payload.name]: action.payload.value}
            break;
        default:
            return state;
            break;
    }
}

export default authReducer;