import { categoryRef, categoriesStorage, subCategoryRef, subCategoriesStorage } from '../../database/firebase';
import { 
    CATEGORY_DATA, 
    CATEGORY_ACTION_INPUT, 
    CATEGORY_ACTION_FILE,
    CATEGORY_ACTION_COLOR,
    CATEGORY_ACTION_RESET,
    CATEGORY_ACTION_EDIT 
} from '../types';
import moment from 'moment';

const getCategories = () => async dispatch => {
    dispatch({type: CATEGORY_DATA, payload: [], loading: true});
    categoryRef.on('value', snap => {
        if(snap.val() === null) {
            dispatch({type: CATEGORY_DATA, payload: [], loading: false});
        }else {
            let item = [];
            snap.forEach(child => {
                item.push({
                    _key: child.key,
                    data: child.val()
                })
            });
            dispatch({type: CATEGORY_DATA, payload: item, loading: false});
        }
    });
}

const getCategoryByKey = (key) => async dispatch => {
    return new Promise((resolve, reject) => {
        categoryRef.child(key).once('value', snap => {
            resolve(snap.val())
        });
    });
}

const addCategory = (state) => async dispatch => {
    return new Promise((resolve, reject) => {
        var id = Math.floor(10000 + Math.random() * 90000); 
        var ref = categoryRef.push({
            id: id.toString(),
            created_date: moment().format('YYYY-MM-DD hh:mm:ss'),
            status: 'active'
        });
        categoryRef.child(ref.key).update(state);
        dispatch(uploadImage(ref.key, id, state, resolve, reject));
    });
}

const uploadImage = (key, id, state, resolve, reject) => async dispatch => {
    var storageRef = categoriesStorage.child(id.toString()).child(id.toString()+'-category.png').put(state.photo);
    storageRef.on('state_changed', function(snapshot) {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        if(progress === 100) {
            resolve('saved');
        }
    }, function(error) {
        resolve(error);
    }, function() {
        storageRef.snapshot.ref.getDownloadURL().then(function(downloadURL) {
            categoryRef.child(key).update({
                photo: downloadURL
            });
        });
    });
}

const editCategory = (state) => async dispatch => {
    return new Promise((resolve, reject) => {
        categoryRef.child(state._key).update(state);
        resolve('saved');
    });
}

const uploadImageItemOnly = (state, file) => async dispatch => {
    return new Promise((resolve, reject) => {
        var storageRef = categoriesStorage.child(state.id.toString()).child(state.id.toString()+'-category.png').put(file);
        storageRef.on('state_changed', function(snapshot) {
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            if(progress === 100) {
                resolve('saved');
            }
        }, function(error) {
            resolve(error);
        }, function() {
            storageRef.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                categoryRef.child(state._key).update({
                    photo: downloadURL
                });
            });
        });
    });
}

const deleteCategory = (state) => async dispatch => {
    return new Promise((resolve, reject) => {
        if(state.photo !== '') {
            categoriesStorage.child(state.id.toString()).child(state.id.toString()+'-category.png').delete();
        }

        subCategoryRef.orderByChild('category_key').equalTo(state._key).once('value', snap => {
            if(snap.val() !== null) {
                snap.forEach(child => {
                    if(child.val().photo !== "") {
                        subCategoriesStorage.child(child.val().id.toString()).child(child.val().id.toString()+'-sub-category.png').delete();
                    }
                    subCategoryRef.child(child.key).remove();
                })
            }
        })

        categoryRef.child(state._key).remove();
        resolve('saved');
    });
}

const inputAction = (data) => {
    return {
        type: CATEGORY_ACTION_INPUT,
        payload: data
    }
}

const inputActionReset = () => {
    return {
        type: CATEGORY_ACTION_RESET,
        payload: {}
    }
}

const inputActionFile = (state) => {
    return {
        type: CATEGORY_ACTION_FILE,
        payload: state
    }
}

const inputActionColor = (state) => {
    return {
        type: CATEGORY_ACTION_COLOR,
        payload: state
    }
}

const inputActionEdit = (data) => {
    return {
        type: CATEGORY_ACTION_EDIT,
        payload: data
    }
}

export {
    getCategories,
    getCategoryByKey,
    addCategory,
    editCategory,
    uploadImageItemOnly,
    deleteCategory,
    inputAction,
    inputActionReset,
    inputActionFile,
    inputActionColor,
    inputActionEdit
}