import React, { useEffect, useState } from "react";
import Header from "components/Headers/AdminHeader";
import DataTable from 'react-data-table-component';
import {createFilter} from 'react-search-input';

import {
    Card,
    CardHeader,
    CardBody,
    Container,
    Input,
    Button,
    ButtonGroup,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from "reactstrap";

import { useDispatch, useSelector } from 'react-redux';
import { getProductItems, editProduct } from 'redux/actions/productAction';
import { getVendorsByKey } from 'redux/actions/vendorAction';

import cols from './cols';
import Add from './add';
import Edit from './edit';

const Products = (props) => {
    const dispatch = useDispatch();
    const product = useSelector(state => state.product);

    const [category, setCategory] = useState([]);
    const [category_key, setCatKey] = useState('');
    const [category_name, setCatName] = useState('');

    useEffect(() => {
        let user = window.localStorage.getItem('@vendor');
        dispatch(getProductItems(user));

        dispatch(getVendorsByKey(user)).then(res => {
            setCategory(res.categories);
        });
    }, []);

    const [search, setSearch] = useState('');
    const [add, setAdd] = useState(false);
    const [edit, setEdit] = useState(false);
    const [selected, setSelected] = useState(null);
    
    const KEYS_TO_FILTERS = ['data.product_name']
    const filterData = product.data.filter(createFilter(search, KEYS_TO_FILTERS));
    
    return (
        <div>
            <Header />
            <Container className="mt--7" fluid>
                <Card>
                    <CardHeader>
                        <h3>Products</h3>
                    </CardHeader>
                    <CardBody>
                        {/* <Button color="primary" onClick={() => setAdd(true)}>Add Products</Button> */}
                        
                        <UncontrolledDropdown>
                            <DropdownToggle caret color="primary">
                                Add Products
                            </DropdownToggle>
                            <DropdownMenu end>
                                {category.map((item, k) => {
                                    return (
                                        <DropdownItem key={k} onClick={() => _openAddModal(item)}>
                                            {item.label}
                                        </DropdownItem>
                                    );
                                })}
                            </DropdownMenu>
                        </UncontrolledDropdown>

                        <br/><br/>
                        
                        <DataTable
                            columns={cols(_renderBtn, _renderImg)}
                            data={filterData}
                            progressPending={product.loading}
                            subHeader
                            subHeaderComponent={
                                <Input
                                    placeholder="Search"
                                    onChange={(e) => setSearch(e.target.value)}
                                    style={{width: 250}}
                                    />
                            }
                            pagination
                            />
                    </CardBody>
                </Card>
            </Container>
            {category_key !== '' ? 
                <Add
                    isOpen={add}
                    onClose={() => _onClose('add')}
                    category_key={category_key}
                    category_name={category_name}
                    />
            : null}
            {selected !== null ? 
                <Edit
                    isOpen={edit}
                    selected={selected}
                    onClose={() => _onClose('edit')}
                    />
            : null}
        </div>
    )

    function _renderImg(row) {
        if(row.data.photos !== undefined) {
            var photos = [];
            Object.keys(row.data.photos).map(k => {
                photos.push(row.data.photos[k]);
            });
            return <img src={photos[0].photo} alt="" style={{width: 50, height: 50, objectFit: 'cover'}} />
        }
    }

    function _renderBtn(row) {
        return (
            <ButtonGroup>
                <Button size="sm" color="warning" onClick={() => _edit(row)}>Edit</Button>
                {/* <Button size="sm" color="info" onClick={() => _viewRating(row)}>Rating</Button> */}
            </ButtonGroup>
        );
    }

    function _openAddModal(item) {
        setAdd(true);
        setCatKey(item.value);
        setCatName(item.label);
    }

    function _edit(row) {
        setSelected(row);
        setEdit(true);
    }

    function _onClose(type) {
        setCatKey('');
        setCatName('');

        if(type === "add") {
            setAdd(false);
        }else {
            setEdit(false);
        }
    }
}

export default Products;