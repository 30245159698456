import { 
    SUB_CATEGORY_DATA, 
    SUB_CATEGORY_ACTION_INPUT, 
    SUB_CATEGORY_ACTION_RESET,
    SUB_CATEGORY_ACTION_EDIT,
    SUB_CATEGORY_ACTION_FILE
} from '../types';

const initialState = {
    loading: true,
    data: [],
    _key: '',
    id: '',
    category_key: '',
    photo: [],
    photo_view: '',
    name: '',
    name_ar: ''
}

const subcatReducer = (state = initialState, action) => {
    switch(action.type) {
        case SUB_CATEGORY_DATA:
            return {...state, data: action.payload, loading: action.loading}
            break;
        case SUB_CATEGORY_ACTION_INPUT:
            return {...state, [action.payload.name]: action.payload.value}
            break;
        case SUB_CATEGORY_ACTION_FILE:
            return {...state, photo: action.payload}
            break;
        case SUB_CATEGORY_ACTION_RESET:
            return {
                ...state, 
                _key: '',
                id: '',
                category_key: '',
                photo: [],
                photo_view: '',
                name: '',
                name_ar: ''
            }
            break;
        case SUB_CATEGORY_ACTION_EDIT:
            return {
                ...state, 
                _key: action.payload._key,
                id: action.payload.data.id,
                category_key: action.payload.data.category_key,
                photo_view: action.payload.data.photo,
                name: action.payload.data.name,
                name_ar: action.payload.data.name_ar,
            }
            break;
        default:
            return state;
            break;
    }
}

export default subcatReducer;