import { formsRef } from '../../database/firebase';
import { 
    FORM_DATA 
} from '../types';
import moment from 'moment';

const getForms = () => async dispatch => {
    dispatch({type: FORM_DATA, payload: [], loading: true});
    formsRef.on('value', snap => {
        if(snap.val() === null) {
            dispatch({type: FORM_DATA, payload: [], loading: false});
        }else {
            let item = [];
            snap.forEach(child => {
                item.push({
                    _key: child.key,
                    data: child.val()
                })
            });
            dispatch({type: FORM_DATA, payload: item.reverse(), loading: false});
        }
    });
}

const getFormsByCategory = (category) => async dispatch => {
    return new Promise((resolve, reject) => {
        formsRef.orderByChild('category_key').equalTo(category).once('value', snap => {
            if(snap.val() !== null) {
                snap.forEach(child => {
                    resolve(child.val());
                });
            }
        });
    });
}

const addForms = (state) => async dispatch => {
    return new Promise((resolve, reject) => {
        var id = Math.floor(10000 + Math.random() * 90000); 
        var ref = formsRef.push({
            id: id.toString(),
            created_date: moment().format('YYYY-MM-DD hh:mm:ss')
        });
        formsRef.child(ref.key).update(state);
        resolve('saved');
    });
}

const editForms = (state) => async dispatch => {
    return new Promise((resolve, reject) => {
        formsRef.child(state._key).update(state.data);
        resolve('saved');
    });
}

const deleteForms = (state) => async dispatch => {
    return new Promise((resolve, reject) => {
        formsRef.child(state._key).remove();
        resolve('saved');
    });
}

export {
    getForms,
    addForms,
    editForms,
    deleteForms,
    getFormsByCategory
}