import React, { useEffect, useState } from "react";

import {
    Modal, 
    ModalHeader, 
    ModalBody,
    ModalFooter,
    Form,
    FormGroup, 
    Label, 
    Input,
    Row,
    Col,
    Badge,
    Button,
    Carousel,
    CarouselItem,
    CarouselControl,
    InputGroup,
    InputGroupAddon,
    Spinner
} from "reactstrap";
import Resizer from "react-image-file-resizer";
import { toast } from 'react-toastify';
import { TwitterPicker } from 'react-color';
import { BiTimeFive } from 'react-icons/bi';
import { FaPencilRuler } from 'react-icons/fa';
import { BsSpeedometer2, BsCircle, BsCircleFill, BsSpeedometer } from 'react-icons/bs';
import {  AiOutlineMinus, AiOutlinePlus, AiFillStar, AiOutlineClose } from 'react-icons/ai';
import { IoIosArrowDown } from 'react-icons/io';
import { MdOutlinePower } from 'react-icons/md';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import { useDispatch } from "react-redux";
import { getSubCatByCategory } from 'redux/actions/subcatAction';
import { getProductByKey, editProduct, uploadImageItemOnly, deleteImage, deleteProduct } from "redux/actions/productAction"; 

const Balloons = (props) => {

    let data = props.data;

    const dispatch = useDispatch();

    const categoryData = window.localStorage.getItem('@vendor_category');
    const categoryParse = JSON.parse(categoryData);

    const [subcat, setSubCat] = useState([]);
    const [sub_category_key, setSubCatKey] = useState(data.sub_category_key);
    const [sub_category_name, setSubCatName] = useState(data.sub_category_name);

    const [photo, setPhotos] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const [product_name, setProductName] = useState(data.product_name);
    const [description, setDescription] = useState(data.description);

    const [balloonColour, setBalloonColour] = useState(data.data.balloonColour);
    const [ribbonColour, setRibbonColour] = useState(data.data.ribbonColour);

    const [preference_txt, setPreferenceTxt] = useState('');
    const [preference, setPreference] = useState(data.data.preference);

    const [small_txt, setSmallTxt] = useState('');
    const [small, setSmall] = useState(data.data.small);

    const [medium_txt, setMediumTxt] = useState('');
    const [medium, setMedium] = useState(data.data.medium);

    const [large_txt, setLargeTxt] = useState('');
    const [large, setLarge] = useState(data.data.large);

    const [price, setPrice] = useState(data.price);

    const [product_details, setProductDetails] = useState(data.data.product_details);
    const [minQty, setMinQty] = useState(data.minQty);
    const [maxQty, setMaxQty] = useState(data.maxQty);
    const [stocks, setStocks] = useState(data.stocks);

    const [btnDisabled, setDisabled] = useState(false);

    const [selectedBalloonColour, setSelectedBalloonColour] = useState('');
    const [selectedRibbonColour, setSelectedRibbonColour] = useState('');

    const [requirements, setRequirements] = useState([
        {
            title: 'Set Up Time',
            isIncluded: 'No',
            value: ''
        },
        {
            title: 'Electricity',
            isIncluded: 'No',
            value: ''
        },
        {
            title: 'Session Duration',
            isIncluded: 'No',
            value: ''
        },
        {
            title: 'Dimension',
            isIncluded: 'No',
            value: ''
        },
        {
            title: 'Pick Up Time',
            isIncluded: 'No',
            value: ''
        }
    ]);

    useEffect(() => {
        dispatch(getSubCatByCategory(data.category_key)).then(res => {
            if(res.length === 0) {
                setSubCatKey('---');
                setSubCatName('---');
            }else {
                setSubCat(res);
            }
        });

        _callImages()
    }, []);

    function _renderIcons(title) {
        switch(title) {
            case 'Set Up Time':
                return <BiTimeFive size={20}/>
            case 'Electricity':
                return <MdOutlinePower size={20}/>
            case 'Session Duration':
                return <BsSpeedometer size={20}/>
            case 'Dimension':
                return <FaPencilRuler size={20}/>
            case 'Pick Up Time':
                return <BiTimeFive size={20}/>
        }
    }

    function _callImages() {
        dispatch(getProductByKey(props._key)).then(res => {
            setPhotos(res);
        });
    }

    return (
        <Modal isOpen={props.isOpen} toggle={() => _onClose()} size="lg">
            <ModalHeader toggle={() => _onClose()}>Edit Product</ModalHeader>
            <ModalBody>
                <Form>
                    {subcat.length !== 0 ? 
                        <FormGroup>
                            <Label>Sub Category</Label>
                            <Input 
                                type="select" 
                                name="sub_category_key"
                                onChange={(e) => _selectSubCategory(e)}
                                >
                                <option value="">Select Category</option>
                                {subcat.map((item, k) => {
                                    return <option key={k} value={item._key}>{item.data.name}</option>
                                })}
                            </Input>
                        </FormGroup>
                    : null}

                    <div>
                        {/* Photo */}
                        <div style={{backgroundColor: '#ecf0f1', width: '100%', height: 300, borderRadius: 10, marginBottom: 10}}>
                            {_renderPhoto()}
                        </div>
                        <FormGroup>
                            <Label>Upload Photo</Label>
                            <Input type="file" name="file" onChange={(e) => _uploadPhoto(e)} accept="image/*"/>
                        </FormGroup>
                        
                        <Row>
                            <Col md="9" xs="9">
                                <FormGroup>
                                    <Input type="text" name="product_name" value={product_name} placeholder="Product Name" onChange={(e) => setProductName(e.target.value)}/>
                                </FormGroup>
                            </Col>
                            <Col md="3" xs="3" style={{textAlign: 'right'}}>
                                5.0 <AiFillStar />
                            </Col>
                        </Row>

                        <FormGroup>
                            <Label>Description</Label>
                            <Input type="textarea" name="description" value={description} onChange={(e) => setDescription(e.target.value)}/>
                        </FormGroup>

                        <FormGroup>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">SAR</InputGroupAddon>
                                <Input type="number" placeholder="Price" name="price" value={price} onChange={(e) => setPrice(e.target.value)}/>
                            </InputGroup>
                        </FormGroup>

                        <p>Balloon Colours</p>
                        {balloonColour.length !== 0 ? 
                            <div style={{marginBottom: 20}}>
                                {balloonColour.map((item, k) => {
                                    return (
                                        <Badge key={k} pill
                                            style={{marginRight: 10, cursor: 'pointer', backgroundColor: item}} 
                                            onClick={() => _selectedBallonColour('remove', k)}
                                            >
                                            &nbsp;
                                        </Badge>
                                    )
                                })}
                            </div>
                        : null}
                        <TwitterPicker onChangeComplete={e => setSelectedBalloonColour(e.hex)}/> <br/>
                        {selectedBalloonColour !== '' ? 
                            <>
                                <Button size="sm" color="primary" onClick={() => _selectedBallonColour('add', '')}>Add Colour</Button><br/><br/>
                            </>
                        : null}

                        <p>Ribbon Colours</p>
                        {ribbonColour.length !== 0 ? 
                            <div style={{marginBottom: 20}}>
                                {ribbonColour.map((item, k) => {
                                    return (
                                        <Badge key={k} pill
                                            style={{marginRight: 10, cursor: 'pointer', backgroundColor: item}} 
                                            onClick={() => _selectedRibbonColour('remove', k)}
                                            >
                                            &nbsp;
                                        </Badge>
                                    )
                                })}
                            </div>
                        : null}
                        <TwitterPicker onChangeComplete={e => setSelectedRibbonColour(e.hex)}/> <br/>
                        {selectedRibbonColour !== '' ? 
                            <>
                                <Button size="sm" color="primary" onClick={() => _selectedRibbonColour('add', '')}>Add Colour</Button><br/><br/>
                            </>
                        : null}

                        <FormGroup>
                            <Label>Preferences</Label>
                            <InputGroup style={{marginBottom: 10}}>
                                <Input type="text" name="preference_txt" value={preference_txt} onChange={(e) => setPreferenceTxt(e.target.value)}/>
                                <Button onClick={() => {
                                    setPreference([...preference, preference_txt]);
                                    setPreferenceTxt('');
                                }}>Add</Button>
                            </InputGroup>
                            {preference.map((item, k) => {
                                return (
                                    <div key={k}>
                                        <FormGroup check>
                                            <Input name="radio1" type="radio"/>{' '}
                                            <Label check>
                                                {item} <AiOutlineClose style={{cursor: 'pointer'}} onClick={() => {
                                                    var data = [...preference];
                                                    data.splice(k, 1);
                                                    setPreference(data);
                                                }}/>
                                            </Label>
                                        </FormGroup>
                                    </div>
                                )
                            })}
                        </FormGroup>
                        
                        <FormGroup>
                            <Label>Small</Label>
                            <InputGroup style={{marginBottom: 10}}>
                                <Input type="text" name="small_txt" value={small_txt} onChange={(e) => setSmallTxt(e.target.value)}/>
                                <Button onClick={() => {
                                    setSmall([...small, small_txt]);
                                    setSmallTxt('');
                                }}>Add</Button>
                            </InputGroup>
                            {small.map((item, k) => {
                                return (
                                    <div key={k}>
                                        <FormGroup check>
                                            <Input name="radio1" type="radio"/>{' '}
                                            <Label check>
                                                {item} <AiOutlineClose style={{cursor: 'pointer'}} onClick={() => {
                                                    var data = [...small];
                                                    data.splice(k, 1);
                                                    setSmall(data);
                                                }}/>
                                            </Label>
                                        </FormGroup>
                                    </div>
                                )
                            })}
                        </FormGroup>

                        <FormGroup>
                            <Label>Medium</Label>
                            <InputGroup style={{marginBottom: 10}}>
                                <Input type="text" name="medium_txt" value={medium_txt} onChange={(e) => setMediumTxt(e.target.value)}/>
                                <Button onClick={() => {
                                    setMedium([...medium, medium_txt]);
                                    setMediumTxt('');
                                }}>Add</Button>
                            </InputGroup>
                            {medium.map((item, k) => {
                                return (
                                    <div key={k}>
                                        <FormGroup check>
                                            <Input name="radio1" type="radio"/>{' '}
                                            <Label check>
                                                {item} <AiOutlineClose style={{cursor: 'pointer'}} onClick={() => {
                                                    var data = [...medium];
                                                    data.splice(k, 1);
                                                    setMedium(data);
                                                }}/>
                                            </Label>
                                        </FormGroup>
                                    </div>
                                )
                            })}
                        </FormGroup>

                        <FormGroup>
                            <Label>Large</Label>
                            <InputGroup style={{marginBottom: 10}}>
                                <Input type="text" name="large_txt" value={large_txt} onChange={(e) => setLargeTxt(e.target.value)}/>
                                <Button onClick={() => {
                                    setLarge([...large, large_txt]);
                                    setLargeTxt('');
                                }}>Add</Button>
                            </InputGroup>
                            {large.map((item, k) => {
                                return (
                                    <div key={k}>
                                        <FormGroup check>
                                            <Input name="radio1" type="radio"/>{' '}
                                            <Label check>
                                                {item} <AiOutlineClose style={{cursor: 'pointer'}} onClick={() => {
                                                    var data = [...large];
                                                    data.splice(k, 1);
                                                    setLarge(data);
                                                }}/>
                                            </Label>
                                        </FormGroup>
                                    </div>
                                )
                            })}
                        </FormGroup>

                        <FormGroup>
                            <Label>Minimum Quantity</Label>
                            <Input type="number" name="minQty" value={minQty} onChange={(e) => setMinQty(e.target.value)}/>
                        </FormGroup>

                        <FormGroup>
                            <Label>Maximum Quantity</Label>
                            <Input type="number" name="maxQty" value={maxQty} onChange={(e) => setMaxQty(e.target.value)}/>
                        </FormGroup>

                        <FormGroup>
                            <Label>Inventory Stocks</Label>
                            <Input type="number" name="stocks" value={stocks} onChange={(e) => setStocks(e.target.value)}/>
                        </FormGroup>

                        <hr />

                        {requirements.map((item, k) => {
                            return(
                                <Row key={k} style={{marginBottom: 10}}>
                                    <Col md={1}>
                                        {item.isIncluded === "Yes" ? 
                                            <BsCircleFill size={18} onClick={() => _onChangeRequirements(item, k, 'isIncluded', 'No')}/> : 
                                            <BsCircle size={18} onClick={() => _onChangeRequirements(item, k, 'isIncluded', 'Yes')}/>
                                        }
                                    </Col>
                                    <Col md={3}>
                                        {_renderIcons(item.title)} {item.title}
                                    </Col> 
                                    <Col>
                                        <Input 
                                            type="text"
                                            name="value"
                                            size="sm"
                                            value={item.value}
                                            onChange={(e) => _onChangeRequirements(item, k, e.target.name, e.target.value)}
                                            />
                                    </Col>
                                </Row>
                            );
                        })}

                        <hr />

                        <Accordion>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        <Row>
                                            <Col md="8" xs="4">
                                                Product Details
                                            </Col>
                                            <Col md="4" xs="4" style={{textAlign: "right"}}>
                                                <IoIosArrowDown size={30}/>
                                            </Col>
                                        </Row>
                                        <div className="bottomBorder"></div>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <Input placeholder="Type here..." type="textarea" name="product_details" value={product_details} onChange={(e) => setProductDetails(e.target.value)}/>
                                    <br/>
                                </AccordionItemPanel>
                            </AccordionItem>
                        </Accordion>
                    </div>
                </Form>
            </ModalBody>
            <ModalFooter>
                {
                    btnDisabled === true ? <Spinner /> : 
                    <>
                        <Button color="primary" onClick={() => _submit()}>Save this Product</Button>
                        <Button color="danger" onClick={() => _delete()}>Delete this Product</Button>
                    </>
                }
            </ModalFooter>
        </Modal>
    )

    function _onChangeRequirements(item, k, name, val) {
        var req = [...requirements];
        req[k][name] = val;
        setRequirements(req);
    }

    function _selectSubCategory(e) {
        let index = e.nativeEvent.target.selectedIndex;
        let label = e.nativeEvent.target[index].text;
        setSubCatKey(e.target.value);
        setSubCatName(label);
    }

    function _submit() {
        if(sub_category_key === '') {
            toast.error("Please select category", {
                theme: "colored"
            });
            return false;
        }

        if(product_name === '') {
            toast.error("Please enter the product name", {
                theme: "colored"
            });
            return false;
        }

        if(balloonColour.length === 0) {
            toast.error("Please add atleast 1 balloon colour", {
                theme: "colored"
            });
            return false;
        }

        if(ribbonColour.length === 0) {
            toast.error("Please add atleast 1 ribbon colour", {
                theme: "colored"
            });
            return false;
        }

        if(price === '') {
            toast.error("Please enter the price", {
                theme: "colored"
            });
            return false;
        }

        if(minQty === '') {
            toast.error("Please enter the minimum quantity", {
                theme: "colored"
            });
            return false;
        }

        if(maxQty === '') {
            toast.error("Please enter the maximum quantity", {
                theme: "colored"
            });
            return false;
        }

        if(stocks === '') {
            toast.error("Please enter the inventory stocks", {
                theme: "colored"
            });
            return false;
        }

        if(preference.length === 0) {
            toast.error("Please add atleast 1 preference", {
                theme: "colored"
            });
            return false;
        }

        if(small.length === 0) {
            toast.error("Please add atleast 1 small size", {
                theme: "colored"
            });
            return false;
        }

        if(medium.length === 0) {
            toast.error("Please add atleast 1 small size", {
                theme: "colored"
            });
            return false;
        }

        if(large.length === 0) {
            toast.error("Please add atleast 1 large size", {
                theme: "colored"
            });
            return false;
        }

        var params = {
            _key: props._key,
            sub_category_key: sub_category_key,
            sub_category_name: sub_category_name,
            target: 'product',
            //photo: photo,
            product_name: product_name,
            description: description,
            price: price,
            minQty: minQty,
            maxQty: maxQty,
            stocks: stocks,
            data: {
                product_details: product_details,
                balloonColour: balloonColour,
                ribbonColour: ribbonColour,
                preference: preference,
                small: small,
                medium: medium,
                large: large,
                requirements: requirements
            }
        }

        setDisabled(true);
        dispatch(editProduct(params)).then(res => {
            if(res === 'saved') {
                setDisabled(false);
                toast.success("New product successfully added and it will review by Dibrah Team", {
                    theme: "colored"
                });
                _onClose();
            }else {
                setDisabled(false);
                toast.error("An error occur please trye again later", {
                    theme: "colored"
                });
                return false;
            }
        });
    }

    function _delete() {
        if(window.confirm('Are you sure you want to delete?')) {
            dispatch(deleteProduct(props._key, props.data.id, photo)).then(res => {
                if(res === 'saved') {
                    _onClose();
                }
            });
        }
    }

    function _renderPhoto() {
        if(photo.length !== 0) {
            return (
                <Carousel
                    activeIndex={activeIndex}
                    next={() => next()}
                    previous={() => previous()}
                    >
                    {photo.map((item, k) => {
                        return(
                            <CarouselItem key={k}>
                                <img src={item.data.photo} style={{width: '100%', height: 300, objectFit: 'cover'}}/>
                                {photo.length > 1 ?
                                    <Button
                                        color="danger"
                                        style={{position: 'relative', marginTop: -100, left: 10, zIndex: 100}}
                                        onClick={() => _deleteImage(props._key, props.data.id, item._key, item.data.id)}
                                        >
                                        Delete
                                    </Button>
                                : null}
                            </CarouselItem>
                        )
                    })}
                    <CarouselControl direction="prev" directionText="Previous" onClickHandler={() => previous()} />
                    <CarouselControl direction="next" directionText="Next" onClickHandler={() => next()} />
                </Carousel>
            )
        }
    }

    function _uploadPhoto(e) {
        window.removeEventListener('focus', handleFocusBack);
        if(photo.length > 10) {
            toast.error("Photos cannot be more than 10", {
                theme: "colored"
            });
            return false;
        }

        Resizer.imageFileResizer(
            e.target.files[0], 400, 400, "png", 70, 0,
            (uri) => { 
                dispatch(uploadImageItemOnly(props._key, props.data.id, uri)).then(res => {
                    if(res === "saved") {
                        setDisabled(false);
                        toast.success("New photos successfully added", {
                            theme: "colored"
                        });
                        _callImages()
                    }
                })
            }, "blob", 300, 300
        );
    }

    function _deleteImage(key, id, photoKey, photoId) {
        dispatch(deleteImage(key, id, photoKey, photoId)).then(res => {
            if(res == 'saved') {
                toast.error("Photos has been deleted", {
                    theme: "colored"
                });
                _callImages()
            }
        });
    }

    function _selectedBallonColour(type, k) {
        var colours = [...balloonColour];
        if(type === "add") {
            colours.push(selectedBalloonColour);
            setBalloonColour(colours);
            setSelectedBalloonColour('');
        }else {
            colours.splice(k, 1);
            setBalloonColour(colours);
        }
    }

    function _selectedRibbonColour(type, k) {
        var colours = [...ribbonColour];
        if(type === "add") {
            colours.push(selectedRibbonColour);
            setRibbonColour(colours);
            setSelectedRibbonColour('');
        }else {
            colours.splice(k, 1);
            setRibbonColour(colours);
        }
    }

    function handleFocusBack(){
        window.removeEventListener('focus', handleFocusBack);
    }

    function _onClose() {
        props.onClose();
        setSubCat([]);
        setSubCatKey('');
        setSubCatName('');
        setPhotos([]);
        setActiveIndex(0);
        setProductName('');
        setDescription('');
        setBalloonColour([]);
        setRibbonColour([]);
        setPrice('');
        setDisabled(false);
        setSelectedBalloonColour('');
        setSelectedRibbonColour('');
        setPreferenceTxt('');
        setPreference([]);
        setSmallTxt('');
        setSmall([]);
        setMediumTxt('');
        setMedium([]);
        setLargeTxt('');
        setLarge([]);
        setMinQty(0);
        setMaxQty(0);
        setStocks(0);
        setProductDetails('');
    }

    function next() {
        const nextIndex = activeIndex === photo.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    }
    
    function previous() {
        const nextIndex = activeIndex === 0 ? photo.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    }
}

export default Balloons;