import React, { useEffect, useState } from "react";

import {
    Modal, 
    ModalHeader, 
    ModalBody,
    ModalFooter,
    Form,
    FormGroup, 
    Label, 
    Input,
    Row,
    Col,
    Badge,
    Button,
    Carousel,
    CarouselItem,
    CarouselControl,
    InputGroup,
    InputGroupAddon,
    Spinner
} from "reactstrap";
import Resizer from "react-image-file-resizer";
import { toast } from 'react-toastify';
import { BiTimeFive } from 'react-icons/bi';
import { AiFillStar, AiOutlineClose, AiFillDelete } from 'react-icons/ai';
import { FaPencilRuler } from 'react-icons/fa';
import { IoIosArrowDown } from 'react-icons/io';
import { MdOutlinePower } from 'react-icons/md';
import { BsCircle, BsCircleFill, BsSpeedometer } from 'react-icons/bs';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import { useDispatch } from "react-redux";
import { getSubCatByCategory } from 'redux/actions/subcatAction';
import { getProductByKey, editProduct, uploadImageItemOnly, deleteImage, deleteProduct } from "redux/actions/productAction"; 
import { getCateringCategoryByType } from 'redux/actions/cateringAction';

const Catering = (props) => {

    let data = props.data;

    const dispatch = useDispatch();

    const categoryData = window.localStorage.getItem('@vendor_category');
    const categoryParse = JSON.parse(categoryData);

    const [type, setType] = useState(data.type);

    const [subcat, setSubCat] = useState([]);
    const [sub_category_key, setSubCatKey] = useState(data.sub_category_key);
    const [sub_category_name, setSubCatName] = useState(data.sub_category_name);

    const [photo, setPhotos] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const [product_name, setProductName] = useState(data.product_name);
    const [description, setDescription] = useState(data.description);

    const [add_on_title, setAddOnTitle] = useState('');
    const [addOns, setAddOns] = useState(data.data.addOns);

    const [min, setMin] = useState(0);
    const [jump, setJump] = useState(0);
    const [max, setMax] = useState(0);

    const [requirements, setRequirements] = useState(
        !data.data.requirements ? [
            {
                title: 'Set Up Time',
                isIncluded: 'No',
                value: ''
            },
            {
                title: 'Electricity',
                isIncluded: 'No',
                value: ''
            },
            {
                title: 'Session Duration',
                isIncluded: 'No',
                value: ''
            },
            {
                title: 'Dimension',
                isIncluded: 'No',
                value: ''
            },
            {
                title: 'Pick Up Time',
                isIncluded: 'No',
                value: ''
            }
        ] : data.data.requirements
    );

    const [setup_time, setSetupTime] = useState(data.data.setup_time);
    const [dimention, setDimention] = useState(data.data.dimention);
    const [price, setPrice] = useState(data.price);

    const [refund_policy, setRefundPolicy] = useState(data.data.refund_policy);
    const [cancellation_policy, setCancellationPolicy] = useState(data.data.cancellation_policy);

    const [btnDisabled, setDisabled] = useState(false);

    useEffect(() => {
        // dispatch(getSubCatByCategory(categoryParse._key)).then(res => {
        //     if(res.length === 0) {
        //         setSubCatKey('---');
        //         setSubCatName('---');
        //     }else {
        //         setSubCat(res);
        //     }
        // });
        _getCateringCategoryByType(data.type);
        _callImages()
    }, []);

    function _getCateringCategoryByType(type) {
        dispatch(getCateringCategoryByType(type)).then(res => {
            setSubCat(res);
        });
    }

    function _callImages() {
        dispatch(getProductByKey(props._key)).then(res => {
            setPhotos(res);
        });
    }

    function _renderIcons(title) {
        switch(title) {
            case 'Set Up Time':
                return <BiTimeFive size={20}/>
            case 'Electricity':
                return <MdOutlinePower size={20}/>
            case 'Session Duration':
                return <BsSpeedometer size={20}/>
            case 'Dimension':
                return <FaPencilRuler size={20}/>
            case 'Pick Up Time':
                return <BiTimeFive size={20}/>
        }
    }

    return (
        <Modal isOpen={props.isOpen} toggle={() => _onClose()} size="lg">
            <ModalHeader toggle={() => _onClose()}>Edit Product</ModalHeader>
            <ModalBody>
                <div>
                    <FormGroup>
                        <Label>Catering Type</Label>
                        <Input 
                            type="select" 
                            name="type"
                            onChange={(e) => {
                                _getCateringCategoryByType(e.target.value);
                                setType(e.target.value)
                            }}
                            value={type}
                            >
                            <option value="">Select Type</option>
                            <option value="catering_services">Catering Services</option>
                            <option value="food_trucks">Food Trucks</option>
                            <option value="food_stations">Food Stations</option>
                        </Input>
                    </FormGroup>

                    {subcat.length !== 0 ? 
                        <FormGroup>
                            <Label>Sub Category</Label>
                            <Input 
                                type="select" 
                                name="sub_category_key"
                                onChange={(e) => _selectSubCategory(e)}
                                >
                                <option value="">Select Category</option>
                                {subcat.map((item, k) => {
                                    return <option key={k} value={item._key}>{item.data.name}</option>
                                })}
                            </Input>
                        </FormGroup>
                    : null}

                    {/* Photo */}
                    <div style={{backgroundColor: '#ecf0f1', width: '100%', height: 300, borderRadius: 10, marginBottom: 10}}>
                        {_renderPhoto()}
                    </div>
                    <FormGroup>
                        <Label>Upload Photo</Label>
                        <Input type="file" name="file" onChange={(e) => _uploadPhoto(e)} accept="image/*"/>
                    </FormGroup>

                    <Row>
                        <Col md="9" xs="9">
                            <FormGroup>
                                <Input type="text" name="product_name" value={product_name} placeholder="Product Name" onChange={(e) => setProductName(e.target.value)}/>
                            </FormGroup>
                        </Col>
                        <Col md="3" xs="3" style={{textAlign: 'right'}}>
                            5.0 <AiFillStar />
                        </Col>
                    </Row>

                    <FormGroup>
                        <Label>Description</Label>
                        <Input type="textarea" name="description" value={description} onChange={(e) => setDescription(e.target.value)}/>
                    </FormGroup>

                    <FormGroup>
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">SAR</InputGroupAddon>
                            <Input type="number" placeholder="Price" name="price" value={price} onChange={(e) => setPrice(e.target.value)}/>
                        </InputGroup>
                    </FormGroup>

                    <FormGroup>
                        <Label>Add Title</Label>
                        <InputGroup style={{marginBottom: 10}}>
                            <Input type="text" name="add_on_title" value={add_on_title} onChange={(e) => setAddOnTitle(e.target.value)}/>
                            <Button onClick={() => {
                                var newAddOn = [...addOns, {title: add_on_title, readOnly: 'No' , selections: [{name: '', price: '', qty: 0}]}];
                                setAddOns(newAddOn);
                                setAddOnTitle('');
                            }}>Add</Button>
                        </InputGroup>
                        {addOns.map((item, key) => {
                            return (
                                <div key={key} style={{backgroundColor: '#ecf0f1', padding: 20}}>
                                    <h3>{item.title}</h3>
                                    {item.selections.map((v, k) => {
                                        return (
                                            <Row key={k} style={{marginBottom: 10}}>
                                                <Col>
                                                    <FormGroup>
                                                        <Label>Name</Label>
                                                        <Input type="text" name="name" value={v.name} onChange={(e) => _onchangeNewField(item, k, e)}/>
                                                    </FormGroup>
                                                </Col>
                                                <Col>
                                                    <FormGroup>
                                                        <Label>Price</Label>
                                                        <Input type="number" name="price" value={v.price} onChange={(e) => _onchangeNewField(item, k, e)}/>
                                                    </FormGroup>
                                                </Col>
                                                <Col>
                                                    <FormGroup>
                                                        <Label>Enable</Label>
                                                        <Input type="select" name="isEnabled" value={v.isEnabled} onChange={(e) => _onchangeNewField(item, k, e)}>
                                                            <option value="No">No</option>
                                                            <option value="Yes">Yes</option>
                                                        </Input>
                                                    </FormGroup>
                                                </Col>
                                                <Col md="2" style={{textAlign: 'right'}}>
                                                    <AiFillDelete style={{cursor: 'pointer', marginTop: 33}} size={40} color="#e74c3c" onClick={() => _deleteNewField(item, k)}/>
                                                </Col>
                                            </Row>
                                        );
                                    })}
                                    <hr/>
                                    <Row>
                                        <Col>
                                            <Button color="warning" size="sm" onClick={() => _addNewField(item)}>Add New Field</Button>
                                        </Col>
                                        <Col style={{textAlign: 'right'}}>
                                            <Button color="danger" size="sm" onClick={() => _removeTitle(key)}>Remove This Title</Button>
                                        </Col>
                                    </Row>
                                    
                                </div>
                            )
                        })}
                    </FormGroup>

                    <hr />

                    <h3>Servings Range</h3>
                    <Row>
                        <Col md="4">
                            <FormGroup>
                                <Label>Minimum</Label>
                                <Input type="text" name="min" value={min} onChange={(e) => setMin(e.target.value)}/>
                            </FormGroup>
                        </Col>
                        <Col md="4">
                            <FormGroup>
                                <Label>Jump Range</Label>
                                <Input type="text" name="jump" value={jump} onChange={(e) => setJump(e.target.value)}/>
                                <i style={{fontSize: 10}}>(How many steps to jump on next range)</i>
                            </FormGroup>
                        </Col>
                        <Col md="4">
                            <FormGroup>
                                <Label>Maximum</Label>
                                <Input type="text" name="max" value={max} onChange={(e) => setMax(e.target.value)}/>
                            </FormGroup>
                        </Col>
                    </Row>

                    <hr />

                    {requirements.map((item, k) => {
                        return(
                            <Row key={k} style={{marginBottom: 10}}>
                                <Col md={1}>
                                    {item.isIncluded === "Yes" ? 
                                        <BsCircleFill size={18} onClick={() => _onChangeRequirements(item, k, 'isIncluded', 'No')}/> : 
                                        <BsCircle size={18} onClick={() => _onChangeRequirements(item, k, 'isIncluded', 'Yes')}/>
                                    }
                                </Col>
                                <Col md={3}>
                                    {_renderIcons(item.title)} {item.title}
                                </Col> 
                                <Col>
                                    <Input 
                                        type="text"
                                        name="value"
                                        size="sm"
                                        value={item.value}
                                        onChange={(e) => _onChangeRequirements(item, k, e.target.name, e.target.value)}
                                        />
                                </Col>
                            </Row>
                        );
                    })}

                    <hr />

                    <Accordion>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <Row>
                                        <Col md="8" xs="4">
                                            Refund Policy
                                        </Col>
                                        <Col md="4" xs="4" style={{textAlign: "right"}}>
                                            <IoIosArrowDown size={30}/>
                                        </Col>
                                    </Row>
                                    <div className="bottomBorder"></div>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <Input placeholder="Type here..." type="textarea" name="refund_policy" value={refund_policy} onChange={(e) => setRefundPolicy(e.target.value)}/>
                                <br/>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <Row>
                                        <Col md="8" xs="4">
                                            Cancellation Policy
                                        </Col>
                                        <Col md="4" xs="4" style={{textAlign: "right"}}>
                                            <IoIosArrowDown size={30}/>
                                        </Col>
                                    </Row>
                                    <div className="bottomBorder"></div>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <Input placeholder="Type here..." type="textarea" name="cancellation_policy" value={cancellation_policy} onChange={(e) => setCancellationPolicy(e.target.value)}/>
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>
                </div>
            </ModalBody>
            <ModalFooter>
                {
                    btnDisabled === true ? <Spinner /> : 
                    <>
                        <Button color="primary" onClick={() => _submit()}>Save this Product</Button>{" "}
                        <Button color="danger" onClick={() => _delete()}>Delete Product</Button>
                    </>
                }
            </ModalFooter>
        </Modal>
    )

    function _onChangeRequirements(item, k, name, val) {
        var req = [...requirements];
        req[k][name] = val;
        setRequirements(req);
    }

    function _removeTitle(index) {
        var newField = [...addOns];
        addOns.splice(index, 1);
        setAddOns(newField);
    }

    function _addNewField(item) {
        var newField = [...addOns];
        item.selections.push({name: '', price: '', qty: 0});
        setAddOns(newField);
    }

    function _deleteNewField(item, k) {
        var newField = [...addOns];
        item.selections.splice(k, 1);
        setAddOns(newField);
    }

    function _onchangeNewField(item, k, e) {
        var newField = [...addOns];
        item.selections[k][e.target.name] = e.target.value;
        setAddOns(newField);
    }

    function _selectSubCategory(e) {
        let index = e.nativeEvent.target.selectedIndex;
        let label = e.nativeEvent.target[index].text;
        setSubCatKey(e.target.value);
        setSubCatName(label);
    }

    function _submit() {
        if(sub_category_key === '') {
            toast.error("Please select category", {
                theme: "colored"
            });
            return false;
        }

        if(description === '') {
            toast.error("Please enter the description", {
                theme: "colored"
            });
            return false;
        }

        if(price === '') {
            toast.error("Please enter the price", {
                theme: "colored"
            });
            return false;
        }

        // if(service_offer.length === 0) {
        //     toast.error("Please add atleast 1 service offer", {
        //         theme: "colored"
        //     });
        //     return false;
        // }

        // if(foods.length === 0) {
        //     toast.error("Please add atleast 1 foods", {
        //         theme: "colored"
        //     });
        //     return false;
        // }

        // if(servings.length === 0) {
        //     toast.error("Please add atleast 1 servings", {
        //         theme: "colored"
        //     });
        //     return false;
        // }

        if(addOns.length === 0) {
            toast.error("Please enter atleast one add ons", {
                theme: "colored"
            });
            return false;
        }

        if(min === '') {
            toast.error("Please enter minimum range", {
                theme: "colored"
            });
            return false;
        }

        if(jump === '' || jump === 0) {
            toast.error("Please enter jump range", {
                theme: "colored"
            });
            return false;
        }

        if(max === '' || max === 0) {
            toast.error("Please enter maximum range", {
                theme: "colored"
            });
            return false;
        }

        // if(setup_time === '') {
        //     toast.error("Please enter setup time", {
        //         theme: "colored"
        //     });
        //     return false;
        // }

        // if(dimention === '') {
        //     toast.error("Please enter dimention", {
        //         theme: "colored"
        //     });
        //     return false;
        // }

        if(refund_policy === '') {
            toast.error("Please enter refund policy", {
                theme: "colored"
            });
            return false;
        }

        if(cancellation_policy === '') {
            toast.error("Please enter cancellation policy", {
                theme: "colored"
            });
            return false;
        }

        var params = {
            _key: props._key,
            sub_category_key: sub_category_key,
            sub_category_name: sub_category_name,
            target: 'services',
            //photo: photo,
            product_name: product_name,
            description: description,
            price: price,
            data: {
                // service_offer: service_offer,
                // foods: foods,
                // servings: servings,
                // equiments: equiments,
                addOns: addOns,
                requirements: requirements,
                refund_policy: refund_policy,
                cancellation_policy: cancellation_policy,
                min: min,
                jump: jump,
                max: max
            }
        }

        setDisabled(true);
        dispatch(editProduct(params)).then(res => {
            if(res === 'saved') {
                setDisabled(false);
                toast.success("New product successfully added and it will review by Dibrah Team", {
                    theme: "colored"
                });
                _onClose();
            }else {
                setDisabled(false);
                toast.error("An error occur please trye again later", {
                    theme: "colored"
                });
                return false;
            }
        });
    }

    function _delete() {
        if(window.confirm('Are you sure you want to delete?')) {
            dispatch(deleteProduct(props._key, props.data.id, photo)).then(res => {
                if(res === 'saved') {
                    _onClose();
                }
            });
        }
    }

    function _renderPhoto() {
        if(photo.length !== 0) {
            return (
                <Carousel
                    activeIndex={activeIndex}
                    next={() => next()}
                    previous={() => previous()}
                    >
                    {photo.map((item, k) => {
                        return(
                            <CarouselItem key={k}>
                                <img src={item.data.photo} style={{width: '100%', height: 300, objectFit: 'cover'}}/>
                                {photo.length > 1 ?
                                    <Button
                                        color="danger"
                                        style={{position: 'relative', marginTop: -100, left: 10, zIndex: 100}}
                                        onClick={() => _deleteImage(props._key, props.data.id, item._key, item.data.id)}
                                        >
                                        Delete
                                    </Button>
                                : null}
                            </CarouselItem>
                        )
                    })}
                    <CarouselControl direction="prev" directionText="Previous" onClickHandler={() => previous()} />
                    <CarouselControl direction="next" directionText="Next" onClickHandler={() => next()} />
                </Carousel>
            )
        }
    }

    function _uploadPhoto(e) {
        window.removeEventListener('focus', handleFocusBack);
        if(photo.length > 10) {
            toast.error("Photos cannot be more than 10", {
                theme: "colored"
            });
            return false;
        }

        Resizer.imageFileResizer(
            e.target.files[0], 400, 400, "png", 70, 0,
            (uri) => { 
                dispatch(uploadImageItemOnly(props._key, props.data.id, uri)).then(res => {
                    if(res === "saved") {
                        setDisabled(false);
                        toast.success("New photos successfully added", {
                            theme: "colored"
                        });
                        _callImages()
                    }
                })
            }, "blob", 300, 300
        );
    }

    function _deleteImage(key, id, photoKey, photoId) {
        dispatch(deleteImage(key, id, photoKey, photoId)).then(res => {
            if(res == 'saved') {
                toast.error("Photos has been deleted", {
                    theme: "colored"
                });
                _callImages()
            }
        });
    }

    function handleFocusBack(){
        window.removeEventListener('focus', handleFocusBack);
    }

    function _onClose() {
        props.onClose();
        setSubCat([]);
        setSubCatKey('');
        setSubCatName('');
        setPhotos([]);
        setActiveIndex(0);
        setProductName('');
        setDescription('');

        // setServiceOfferTxt('');
        // setServiceOffer([]);

        // setFoodTxt('');
        // setFoods([]);

        // setServingsTxt('');
        // setServings([]);

        // setEquipmentsTxt('');
        // setEquipments([]);

        setAddOns([]);
        setMin('');
        setJump('');
        setMax('');
        // setSetupTime('');
        // setDimention('');
        setPrice('');
        setDisabled(false);
    }

    function next() {
        const nextIndex = activeIndex === photo.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
      }
    
    function previous() {
        const nextIndex = activeIndex === 0 ? photo.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    }
}

export default Catering;