import React, { useState } from 'react';

import Header from './components/header';
import Footer from './components/footer';
import { toast } from 'react-toastify';

import {
    Container,
    Row,
    Col,
    Button,
    Form,
    FormGroup,
    Label,
    Input,
    Spinner
} from 'reactstrap';
import axios from 'axios';
import { mailLogo, emailSupport, phpSupport } from '../../utils/services';

const Support = (props) => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [btnDisabled, setDisabled] = useState(false);

    return (
        <div className="public-body">
            <Header />
            <Container>
                <div className="main-container">
                    <Row>
                        <Col md="6" xs="12">
                            <div>
                                <h1 className="slogan">
                                    Support
                                </h1>
                                <p>Encounter problems? Let us know</p>
                                <br/>
                                Email us: <a href="mailto:info@dibrah.sa">info@dibrah.sa</a>
                            </div>
                        </Col>
                        <Col md="6" xs="12">
                            <div className="center-right">
                                <img 
                                    src={require('../../assets/img/support.png').default} 
                                    style={{width: '100%', height: 600, objectFit: 'contain'}}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
            <Footer />
        </div>
    )

    function _submit() {
        if(name === '') {
            toast.error("Please enter full name", {
                theme: "colored"
            });
            return false;
        }

        if(email === '') {
            toast.error("Please enter email address", {
                theme: "colored"
            });
            return false;
        }

        var emailValid = email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        if(emailValid === null) {
            toast.error("Please enter valid email address", {
                theme: "colored"
            });
            return false;
        }

        if(message === '') {
            toast.error("Please enter your message", {
                theme: "colored"
            });
            return false;
        }

        setDisabled(true);
        axios.get(emailSupport, {
            params: {
                url: phpSupport,
                logo: mailLogo,
                message: message,
                name: name,
                email: email
            }
        }).then(res => {
            toast.success("Email successfully sent. We will back to you ASAP.", {
                theme: "colored"
            });

            setTimeout(() => {
                window.location.reload();
            }, 5000);
        });
    }
}

export default Support;