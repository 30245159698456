import React, { useEffect } from "react";
import Header from "components/Headers/MerchantHeader";

import {
    Card,
    CardHeader,
    CardBody,
    Container,
    Row,
    Col
} from "reactstrap";

import { useDispatch, useSelector } from 'react-redux';

import { FaUsers, FaRegFileImage, FaMoneyBillAlt } from 'react-icons/fa';
import { AiTwotoneShop } from 'react-icons/ai';

const Dashboard = (props) => {

    const dispatch = useDispatch();
    const order = useSelector(state => state.order);

    useEffect(() => {
        
    }, []);
    
    return (
        <div>
            <Header />
            <Container className="mt--7" fluid>
                <Row>
                    <Col lg="6" md="3" sm="12" xs="12">
                        <Card>
                            <CardHeader>
                                <h3>Previous Orders</h3>
                            </CardHeader>
                            <CardBody>
                                <center>
                                    <FaRegFileImage size={100}/>
                                    <br/><br/>
                                    <h1>0</h1>
                                </center>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg="6" md="3" sm="12" xs="12">
                        <Card>
                            <CardHeader>
                                <h3>Active Orders</h3>
                            </CardHeader>
                            <CardBody>
                                <center>
                                    <FaRegFileImage size={100}/>
                                    <br/><br/>
                                    <h1>0</h1>
                                </center>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <br/>

                <Row>
                    <Col lg="6" md="3" sm="12" xs="12">
                        <Card>
                            <CardHeader>
                                <h3>Scheduled Orders</h3>
                            </CardHeader>
                            <CardBody>
                                <center>
                                    <FaRegFileImage size={100}/>
                                    <br/><br/>
                                    <h1>0</h1>
                                </center>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg="6" md="3" sm="12" xs="12">
                        <Card>
                            <CardHeader>
                                <h3>Total Orders / day</h3>
                            </CardHeader>
                            <CardBody>
                                <center>
                                    <FaRegFileImage size={100}/>
                                    <br/><br/>
                                    <h1>0</h1>
                                </center>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Dashboard;