/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import { useState } from "react";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Spinner,
    Col,
} from "reactstrap";
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { authVendorLogin, authVendorData, addDefaultUser } from 'redux/actions/authAction';
import { getCategoryByKey } from 'redux/actions/categoryAction';

const Login = (props) => {

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const [btnDisabled, setDisabled] = useState(false);

    return (
      <>
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-4">
                    <img src={require('assets/img/brand/logo-main.png').default} width="100"/><br/>
                    <small>Vendor Control Panel</small>
                </div>
                <Form role="form">
                    <FormGroup className="mb-3">
                        <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <i className="ni ni-single-02" />
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input
                                placeholder="Username"
                                type="text"
                                name="username" 
                                onChange={(e) => dispatch(authVendorData(e.target))}
                                />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup>
                        <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <i className="ni ni-lock-circle-open" />
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input
                                placeholder="Password"
                                type="password"
                                name="password" 
                                onChange={(e) => dispatch(authVendorData(e.target))}
                                />
                        </InputGroup>
                    </FormGroup>
                    <div className="text-center">
                        <Button 
                            block className="my-4" 
                            color="danger" 
                            type="button"
                            onClick={() => _submit()} 
                            disabled={btnDisabled}
                            >
                            {btnDisabled === true ? <Spinner /> : 'Sign in'}
                        </Button>
                    </div>
                </Form>
            </CardBody>
          </Card>
        </Col>
      </>
    );

    function _submit() {
        if(auth.username === '') {
            toast.error("Please enter your username", {
                theme: "colored"
            });
            return false;
        }

        if(auth.password === '') {
            toast.error("Please enter your password", {
                theme: "colored"
            });
            return false;
        }

        setDisabled(true);

        dispatch(authVendorLogin(auth)).then(res => {
            if(res === 'not_found') {
                toast.error("User not found. Maybe your username and password are incorrect",{
                    theme: "colored"
                });
                setDisabled(false);
                return false;
            }else {
                window.localStorage.setItem('@vendor', res._key);
                dispatch(getCategoryByKey(res.data.category_key)).then(r => {
                    window.localStorage.setItem('@vendor_category', JSON.stringify(r));
                });

                setTimeout(() => {
                    window.location.href = '/vendor/dashboard';
                }, 2000);
            }
        });
    }
  };
  
  export default Login;
  