import React, { useEffect, useState } from "react";

import {
    Modal, 
    ModalHeader, 
    ModalBody, 
    ModalFooter,
    Button,
    Form, 
    FormGroup, 
    Label, 
    Input,
    Spinner,
    Row,
    Col,
    Badge
} from "reactstrap";
import { useDispatch, useSelector } from 'react-redux';
import { getSubCatByCategory } from 'redux/actions/subcatAction';

import PartyProps from "./edit_category_form/party_props";
import VenueRental from "./edit_category_form/venue_rental";
import Balloons from "./edit_category_form/balloons";
import Cakes from "./edit_category_form/cakes";
import Catering from "./edit_category_form/catering";
import Furniture from "./edit_category_form/furniture";
import Inflatables from "./edit_category_form/inflatables";
import Backdrops from "./edit_category_form/backdrops";
import Entertainers from "./edit_category_form/entertainers";
import Rentals from "./edit_category_form/rentals";
import Hospitality from "./edit_category_form/hospitality";
import Photography from "./edit_category_form/photography";
import MunchSuch from "./edit_category_form/munch_such";

const Edit = (props) => {

    let _key = props.selected._key;
    let data = props.selected.data;

    return _renderForm();

    function _renderForm() {
        switch(data.category_name) {
            case 'Party Props':
                return (
                    <PartyProps 
                        isOpen={props.isOpen} 
                        onClose={() => props.onClose()}
                        _key={_key}
                        data={data}
                        />
                )
                break;
            case 'Venue Rentals':
                return (
                    <VenueRental 
                        isOpen={props.isOpen} 
                        onClose={() => props.onClose()}
                        _key={_key}
                        data={data}
                        />
                )
                break;
            case 'Balloons':
                return (
                    <Balloons 
                        isOpen={props.isOpen} 
                        onClose={() => props.onClose()}
                        _key={_key}
                        data={data}
                        />
                );
                break;
            case 'Cakes':
                return (
                    <Cakes 
                        isOpen={props.isOpen} 
                        onClose={() => props.onClose()}
                        _key={_key}
                        data={data}
                        />
                );
                break;
            case 'Catering':
                return (
                    <Catering 
                        isOpen={props.isOpen} 
                        onClose={() => props.onClose()}
                        _key={_key}
                        data={data}
                        />
                );
                break;
            case 'Furniture':
                return (
                    <Furniture 
                        isOpen={props.isOpen} 
                        onClose={() => props.onClose()}
                        _key={_key}
                        data={data}
                        />
                );
                break;
            case 'Inflatables':
                return (
                    <Inflatables 
                        isOpen={props.isOpen} 
                        onClose={() => props.onClose()}
                        _key={_key}
                        data={data}
                        />
                );
                break;
            case 'Backdrops':
                return (
                    <Backdrops 
                        isOpen={props.isOpen} 
                        onClose={() => props.onClose()}
                        _key={_key}
                        data={data}
                        />
                );
                break;
            case 'Entertainers':
                return (
                    <Entertainers   
                        isOpen={props.isOpen} 
                        onClose={() => props.onClose()}
                        _key={_key}
                        data={data}
                        />
                )
                break;
            case 'Rentals':
                return (
                    <Rentals 
                        isOpen={props.isOpen} 
                        onClose={() => props.onClose()}
                        _key={_key}
                        data={data}
                        />
                );
                break;
            case 'Photography':
                return (
                    <Photography 
                        isOpen={props.isOpen} 
                        onClose={() => props.onClose()}
                        _key={_key}
                        data={data}
                        />
                );
                break;
            case 'Hospitality':
                return (
                    <Hospitality 
                        isOpen={props.isOpen} 
                        onClose={() => props.onClose()}
                        _key={_key}
                        data={data}
                        />
                );
                break;
            case 'Munch & Such':
                return (
                    <MunchSuch 
                        isOpen={props.isOpen} 
                        onClose={() => props.onClose()}
                        _key={_key}
                        data={data}
                        />
                );
                break;
            default:
                return null;
                break;
        }
    }
}

export default Edit;