import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { 
    Modal,
    ModalHeader, 
    ModalBody,
    ModalFooter,
    ListGroup,
    ListGroupItem,
    Row,
    Col,
    Button
} from "reactstrap";
import { orderPackageChangeStatus } from "redux/actions/orderAction";

const ViewDetails = (props) => {
    
    const { selected } = props;
    const dispatch = useDispatch();

    let photo = [];
    Object.keys(selected.data.data.photos).map(k => {
        photo.push(selected.data.data.photos[k].photo);
    });

    return (
        <Modal isOpen={props.isOpen} toggle={() => _onClose()} size="lg">
            <ModalHeader toggle={() => _onClose()}>Order Details</ModalHeader>
            <ModalBody>

                <ListGroup>
                    <ListGroupItem>
                        <strong>Order Date: </strong>{selected.data.orderDate}
                    </ListGroupItem>
                    <ListGroupItem>
                        <strong>Order ID: </strong>{selected.data.orderId}
                    </ListGroupItem>
                    <ListGroupItem>
                        <strong>Order Total: </strong>{selected.data.data.currency+' '+selected.data.grandTotal}
                    </ListGroupItem>
                </ListGroup>

                <br /><br />

                <Row>
                    <Col md="3" xs="12">
                        <img src={photo[0]} alt="" style={{width: '100%', height: 130, objectFit: 'cover', borderRadius: 10, marginBottom: 20}} />
                    </Col>
                    <Col md="9" xs="12">
                        <h3>{selected.data.data.package_name}</h3>
                        <span>{selected.data.status}</span>
                    </Col>
                </Row>

                <hr />
                
                <h3>Add Ons</h3>
                {selected.data.selectedAddons.map((item, k) => {

                    var addOnPhoto = [];
                    Object.keys(item.photos).map(k => {
                        addOnPhoto.push(item.photos[k].photo);
                    });

                    return (
                        <Row key={k}>
                            <Col md="3" xs="12">
                                <img src={addOnPhoto[0]} alt="" style={{width: '100%', height: 130, objectFit: 'cover', borderRadius: 10, marginBottom: 20}} />
                            </Col>
                            <Col md="9" xs="12">
                                <h3>{item.product_name}</h3>
                                <span>{item.currency+' '+item.price}</span>
                                {_renderFood(item)}
                            </Col>
                        </Row>
                    );
                })}

                <br /><br />

                <ListGroup>
                    <ListGroupItem>
                        <strong>Delivery Address: </strong> {selected.data.address.address}
                    </ListGroupItem>
                    <ListGroupItem>
                        <strong>Delivery Method: </strong> {selected.data.deliveryMethod}
                    </ListGroupItem>
                    <ListGroupItem>
                        <strong>Payment Method: </strong>{selected.data.paymentMethod}
                    </ListGroupItem>
                </ListGroup>

                <hr />

                <ListGroup>
                    <ListGroupItem>
                        <strong>Order Total: </strong>{selected.data.data.currency+' '+selected.data.total}
                    </ListGroupItem>
                    <ListGroupItem>
                        <strong>Delivery: </strong>{selected.data.data.currency+' '+selected.data.deliveryCharge}
                    </ListGroupItem>
                    <ListGroupItem>
                        <strong>VAT [15%]: </strong>{selected.data.data.currency+' '+selected.data.vat}
                    </ListGroupItem>
                    <ListGroupItem>
                        <strong>Grand Total: </strong>{selected.data.data.currency+' '+selected.data.grandTotal}
                    </ListGroupItem>
                </ListGroup>

            </ModalBody>
            <ModalFooter>
                {_renderBtn()}
                <Button onClick={() => _onClose()} color="secondary">Close</Button>
            </ModalFooter>
        </Modal>
    )

    function _renderFood(item) {
        if(item.category_name === "Catering") {
            return (
                <>
                    <hr />
                    <strong style={{fontSize: 20, marginBottom: 20}}>Food List</strong>
                    {selected.data.foods.map((val, key) => {
                        return (
                            <div key={key} style={{marginBottom: 20}}>
                                <span style={{fontWeight: 'bold'}}>{val.name}</span><br/>
                                <span>{item.currency+''+val.price}</span>
                            </div>
                        );
                    })}
                </>
            )
        }
    }

    function _renderBtn() {
        if(selected.data.status === "Order Submitted") {
            return (
                <>
                    <Button color="primary" onClick={() => _changeStatus('Order Approved')}>Approve</Button>{" "}
                    <Button color="danger" onClick={() => _changeStatus('Order Rejected')}>Reject</Button>{" "}
                </>
            )
        }

        if(selected.data.status === "Order Approved") {
            return (
                <>
                    <Button color="primary" onClick={() => _changeStatus('Out for Delivery')}>Out for Delivery</Button>
                </>
            )
        }

        if(selected.data.deliveryMethod === "Self Pickup") {
            return (
                <>
                    <Button color="primary" onClick={() => _changeStatus('Ready to pickup')}>Ready to Pickup</Button>
                </>
            )
        }
    }

    function _changeStatus(status) {
        dispatch(orderPackageChangeStatus(selected._key, status)).then(res => {
            if(res === "saved") {
                _onClose();
            }
        });
    }

    function _onClose() {
        props.onClose();
    }
}

export default ViewDetails;