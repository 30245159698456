import React, { useEffect, useState } from "react";
import Header from "components/Headers/AdminHeader";
import DataTable from 'react-data-table-component';
import {createFilter} from 'react-search-input';

import {
    Card,
    CardHeader,
    CardFooter,
    CardBody,
    Container,
    FormGroup, 
    Label, 
    Input,
    Button,
    Row,
    Col,
    ListGroup, 
    ListGroupItem,
    Spinner,
    Badge
} from "reactstrap";
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { updatePassword, newPassword } from 'redux/actions/adminAction';
import { getSettings, editSettings } from 'redux/actions/settingsAction';

const Settings = (props) => {

    const dispatch = useDispatch();
    const settings = useSelector(state => state.settings);
    const [current_password, setCurrentPassword]    = useState('');
    const [new_password, setNewPassword]            = useState('');
    const [confirm_password, setConfirmPassword]    = useState('');
    const [delivery_charge, setDeliveryCharge]      = useState(0);
    const [btnDisabled, setDisabled]                = useState(false);

    useEffect(() => {
        dispatch(getSettings());
    }, []);

    return (
        <div>
            <Header />
            <Container className="mt--7" fluid>
                <Row>
                    <Col>
                        <Card>
                            <CardHeader>
                                <h3>Change Password</h3>
                            </CardHeader>
                            <CardBody>
                                <FormGroup>
                                    <Label>Current Password</Label>
                                    <Input 
                                        type="password" 
                                        name="current_password"
                                        value={current_password}
                                        onChange={(e) => setCurrentPassword(e.target.value)}
                                        />
                                </FormGroup>
                                <FormGroup>
                                    <Label>New Password</Label>
                                    <Input 
                                        type="password" 
                                        name="new_password"
                                        value={new_password}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                        />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Confirm Password</Label>
                                    <Input 
                                        type="password" 
                                        name="confirm_password"
                                        value={confirm_password}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        />
                                </FormGroup>
                            </CardBody>
                            <CardFooter>
                                <Button 
                                    color="primary" 
                                    onClick={() => _submit()}
                                    disabled={btnDisabled}
                                    >
                                    {btnDisabled === true ? <Spinner /> : 'Save'}
                                </Button>
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col>
                        {settings.data !== null ? 
                            <Card>
                                <CardHeader>
                                    <h3>Delivery Charge</h3>
                                    {settings.data.delivery_charge+' SAR'}
                                </CardHeader>
                                <CardBody>
                                    <FormGroup>
                                        <Label>Update Delivery Charge</Label>
                                        <Input 
                                            type="number" 
                                            name="delivery_charge"
                                            value={delivery_charge}
                                            onChange={(e) => setDeliveryCharge(e.target.value)}
                                            />
                                    </FormGroup>
                                </CardBody>
                                <CardFooter>
                                <Button 
                                    color="primary" 
                                    onClick={() => _updateCharge()}
                                    disabled={btnDisabled}
                                    >
                                    {btnDisabled === true ? <Spinner /> : 'Save'}
                                </Button>
                            </CardFooter>
                            </Card>
                        : <Spinner />}
                    </Col>
                </Row>
            </Container>
        </div>
    )

    function _submit() {
        if(current_password === '') {
            toast.error("Please enter current password", {
                theme: "colored"
            });
            return false;
        }

        if(new_password === '') {
            toast.error("Please enter new password", {
                theme: "colored"
            });
            return false;
        }

        if(new_password.length < 6) {
            toast.error("Password atleast 6 characters", {
                theme: "colored"
            });
            return false;
        }

        if(confirm_password === '') {
            toast.error("Please enter confirm password", {
                theme: "colored"
            });
            return false;
        }

        if(confirm_password !== new_password) {
            toast.error("Confirm password is not equal to new password", {
                theme: "colored"
            });
            return false;
        }

        setDisabled(true);

        dispatch(updatePassword(current_password)).then(res => {
            if(res === 'incorrect') {
                setDisabled(false);
                toast.error("Current password not found", {
                    theme: "colored"
                });
                return false;
            }else {
                dispatch(newPassword(new_password)).then(resp => {
                    if(resp === 'saved') {
                        toast.success("New password successfully saved", {
                            theme: "colored"
                        });
                        setCurrentPassword('');
                        setNewPassword('');
                        setConfirmPassword('');
                        setDisabled(false);
                    }else {
                        setDisabled(false);
                        toast.error("Error occur please try again later", {
                            theme: "colored"
                        });
                        return false;
                    }
                })
            }
        })
    }

    function _updateCharge() {

        if(delivery_charge === 0 || delivery_charge === '') {
            toast.error("Please enter delivery charge", {
                theme: "colored"
            });
            return false;
        }

        setDisabled(true);

        dispatch(editSettings(delivery_charge)).then(res => {
            if(res === 'saved') {
                toast.success("New delivery charge successfully saved", {
                    theme: "colored"
                });
                setDeliveryCharge(0);
                setDisabled(false);
            }else {
                setDisabled(false);
                toast.error("Error occur please try again later", {
                    theme: "colored"
                });
                return false;
            }
        })
    }
}

export default Settings;