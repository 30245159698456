const cols = (btn, _renderImg, _renderStatus) =>  [
    {
        name: 'Photo',
        selector: row => _renderImg(row),
    },
    {
        name: 'Category',
        selector: row => row.data.package_category_name,
    },
    {
        name: 'Name',
        selector: row => row.data.package_name,
    },
    {
        name: 'Price',
        selector: row => row.data.price+' '+row.data.currency,
    },
    {
        name: 'Date Created',
        selector: row => row.data.created_date,
    },
    {
        name: '',
        selector: row => btn(row),
        right: true
    },
];

export default cols