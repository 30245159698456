import firebase from 'firebase';

const app = firebase.initializeApp({
    apiKey: "AIzaSyBxsvzxW2lXMoGRbNU9cuKX3EB3y5a3mb0",
    authDomain: "dibrah-app.firebaseapp.com",
    databaseURL: "https://dibrah-app-default-rtdb.firebaseio.com",
    projectId: "dibrah-app",
    storageBucket: "dibrah-app.appspot.com",
    messagingSenderId: "368329672932",
    appId: "1:368329672932:web:e3c1063c7357694eed44f1",
    measurementId: "G-LJCMP1TG9V"
});

export const fbApp = app;
export const oAuth = firebase.auth();
const db = app.database();
const storage = app.storage();

//
// RTDB
//
export const adminRef               = db.ref('admin_users'); // users
export const vendorsRef             = db.ref('vendors');
export const productsRef            = db.ref('products');
export const offersRef              = db.ref('offers');
export const packagesRef            = db.ref('packages');
export const adminPackagesRef       = db.ref('admin_packages');
export const discountsRef           = db.ref('discounts');
export const bannersRef             = db.ref('banners');
export const settingsRef            = db.ref('settings');

export const ordersRef              = db.ref('orders');
export const customersRef           = db.ref('customers');
export const categoryRef            = db.ref('categories');
export const subCategoryRef         = db.ref('subcategories');
export const cateringCatRef         = db.ref('catering_category');
export const formsRef               = db.ref('forms');
export const moodBoardRef           = db.ref('mood_board');
export const packageOrdersRef       = db.ref('package_orders');

//
// Storage
//
export const productsStorage        = storage.ref('products');
export const packagesStorage        = storage.ref('packages');
export const offersStorage          = storage.ref('offers');
export const discountsStorage        = storage.ref('discounts');
export const categoriesStorage      = storage.ref('categories');
export const subCategoriesStorage   = storage.ref('subcategories');
export const vendorStorage          = storage.ref('vendors');
export const bannerStorage          = storage.ref('banners');
