import React from 'react';

import Header from './components/header';
import Footer from './components/footer';

import {
    Container,
    Row,
    Col,
    Button
} from 'reactstrap';

const Home = (props) => {
    return (
        <div className="public-body">
            <Header />
            <Container>
                <div className="main-container">
                    <Row>
                        <Col md="6" xs="12">
                            <div className="center-left">
                                <p className='slogan'>Plan your Day</p>
                                <span>Download the app and start planning your awesome event</span>
                                <Row style={{marginTop: 30}}>
                                    <Col md="6" xs="6">
                                        <img 
                                            src='https://icelandairwaves.is/wp-content/uploads/2019/10/flat800x800070f.jpg'
                                            style={{width: '100%', height: 50, objectFit: 'contain'}}
                                            />
                                    </Col>
                                    <Col md="6" xs="6">
                                        <img 
                                            src='https://upload.wikimedia.org/wikipedia/commons/thumb/7/78/Google_Play_Store_badge_EN.svg/1024px-Google_Play_Store_badge_EN.svg.png'
                                            style={{width: '100%', height: 50, objectFit: 'contain'}}
                                            />
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col md="6" xs="12">
                            <div className="center-right">
                                <img 
                                    src={require('../../assets/img/celeb.gif').default} 
                                    style={{width: '100%', height: 600, objectFit: 'contain'}}
                                    />
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
            <Footer />
        </div>
    )
}

export default Home;