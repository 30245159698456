import { 
    VENDOR_DATA,
    VENDOR_ACTION_INPUT,
    VENDOR_ACTION_FILE,
    VENDOR_ACTION_RESET,
    VENDOR_ACTION_EDIT
} from '../types';

const initialState = {
    loading: false,
    data: [],
    _key: '',
    id: '',
    categories: [],
    company_name: '',
    company_name_ar: '',
    cr_number: '',
    cr_file: [],
    shop_name: '',
    shop_name_ar: '',
    country: 'Saudi Arabia',
    address: '',
    num_of_branches: '1',
    part_of_franchise: 'no',
    have_a_deliver_service: 'no',
    are_in_other_delivery_app: 'no',
    phone_number: '',
    email: '',
    are_you_the_owner: 'no',
    username: '',
    password: '123456'
}

const vendorReducer = (state = initialState, action) => {
    switch(action.type) {
        case VENDOR_DATA:
            return {
                ...state,
                data: action.payload,
                loading: action.loading
            }
            break;
        case VENDOR_ACTION_INPUT:
            return {...state, [action.payload.name]: action.payload.value}
            break;
        case VENDOR_ACTION_FILE:
            return {...state, cr_file: action.payload}
            break;
        case VENDOR_ACTION_RESET:
            return {
                ...state, 
                _key: '',
                id: '',
                categories: [],
                company_name: '',
                company_name_ar: '',
                cr_number: '',
                cr_file: [],
                shop_name: '',
                shop_name_ar: '',
                country: 'Saudi Arabia',
                address: '',
                num_of_branches: '1',
                part_of_franchise: 'no',
                have_a_deliver_service: 'no',
                are_in_other_delivery_app: 'no',
                phone_number: '',
                email: '',
                are_you_the_owner: 'no',
                username: '',
                password: '123456'
            }
            break;
        case VENDOR_ACTION_EDIT:
            return {
                ...state, 
                _key: action.payload._key,
                id: action.payload.data.id,
                categories: (!action.payload.data.categories) ? [] : action.payload.data.categories,
                category_key: action.payload.data.category_key,
                company_name: action.payload.data.company_name,
                company_name_ar: action.payload.data.company_name_ar,
                cr_number: action.payload.data.cr_number,
                cr_file: action.payload.data.cr_file,
                shop_name: action.payload.data.shop_name,
                shop_name_ar: action.payload.data.shop_name_ar,
                country: action.payload.data.country,
                address: action.payload.data.address,
                num_of_branches: action.payload.data.num_of_branches,
                part_of_franchise: action.payload.data.part_of_franchise,
                have_a_deliver_service: action.payload.data.have_a_deliver_service,
                are_in_other_delivery_app: action.payload.data.are_in_other_delivery_app,
                phone_number: action.payload.data.phone_number,
                email: action.payload.data.email,
                are_you_the_owner: action.payload.data.are_you_the_owner,
                username: action.payload.data.username
            }
            break;
        default:
            return state;
            break;
    }
}   

export default vendorReducer;