import React, { useEffect, useState } from "react";
import DataTable from 'react-data-table-component';
import {createFilter} from 'react-search-input';

import {
    ButtonGroup,
    Button,
    Input,
    Badge
} from "reactstrap";
import { useDispatch, useSelector } from 'react-redux';
import { getDiscountsItemsAdmin } from 'redux/actions/discountsAction';

import cols from './cols';
import ViewData from './view';

const Discounts = (props) => {

    const dispatch = useDispatch();
    const discounts = useSelector(state => state.discounts);

    useEffect(() => {
        dispatch(getDiscountsItemsAdmin());
    }, []);

    const [search, setSearch] = useState('');
    const [view, setView] = useState(false);
    const [selected, setSelected] = useState(null);
    
    const KEYS_TO_FILTERS = ['data.title_en', 'data.title_ar']
    const filterData = discounts.data.filter(createFilter(search, KEYS_TO_FILTERS));

    return (
        <div>
            <DataTable
                columns={cols(_renderBtn, _renderImg, _renderStatus)}
                data={filterData}
                progressPending={discounts.loading}
                subHeader
                subHeaderComponent={
                    <Input
                        placeholder="Search"
                        onChange={(e) => setSearch(e.target.value)}
                        style={{width: 250}}
                        />
                }
                pagination
                />
            {selected !== null ? 
                <ViewData 
                    isOpen={view}
                    onClose={() => setView(false)}
                    selected={selected}
                    />
            : null}
        </div>
    )

    function _renderImg(row) {
        if(row.data.photos !== undefined) {
            var photos = [];
            Object.keys(row.data.photos).map(k => {
                photos.push(row.data.photos[k]);
            });
            return <img src={photos[0].photo} alt="" style={{width: 50, height: 50, objectFit: 'cover'}} />
        }
    }

    function _renderBtn(row) {
        return (
            <ButtonGroup>
                <Button size="sm" color="warning" onClick={() => _edit(row)}>View</Button>
                {/* <Button size="sm" color="info" onClick={() => _viewRating(row)}>Rating</Button> */}
            </ButtonGroup>
        );
    }

    function _renderStatus(row) {
        switch(row.data.status) {
            case 'pending':
                return <Badge color="warning">Pending</Badge>
                break;
            case 'rejected':
                return (
                    <>
                        <Badge color="danger">Rejected</Badge>
                        <br/>
                        <span>{row.data.reason}</span>
                    </>
                )
                break;
            default:
                return <Badge color="success">Approved</Badge>
                break;
        } 
    }

    function _edit(row) {
        setSelected(row);
        setView(true);
    }
}

export default Discounts;