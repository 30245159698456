import { adminRef, vendorsRef } from '../../database/firebase';
import { salt, pepper } from '../../utils/password';
import { AUTH_ADMIN, AUTH_VENDOR } from '../types';
import { Base64 } from 'js-base64';
import moment from 'moment';

const addDefaultUser = () => async dispatch => {
    let password = salt+Base64.encode('123456')+pepper;

    adminRef.push({
        access: 'admin_'+password,
        username: 'admin',
        password: password,
        fullname: 'Administrator',
        email: 'admin@dibrah.com',
        created_date: moment().format('YYYY-MM-DD h:mm:ss')
    });
}

const authAdminLogin = (data) => async dispatch => {
    return new Promise((resolve, reject) => {
        let password = salt+Base64.encode(data.password)+pepper;
        adminRef
            .orderByChild('access')
            .equalTo(data.username+'_'+password)
            .once('value', snap => {
                if(snap.val() === null) {
                    resolve('not_found');
                }else {
                    var item = []
                    snap.forEach(child => {
                        item.push({
                            _key: child.key,
                            data: child.val().role
                        })
                    });
                    resolve(item[0]);
                }
            });
    });
}

const authVendorLogin = (data) => async dispatch => {
    return new Promise((resolve, reject) => {
        let password = salt+Base64.encode(data.password)+pepper;
        vendorsRef
            .orderByChild('access')
            .equalTo(data.username+'_'+password)
            .once('value', snap => {

                console.log(snap.val())

                if(snap.val() === null) {
                    resolve('not_found');
                }else {
                    var item = []
                    snap.forEach(child => {
                        item.push({
                            _key: child.key,
                            data: child.val()
                        })
                    });
                    resolve(item[0]);
                }
            });
    });
}

const authAdminData = (data) => {
    return {
        type: AUTH_ADMIN,
        payload: data
    }
}

const authVendorData = (data) => {
    return {
        type: AUTH_VENDOR,
        payload: data
    }
}

export {
    addDefaultUser,
    authAdminLogin,
    authVendorLogin,
    authAdminData,
    authVendorData
}