import { packagesRef, packagesStorage } from '../../database/firebase';
import { PACKAGES_DATA, PACKAGES_PHOTO } from '../types';
import moment from 'moment';

const getPackagesItemsAdmin = () => async dispatch => {
    dispatch({type: PACKAGES_DATA, payload: [], loading: true});
    packagesRef.on('value', snap => {
        if(snap.val() === null) {
            dispatch({type: PACKAGES_DATA, payload: [], loading: false});
        }else {
            let item = [];
            snap.forEach(child => {
                item.push({
                    _key: child.key,
                    data: child.val()
                })
            });
            dispatch({type: PACKAGES_DATA, payload: item.reverse(), loading: false});
        }
    });
}

const getPackagesItems = (vendor_key) => async dispatch => {
    dispatch({type: PACKAGES_DATA, payload: [], loading: true});
    packagesRef.orderByChild("vendor_key").equalTo(vendor_key).on('value', snap => {
        if(snap.val() === null) {
            dispatch({type: PACKAGES_DATA, payload: [], loading: false});
        }else {
            let item = [];
            snap.forEach(child => {
                item.push({
                    _key: child.key,
                    data: child.val()
                })
            });
            dispatch({type: PACKAGES_DATA, payload: item.reverse(), loading: false});
        }
    });
}

const getPackagesByKey = (key) => async dispatch => {
    return new Promise((resolve, reject) => {
        packagesRef.child(key).on('value', snap => {
            if(snap.val() !== null) {
                var photos = [];
                if(snap.val().photos !== undefined) {
                    Object.keys(snap.val().photos).map(k => {
                        photos.push({
                            _key: k,
                            data: snap.val().photos[k]
                        });
                    });
                    resolve(photos);
                }
            }
        });
    });
}

const addPackages = (state) => async dispatch => {
    return new Promise((resolve, reject) => {
        var id = Math.floor(10000 + Math.random() * 90000); 
        var ref = packagesRef.push({
            id: id.toString(),
            currency: 'SAR',
            package_category_key: state.package_category_key,
            package_category_name: state.package_category_name,
            package_name: state.package_name,
            description: state.description,
            price: state.price,
            requirements: state.requirements,
            space: state.space,
            package_details: state.package_details,
            package_includes: state.package_includes,
            created_date: moment().format('YYYY-MM-DD hh:mm:ss')
        });

        for(var i=0; i<state.photo.length; i++) {
            var photoID = Math.floor(10000 + Math.random() * 90000);
            dispatch(uploadImage(ref.key, id, photoID, state.photo[i], resolve, reject));
        }
    });
}

const uploadImage = (key, id, photoID, photo, resolve, reject) => async dispatch => {
    var storageRef = packagesStorage.child(id.toString()).child(photoID.toString()+'-packages.png').put(photo);
    storageRef.on('state_changed', function(snapshot) {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        if(progress === 100) {
            resolve('saved');
        }
    }, function(error) {
        resolve(error);
    }, function() {
        storageRef.snapshot.ref.getDownloadURL().then(function(downloadURL) {
            packagesRef.child(key).child('photos').push({
                id: photoID.toString(),
                photo: downloadURL
            });
        });
    });
}

const editPackages = (state) => async dispatch => {
    return new Promise((resolve, reject) => {
        packagesRef.child(state._key).update(state);
        resolve('saved');
    });
}

const uploadImageItemOnly = (key, id, photo) => async dispatch => {
    return new Promise((resolve, reject) => {
        var photoID = Math.floor(10000 + Math.random() * 90000);
        var storageRef = packagesStorage.child(id).child(photoID.toString()+'-packages.png').put(photo);
        storageRef.on('state_changed', function(snapshot) {
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            if(progress === 100) {
                resolve('saved');
            }
        }, function(error) {
            resolve(error);
        }, function() {
            storageRef.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                packagesRef.child(key).child('photos').push({
                    id: photoID.toString(),
                    photo: downloadURL
                });
            });
        });
    });
}

const deleteImage = (key, id, photoKey, photoID) => async dispatch => {
    return new Promise((resolve, reject) => {
        packagesStorage.child(id.toString()).child(photoID.toString()+'-packages.png').delete();
        packagesRef.child(key).child('photos').child(photoKey).remove();
        resolve('saved');
    });
}

const deletePackages = (_key, id, photos) => async dispatch => {
    return new Promise((resolve, reject) => {
        if(photos.length !== 0) {
            for(var i=0; i<photos.length; i++) {
                packagesStorage.child(id).child(photos[i].data.id+'-packages.png').delete();
            }
        }
        packagesRef.child(_key).remove();
        resolve('saved');
    });
}

const changeStatus = (state) => async dispatch => {
    return new Promise((resolve, reject) => {
        packagesRef.child(state._key).update({
            status: state.status,
            reason: state.reason,
            commission: state.commission
        });
        resolve('saved');
    });
}

export {
    getPackagesItemsAdmin,
    getPackagesItems,
    addPackages,
    editPackages,
    uploadImageItemOnly,
    deleteImage,
    deletePackages,
    getPackagesByKey,
    changeStatus
}