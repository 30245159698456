import React, { useEffect, useState } from "react";

import {
    Modal, 
    ModalHeader, 
    ModalBody, 
    ModalFooter,
    Button,
    Form, 
    FormGroup, 
    Label, 
    Input,
    Spinner
} from "reactstrap";
import { toast } from 'react-toastify';
import Resizer from "react-image-file-resizer";
import { useDispatch, useSelector } from 'react-redux';
import { addCateringCategory, inputAction, inputActionReset } from 'redux/actions/cateringAction';

const Add = (props) => {

    const dispatch = useDispatch();
    const catering = useSelector(state => state.catering);
    const [loading, setloading] = useState(false);

    return (
        <Modal isOpen={props.isOpen}>
            <ModalHeader>Add Catering Category</ModalHeader>
            <ModalBody>
                <Form>
                    <FormGroup>
                        <Label>Type</Label>
                        <Input 
                            type="select" 
                            name="type"
                            onChange={(e) => dispatch(inputAction(e.target))}
                            value={catering.type}
                            >
                            <option value="">Select Type</option>
                            <option value="catering_services">Catering Services</option>
                            <option value="food_trucks">Food Trucks</option>
                            <option value="food_stations">Food Stations</option>
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label>Name</Label>
                        <Input 
                            type="text" 
                            name="name" 
                            onChange={(e) => dispatch(inputAction(e.target))}
                            value={catering.name}
                            />
                    </FormGroup>
                    <FormGroup>
                        <Label>Name (Arabic)</Label>
                        <Input 
                            type="text" 
                            name="name_ar" 
                            onChange={(e) => dispatch(inputAction(e.target))} 
                            style={{textAlign: "right"}}
                            value={catering.name_ar}/>
                    </FormGroup>
                </Form>
            </ModalBody>
            <ModalFooter>
                {loading === true ? <Spinner /> : 
                    <>
                        <Button 
                            color="primary" 
                            onClick={() => _submit()}>
                                Save
                        </Button>{' '}
                        <Button color="secondary" onClick={() => _onClose()}>Cancel</Button>
                    </>
                }
            </ModalFooter>
        </Modal>
    )

    function _submit() {

        if(catering.type === '') {
            toast.error("Please enter sub category name", {
                theme: "colored"
            });
            return false;
        }

        if(catering.name === '') {
            toast.error("Please enter sub category name", {
                theme: "colored"
            });
            return false;
        }
        
        if(catering.name_ar === '') {
            toast.error("Please enter sub category name in arabic", {
                theme: "colored"
            });
            return false;
        }

        let params = {
            type: catering.type,
            name: catering.name,
            name_ar: catering.name_ar
        }

        setloading(true);

        dispatch(addCateringCategory(params)).then(res => {
            if(res === 'saved') {
                dispatch(inputActionReset());
                setloading(false);
                props.onClose();
            }
        });
    }

    function _onClose() {
        dispatch(inputActionReset());
        props.onClose();
    }
}

export default Add;