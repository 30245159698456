import React, { useEffect, useState } from "react";
import Header from "components/Headers/AdminHeader";
import DataTable from 'react-data-table-component';
import {createFilter} from 'react-search-input';

import {
    Card,
    CardHeader,
    CardBody,
    Container,
    Input,
    Button,
    ButtonGroup,
    Badge
} from "reactstrap";

import { useDispatch, useSelector } from 'react-redux';
// import { getMerchants, inputEdit } from 'redux/actions/merchantAction';
// import { getCountry } from 'redux/actions/countryAction';
// import { getIndustries } from 'redux/actions/industryAction';

// import cols from './cols';
// import Add from './add';
// import Edit from './edit';

const Notification = (props) => {

    // const dispatch = useDispatch();
    // const merchant = useSelector(state => state.merchant);
    // const country = useSelector(state => state.country);
    // const industry = useSelector(state => state.industry);

    // useEffect(() => {
    //     dispatch(getMerchants());
    //     dispatch(getCountry());
    //     dispatch(getIndustries());
    // }, []);

    // const [search, setSearch] = useState('');
    // const [add, setAdd] = useState(false);
    // const [edit, setEdit] = useState(false);
    
    // const KEYS_TO_FILTERS = ['data.merchant_name']
    // const filterData = merchant.data.filter(createFilter(search, KEYS_TO_FILTERS));
    
    return (
        <div>
            <Header />
            <Container className="mt--7" fluid>
                <Card>
                    <CardHeader>
                        <h3>Notification</h3>
                    </CardHeader>
                    <CardBody>
                        {/* <Button color="primary" onClick={() => setAdd(true)}>Add Merchant</Button>
                        <DataTable
                            columns={cols(_renderBtn, _renderCountry, _renderIndustry, _renderStatus)}
                            data={filterData}
                            progressPending={merchant.loading}
                            subHeader
                            subHeaderComponent={
                                <Input
                                    placeholder="Search"
                                    onChange={(e) => setSearch(e.target.value)}
                                    style={{width: 250}}
                                    />
                            }
                            pagination
                            /> */}
                    </CardBody>
                </Card>
            </Container>
            {/* <Add
                isOpen={add}
                onClose={() => setAdd(false)}
                title="Add Merchant"
                />
            {merchant._key !== "" ? 
                <>
                    <Edit
                        isOpen={edit}
                        onClose={() => setEdit(false)}
                        title="Edit Merchant"
                        />
                </>
            : null} */}
        </div>
    )

    // function _renderBtn(row) {
    //     return (
    //         <ButtonGroup>
    //             <Button size="sm" color="warning" onClick={() => _edit(row)}>Edit</Button>
    //         </ButtonGroup>
    //     );
    // }

    // function _renderCountry(row) {
    //     var countryName = "";
    //     country.data.some(item => {
    //         if(row.data.country_key === item._key) {
    //             countryName = item.data.country_name;
    //         }
    //     });
    //     return countryName;
    // }

    // function _renderIndustry(row) {
    //     var industryName = "";
    //     industry.data.some(item => {
    //         if(row.data.industry_key === item._key) {
    //             industryName = item.data.name;
    //         }
    //     });
    //     return industryName;
    // }

    // function _renderStatus(row) {
    //     switch(row.data.status) {
    //         case 'hide':
    //             return <Badge color="dark">Hidden</Badge>
    //             break;
    //         case 'show':
    //             return <Badge color="success">Visible</Badge>
    //             break;
    //         default:
    //             return <Badge color="warning">For Review</Badge>
    //             break;
    //     } 
    // }

    // function _edit(row) {
    //    dispatch(inputEdit(row));
    //    setEdit(true);
    // }
}

export default Notification;