import React, { useEffect, useState } from "react";
import Header from "components/Headers/AdminHeader";

import {
    Container,
    Card,
    CardHeader,
    CardBody,
    ButtonGroup,
    Button
} from "reactstrap";

import Discounts from './discounts';
import Offers from './offers';
import Packages from './packages';

const Request = (props) => {

    const [activePage, setActivePage] = useState(0);

    return (
        <div>
            <Header />
            <Container className="mt--7" fluid>
                <Card>
                    <CardHeader>
                        <ButtonGroup>
                            <Button onClick={() => setActivePage(0)} color={activePage === 0 ? "primary" : ""}>Discounts</Button>
                            <Button onClick={() => setActivePage(1)} color={activePage === 1 ? "primary" : ""}>Offers</Button>
                            <Button onClick={() => setActivePage(2)} color={activePage === 2 ? "primary" : ""}>Packages</Button>
                        </ButtonGroup>
                    </CardHeader>
                    <CardBody>
                        {_renderPages()}
                    </CardBody>
                </Card>
            </Container>
        </div>
    )

    function _renderPages() {
        switch(activePage) {
            case 0:
                return <Discounts />
                break;
            case 1:
                return <Offers />
                break;
            case 2:
                return <Packages />
                break;
        }
    }
}

export default Request;