import { moodBoardRef } from '../../database/firebase';
import { MOOD_DATA } from '../types';

const getMoods = () => async dispatch => {
    dispatch({type: MOOD_DATA, payload: [], loading: true});
    moodBoardRef.on('value', snap => {
        if(snap.val() === null) {
            dispatch({type: MOOD_DATA, payload: [], loading: false});
        }else {
            let item = [];
            snap.forEach(child => {
                item.push({
                    _key: child.key,
                    data: child.val()
                })
            });
            dispatch({type: MOOD_DATA, payload: item.reverse(), loading: false});
        }
    });
}

export {
    getMoods
}