const cols = (btn, _renderStatus, _renderImg) =>  [
    {
        name: 'Photo',
        width: '100px',
        selector: row => _renderImg(row),
    },
    {
        name: 'Order ID',
        selector: row => row.data.orderId,
    },
    {
        name: 'Name',
        selector: row => row.data.data.package_name,
    },
    {
        name: 'Delivery',
        selector: row => row.data.deliveryMethod,
    },
    {
        name: 'Total Price',
        selector: row => row.data.data.currency+' '+row.data.total,
    },
    {
        name: 'Date Created',
        selector: row => row.data.created_date,
    },
    {
        name: '',
        selector: row => btn(row),
        right: true
    },
];

export default cols