/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";

// import { useDispatch, useSelector } from 'react-redux';
// import { getMerchants } from 'redux/actions/merchantAction';
// import { getCustomers } from 'redux/actions/customerAction';

const Header = () => {

  // const dispatch = useDispatch();
  // const customer = useSelector(state => state.customer);
  // const merchant = useSelector(state => state.merchant);

  // useEffect(() => {
  //     dispatch(getMerchants());
  //     dispatch(getCustomers());
  // }, []);

  const formatCash = n => {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
  };

  // var role = window.localStorage.getItem('@role');

  return (
    <>
      <div className="header bg-gradient-primary pb-8 pt-5 pt-md-8">
        {/* <Container fluid>
          <div className="header-body">
            <Row>
              <Col xs="12" md="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Profit
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {formatCash(200000)}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                          <i className="fas fa-chart-bar" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col xs="12" md="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Customers
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {formatCash(0)}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                          <i className="fas fa-users" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col xs="12" md="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Merchants
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {formatCash(0)}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                          <i className="fas fa-chart-pie" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container> */}
      </div>
    </>
  );
};

export default Header;
