import { 
    PRODUCT_DATA,
    PRODUCT_PHOTO,
    PRODUCT_ENTERTAINER_PHOTO,
    PRODUCT_HOSPITALITY_PHOTO
} from '../types';

const initialState = {
    loading: false,
    data: [],
    photos: [],
    icons: [],
    outfits: []
}

const merchantProductReducer = (state = initialState, action) => {
    switch(action.type) {
        case PRODUCT_DATA:
            return {
                ...state,
                data: action.payload,
                loading: action.loading
            }
            break;
        case PRODUCT_PHOTO:
            return {
                ...state,
                photos: action.payload,
                loading: action.loading
            }
            break;
        case PRODUCT_ENTERTAINER_PHOTO:
            return {
                ...state,
                icons: action.payload,
                loading: action.loading
            }
            break;
        case PRODUCT_HOSPITALITY_PHOTO:
            return {
                ...state,
                outfits: action.payload,
                loading: action.loading
            }
            break;
        default:
            return state;
            break;
    }
}   

export default merchantProductReducer;