import { 
    ADMIN_DATA, 
    ADMIN_ACTION_INPUT, 
    ADMIN_ACTION_RESET,
    ADMIN_ACTION_EDIT 
} from '../types';
import { adminRef } from '../../database/firebase';
import { Base64 } from 'js-base64';
import { salt, pepper } from '../../utils/password';
import moment from 'moment';

const getAdmin = () => async dispatch => {
    dispatch({type: ADMIN_DATA, payload: [], loading: true});
    adminRef.on('value', snap => {
        if(snap.val() === null) {
            dispatch({type: ADMIN_DATA, payload: [], loading: false});
        }else {
            var item = [];
            snap.forEach(child => {
                let admin = window.localStorage.getItem('@admin');
                if(admin !== child.key && child.val().username !== 'admin') {
                    item.push({
                        _key: child.key,
                        data: child.val()
                    });
                } 
            });
            dispatch({type: ADMIN_DATA, payload: item.reverse(), loading: false});
        }
    });
}

const getAdminSelected = (key) => async dispatch => {
    return new Promise((resolve, reject) => {
        adminRef.child(key).once('value', snap => {
            resolve(snap.val())
        });
    });
}

const checkAdminUsername = (username) => async dispatch => {
    return new Promise((resolve, reject) => {
        adminRef.orderByChild('username').equalTo(username).once('value', snap => {
            if(snap.val() === null) {
                resolve('available');
            }else {
                resolve('not_available');
            }
        });
    });
}

const addAdmin = (state) => async dispatch => {
    return new Promise((resolve, reject) => {
        let password = salt+Base64.encode('123456')+pepper;
        adminRef.push({
            access: state.username+'_'+password,
            username: state.username,
            password: password,
            fullname: state.fullname,
            role: state.role,
            created_date: moment().format('YYYY-MM-DD hh:mm:ss')
        });
        resolve('saved');
    });
}

const editAdmin = (state) => async dispatch => {
    return new Promise((resolve, reject) => {
        adminRef.child(state._key).update({
            fullname: state.fullname
        });
        resolve('saved');
    });
}

const deleteAdmin = (state) => async dispatch => {
    return new Promise((resolve, reject) => {
        adminRef.child(state._key).remove();
        resolve('saved');
    });
}

const updatePassword = (cpassword) => async dispatch => {
    return new Promise((resolve, reject) => {
        let admin = window.localStorage.getItem('@admin');
        let password = salt+Base64.encode(cpassword)+pepper;
        adminRef.child(admin).once('value', snap => {
            if(snap.val().password === password) {
                resolve('correct');
            }else {
                resolve('incorrect');
            }
        });
    });
}

const newPassword = (new_password) => async dispatch => {
    return new Promise((resolve, reject) => {
        let admin = window.localStorage.getItem('@admin');
        let password = salt+Base64.encode(new_password)+pepper;
        adminRef.child(admin).once('value', snap => {
            adminRef.child(admin).update({
                access: snap.val().username+'_'+password,
                password: password
            });
            resolve('saved');
        });
    });
}

const adminInput = (data) => {
    return {
        type: ADMIN_ACTION_INPUT,
        payload: data
    }
}

const adminInputReset = () => {
    return {
        type: ADMIN_ACTION_RESET,
        payload: {}
    }
}

const adminInputEdit = (data) => {
    return {
        type: ADMIN_ACTION_EDIT,
        payload: data
    }
}

export {
    getAdmin,
    getAdminSelected,
    addAdmin,
    editAdmin,
    deleteAdmin,
    checkAdminUsername,
    updatePassword,
    newPassword,
    adminInput,
    adminInputReset,
    adminInputEdit
}