/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/vendors/dashboard";
import Products from "views/vendors/products";
import Offers from "views/vendors/offers";
import Packages from "views/vendors/packages";
import Discounts from "views/vendors/discounts";
import Orders from "views/vendors/orders";
import Customers from "views/vendors/customers";
import Settings from "views/vendors/settings";

import SubCategories from "views/vendors/subcategories";
import Users from "views/vendors/users";
import Icons from "views/examples/Icons";

var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-info",
    component: Dashboard,
    layout: "/vendor",
  },
  {
    path: "/products",
    name: "Products",
    icon: "ni ni-bag-17 text-info",
    component: Products,
    layout: "/vendor",
  },
  // {
  //   path: "/offers",
  //   name: "Offers",
  //   icon: "ni ni-bag-17 text-info",
  //   component: Offers,
  //   layout: "/vendor",
  // },
  // {
  //   path: "/packages",
  //   name: "Packages",
  //   icon: "ni ni-bag-17 text-info",
  //   component: Packages,
  //   layout: "/vendor",
  // },
  // {
  //   path: "/discounts",
  //   name: "Discounts",
  //   icon: "ni ni-bag-17 text-info",
  //   component: Discounts,
  //   layout: "/vendor",
  // },
  {
    path: "/orders",
    name: "Orders",
    icon: "ni ni-collection text-info",
    component: Orders,
    layout: "/vendor",
  },
  // {
  //   path: "/customers",
  //   name: "Customers",
  //   icon: "ni ni-single-02 text-info",
  //   component: Customers,
  //   layout: "/vendor",
  // },
  // {
  //   path: "/subcat",
  //   name: "Categories",
  //   icon: "ni ni-ruler-pencil text-info",
  //   component: SubCategories,
  //   layout: "/vendor",
  // },
  {
    path: "/settings",
    name: "Settings",
    icon: "ni ni-settings-gear-65 text-info",
    component: Settings,
    layout: "/vendor",
  },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   icon: "ni ni-planet text-warning",
  //   component: Icons,
  //   layout: "/vendor",
  // },
];
export default routes;
