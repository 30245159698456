import React, { useEffect, useState } from "react";

import {
    Modal, 
    ModalHeader, 
    ModalBody, 
    ModalFooter,
    Button,
    Form, 
    FormGroup, 
    Label, 
    Input,
    Spinner
} from "reactstrap";
import Resizer from "react-image-file-resizer";
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { inputAction, inputActionReset, editCategory, deleteCategory, uploadImageItemOnly, inputActionFile } from 'redux/actions/subcatAction';

const Edit = (props) => {

    const dispatch = useDispatch();
    const subcat = useSelector(state => state.subcat);
    const [loading, setloading] = useState(false);

    return (
        <Modal isOpen={props.isOpen}>
            <ModalHeader>Edit Category</ModalHeader>
            <ModalBody>
                <Form>
                    <FormGroup>
                        <Label>Category</Label>
                        <Input 
                            type="select" 
                            name="category_key"
                            onChange={(e) => dispatch(inputAction(e.target))}
                            value={subcat.category_key}
                            >
                            <option value="">Select Category</option>
                            {props.category.data.map((item, k) => {
                                if(item._key !== "-MuA9Y_mR1pK1hjaThG9") {
                                    return <option key={k} value={item._key}>{item.data.name}</option>
                                }
                            })}
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label>Icon/Photo</Label>
                        <Input 
                            type="file" 
                            name="photo" 
                            onChange={(e) => _onCropChange(e)}
                            accept="image/*"
                            />
                        <br/>
                        <p style={{fontSize: 12}}>Accepts .png file only for transparent background</p>
                        {subcat.photo.length === 0 ? 
                            <img src={subcat.photo_view} style={{width: 200, height: 200, objectFit: 'cover'}}/> : 
                            <img src={URL.createObjectURL(subcat.photo)} style={{width: 200, height: 200, objectFit: 'cover'}}/>
                        }
                    </FormGroup>
                    <FormGroup>
                        <Label>Name</Label>
                        <Input 
                            type="text" 
                            name="name" 
                            onChange={(e) => dispatch(inputAction(e.target))}
                            value={subcat.name}
                            />
                    </FormGroup>
                    <FormGroup>
                        <Label>Name (Arabic)</Label>
                        <Input 
                            type="text" 
                            name="name_ar" 
                            onChange={(e) => dispatch(inputAction(e.target))} 
                            style={{textAlign: "right"}}
                            value={subcat.name_ar}/>
                    </FormGroup>
                </Form>
            </ModalBody>
            <ModalFooter>
                {loading === true ? <Spinner /> : 
                    <>
                        <Button color="primary" onClick={() => _submit()}>Save</Button>{' '}
                        <Button color="secondary" onClick={() => _onClose()}>Cancel</Button>{' '}
                        <Button color="danger" onClick={() => _onDelete()}>Delete</Button>
                    </>
                }
            </ModalFooter>
        </Modal>
    )

    function _submit() {
        if(subcat.name === '') {
            toast.error("Please enter category", {
                theme: "colored"
            });
            return false;
        }
        
        if(subcat.name_ar === '') {
            toast.error("Please enter category in arabic", {
                theme: "colored"
            });
            return false;
        }

        let params = {
            _key: subcat._key,
            category_key: subcat.category_key,
            vendor_key: 'admin',
            name: subcat.name,
            name_ar: subcat.name_ar
        }

        setloading(true);

        dispatch(editCategory(params)).then(res => {
            if(res === 'saved') {
                dispatch(inputActionReset());
                setloading(false);
                props.onClose();
            }
        });
    }

    function _onDelete() {
        if(window.confirm('Are you sure you want to delete?')) {
            dispatch(deleteCategory(subcat)).then(res => {
                if(res === 'saved') {
                    setloading(false);
                    dispatch(inputActionReset());
                    props.onClose();
                }
            });
        }
    }

    function _onCropChange(e) {
        window.removeEventListener('focus', handleFocusBack);
        Resizer.imageFileResizer(
            e.target.files[0],
            200, 200, "png", 70, 0,
            (uri) => {
                dispatch(inputActionFile(uri));
                setloading(true);
                dispatch(uploadImageItemOnly(subcat, uri)).then(res => {
                    if(res === 'saved') {
                        setloading(false);
                        toast.success("Icon/Photo successfully updated", {
                            theme: "colored"
                        });
                    }else {
                        setloading(false);
                        toast.error("An error occur please try again later", {
                            theme: "colored"
                        });
                    }
                });
            },
            "blob",
            200,
            200
        );
    }

    function handleFocusBack(){
        window.removeEventListener('focus', handleFocusBack);
    }

    function _onClose() {
        dispatch(inputActionReset());
        props.onClose();
    }
}

export default Edit;