const cols = (btn, _renderStatus) =>  [
    {
        name: 'Country',
        selector: row => row.data.country
    },
    {
        name: 'Company Name',
        selector: row => row.data.company_name,
    },
    {
        name: 'Shop Name',
        selector: row => row.data.shop_name,
    },
    {
        name: 'CR #',
        selector: row => row.data.cr_number,
    },
    {
        name: 'Username',
        selector: row => row.data.username,
    },
    {
        name: 'Password',
        selector: row => 'Default (123456)',
    },
    {
        name: 'Date Created',
        selector: row => row.data.created_date,
    },
    {
        name: '',
        selector: row => btn(row),
        right: true
    },
];

export default cols