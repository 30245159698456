import { 
    CATERING_SUBCAT_DATA, 
    CATERING_SUBCAT_INPUT, 
    CATERING_SUBCAT_RESET,
    CATERING_SUBCAT_EDIT
} from '../types';

const initialState = {
    loading: true,
    data: [],
    _key: '',
    type: '',
    name: '',
    name_ar: ''
}

const cateringReducer = (state = initialState, action) => {
    switch(action.type) {
        case CATERING_SUBCAT_DATA:
            return {...state, data: action.payload, loading: action.loading}
            break;
        case CATERING_SUBCAT_INPUT:
            return {...state, [action.payload.name]: action.payload.value}
            break;
        case CATERING_SUBCAT_RESET:
            return {
                ...state, 
                _key: '',
                type: '',
                name: '',
                name_ar: ''
            }
            break;
        case CATERING_SUBCAT_EDIT:
            return {
                ...state, 
                _key: action.payload._key,
                type: action.payload.data.type,
                type: action.payload.data.type,
                name: action.payload.data.name,
                name_ar: action.payload.data.name_ar,
            }
            break;
        default:
            return state;
            break;
    }
}

export default cateringReducer;