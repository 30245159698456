const cols = (btn) =>  [
    {
        name: 'Full Name',
        selector: row => row.data.fullname,
    },
    {
        name: 'Username',
        selector: row => row.data.username,
    },
    {
        name: 'Password',
        selector: row => 'Default (123456)',
    },
    // {
    //     name: 'Role',
    //     selector: row => row.data.role,
    // },
    // {
    //     name: 'Affiliate Code',
    //     selector: row => row.data.affiliate_code,
    // },
    {
        name: 'Date Created',
        selector: row => row.data.created_date,
    },
    {
        name: '',
        selector: row => btn(row),
        right: true
    },
];

export default cols