import React, { useEffect, useState } from "react";
import Header from "components/Headers/MerchantHeader";
import DataTable from 'react-data-table-component';
import {createFilter} from 'react-search-input';

import {
    Card,
    CardHeader,
    CardBody,
    Container,
    Input,
    Button
} from "reactstrap";

// import { useDispatch, useSelector } from 'react-redux';
// import { getAdmin, adminInputEdit } from 'redux/actions/merchantAdminAction';
// import { getBranch } from 'redux/actions/branchAction';

// import cols from './cols';
// import Add from './add';
// import Edit from './edit';

const Users = (props) => {

    // const dispatch = useDispatch();
    // const merchantAdmin = useSelector(state => state.merchantAdmin);
    // const branch = useSelector(state => state.branch);
    // const setup = useSelector(state => state.setup);

    // useEffect(() => {
    //     dispatch(getAdmin(setup._key));
    //     dispatch(getBranch(setup._key));
    // }, [setup]);

    // const [search, setSearch] = useState('');
    // const [add, setAdd] = useState(false);
    // const [edit, setEdit] = useState(false);
    
    // const KEYS_TO_FILTERS = ['data.category_name']
    // const filterData = merchantAdmin.data.filter(createFilter(search, KEYS_TO_FILTERS));
    
    return (
        <div>
            <Header />
            <Container className="mt--7" fluid>
                <Card>
                    <CardHeader>
                        <h3>Users</h3>
                    </CardHeader>
                    <CardBody>
                        {/* <Button color="primary" onClick={() => setAdd(true)}>Add Users</Button>
                        <DataTable
                            columns={cols(_renderBtn, _renderBranch)}
                            data={filterData}
                            progressPending={merchantAdmin.loading}
                            subHeader
                            subHeaderComponent={
                                <Input
                                    placeholder="Search"
                                    onChange={(e) => setSearch(e.target.value)}
                                    style={{width: 250}}
                                    />
                            }
                            pagination
                            /> */}
                    </CardBody>
                </Card>
            </Container>
            {/* <Add
                isOpen={add}
                onClose={() => setAdd(false)}
                branch={branch}
                />
            <Edit
                isOpen={edit}
                onClose={() => setEdit(false)}
                branch={branch}
                /> */}
        </div>
    )

    // function _renderBtn(row) {
    //     return <Button size="sm" color="warning" onClick={() => _edit(row)}>Edit</Button>
    // }

    // function _renderBranch(row) {
    //     var branchName = "";
    //     if(row.data.branch_key === "---") {
    //         branchName = "---"
    //     }else {
    //         branch.data.map((i) => {
    //             if(i._key === row.data.branch_key) {
    //                 branchName = i.data.branch_name;
    //             }
    //         });
    //     }
    //     return branchName;
    // }

    // function _edit(row) {
    //    dispatch(adminInputEdit(row));
    //    setEdit(true);
    // }
}

export default Users;