import { MOOD_DATA } from '../types';

const initialState = {
    loading: false,
    data: []
}

const moodReducer = (state = initialState, action) => {
    switch(action.type) {
        case MOOD_DATA:
            return {
                ...state,
                data: action.payload,
                loading: action.loading
            }
            break;
        default:
            return state;
            break;
    }
}   

export default moodReducer;