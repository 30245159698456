import React, { useEffect, useState } from "react";

import {
    Modal, 
    ModalHeader, 
    ModalBody, 
    ModalFooter,
    Button,
    Form, 
    FormGroup, 
    Label, 
    Input,
    Spinner,
    Row,
    Col
} from "reactstrap";
import { toast } from 'react-toastify';

const NewForms = (props) => {

    const [type, setType]                    = useState('');
    const [label_en, setLabelEn]             = useState('');
    const [name_en, setNameEn]               = useState('');
    const [label_ar, setLabelAr]             = useState('');
    const [name_ar, setNameAr]               = useState('');
    const [isMultiple, setIsMultiple]        = useState(false);

    return (
        <Modal isOpen={props.isOpen}>
            <ModalHeader>Add Form List</ModalHeader>
            <ModalBody>
                <Form>
                    <FormGroup>
                        <Label>Form Type</Label>
                        <Input 
                            type="select" 
                            name="type"
                            onChange={(e) => setType(e.target.value)}
                            value={type}
                            bsSize="sm"
                            >
                            <option value="">Select</option>
                            <option value="text">Text</option>
                            <option value="textarea">Long Text</option>
                            <option value="file">File</option>
                            <option value="number">Number</option>
                            <option value="selection">Selection</option>
                            {/* <option value="radio">Radio Button</option>
                            <option value="checkbox">Checkbox</option> */}
                            {/* <option value="date">Date Range</option> */}
                        </Input>
                    </FormGroup>
                    <Row>
                        <Col md="6" xs="12">
                            <FormGroup>
                                <Label>Label</Label>
                                <Input 
                                    type="text" 
                                    name="label_en"
                                    onChange={(e) => setLabelEn(e.target.value)}
                                    value={label_en}
                                    bsSize="sm"
                                    />
                            </FormGroup>
                        </Col>
                        <Col md="6" xs="12">
                            <FormGroup>
                                <Label>Label Arabic</Label>
                                <Input 
                                    type="text" 
                                    name="label_ar"
                                    onChange={(e) => setLabelAr(e.target.value)}
                                    value={label_ar}
                                    bsSize="sm"
                                    style={{textAlign: 'right'}}
                                    />
                            </FormGroup>
                        </Col>
                    </Row>
                    <FormGroup>
                        <Label>Identifier Name</Label>
                        <Input 
                            type="text" 
                            name="name_en"
                            onChange={(e) => _identifierName(e)}
                            value={name_en.toLocaleLowerCase().replace(/\s/g, '_')}
                            bsSize="sm"
                            />
                        <i style={{fontSize: 12}}>This will be the form identifier</i>
                    </FormGroup>
                    {_renderIsMultiple()}
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={() => _submit()}>Save</Button>{' '}
                <Button color="secondary" onClick={() => _onClose()}>Cancel</Button>
            </ModalFooter>
        </Modal>
    )

    function _renderIsMultiple() {
        if(type === "file") {
            return (
                <FormGroup check>
                    <Input 
                        type="checkbox" 
                        name="isMultiple"
                        onChange={(e) => setIsMultiple(isMultiple == false ? true : false)}
                        value={isMultiple}
                        />
                    {' '}
                    <Label check>
                        Is Multiple Photo Upload?
                    </Label>
                </FormGroup>
            )
        }
    }

    function _identifierName(e) {
        setNameEn(e.target.value)
        setNameAr(e.target.value.concat('_ar'));
    }

    function _submit() {
        if(type === "") {
            toast.error("Please select form type", {
                theme: "colored"
            });
            return false
        }

        if(label_en === "") {
            toast.error("Please enter label", {
                theme: "colored"
            });
            return false
        }

        if(label_ar === "") {
            toast.error("Please enter label in arabic", {
                theme: "colored"
            });
            return false
        }

        if(name_en === "") {
            toast.error("Please enter form identifier", {
                theme: "colored"
            });
            return false
        }

        var params = {
            type: type,
            label_en: label_en,
            name_en: name_en,
            label_ar: label_ar,
            name_ar: name_ar,
            value_en: '',
            value_ar: '',
            options: '',
            isMultiple: isMultiple
        }

        props.addForms(params);
        setType('');
        setLabelEn('');
        setNameEn('');
        setLabelAr('');
        setNameAr('');
        _onClose();
    }

    function _onClose() {
        props.onClose();
    }
}

export default NewForms;