const cols = (btn, _renderCategory) =>  [
    {
        name: 'Icon/Photo',
        selector: row => <img src={row.data.photo} style={{width: 50, height: 50, objectFit: 'cover'}}/>,
    },
    {
        name: 'Category',
        selector: row => _renderCategory(row.data.category_key),
    },
    {
        name: 'Name',
        selector: row => row.data.name,
    },
    {
        name: '(Arabic)',
        selector: row => row.data.name_ar,
    },
    {
        name: 'Date Created',
        selector: row => row.data.created_date,
    },
    {
        name: '',
        selector: row => btn(row),
        right: true
    },
];

export default cols