import Dashboard from "views/admin/dashboard";
import Users from "views/admin/users";
import Vendors from "views/admin/vendors";
import Categories from "views/admin/categories";
import SubCategories from "views/admin/subcategories";
import Products from "views/admin/products";
import Packages from "views/admin/packages";
import Request from "views/admin/request";
import Orders from "views/admin/orders";
import Mood from "views/admin/mood";
import Notification from "views/admin/notification";
import Settings from "views/admin/settings";
import Banners from "views/admin/banners";
import Icons from "views/examples/Icons";
import CateringCategories from "views/admin/catering";

var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-info",
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/orders",
    name: "Orders",
    icon: "ni ni-collection text-info",
    component: Orders,
    layout: "/admin",
  },
  {
    path: "/request",
    name: "Request",
    icon: "ni ni-single-copy-04 text-info",
    component: Request,
    layout: "/admin",
  },
  {
    path: "/mood",
    name: "Mood Board",
    icon: "ni ni-satisfied text-info",
    component: Mood,
    layout: "/admin",
  },
  {
    path: "/users",
    name: "Users",
    icon: "ni ni-single-02 text-info",
    component: Users,
    layout: "/admin",
  },
  {
    path: "/vendors",
    name: "Vendors",
    icon: "ni ni-shop text-info",
    component: Vendors,
    layout: "/admin",
  },
  {
    path: "/categories",
    name: "Categories",
    icon: "ni ni-ungroup text-info",
    component: Categories,
    layout: "/admin",
  },
  {
    path: "/subcat",
    name: "Sub Categories",
    icon: "ni ni-ruler-pencil text-info",
    component: SubCategories,
    layout: "/admin",
  },
  {
    path: "/catering",
    name: "Catering Categories",
    icon: "ni ni-ruler-pencil text-info",
    component: CateringCategories,
    layout: "/admin",
  },
  {
    path: "/products",
    name: "Products",
    icon: "ni ni-bag-17 text-info",
    component: Products,
    layout: "/admin",
  },
  {
    path: "/package",
    name: "Package",
    icon: "ni ni-single-copy-04 text-info",
    component: Packages,
    layout: "/admin",
  },
  {
    path: "/banners",
    name: "Banners",
    icon: "ni ni-ui-04 text-info",
    component: Banners,
    layout: "/admin",
  },
  {
    path: "/notification",
    name: "Notification",
    icon: "ni ni-bell-55 text-info",
    component: Notification,
    layout: "/admin",
  },
  {
    path: "/settings",
    name: "Settings",
    icon: "ni ni-settings-gear-65 text-info",
    component: Settings,
    layout: "/admin",
  },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   icon: "ni ni-planet text-warning",
  //   component: Icons,
  //   layout: "/admin",
  // },
];

export default routes;