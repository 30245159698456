import React, { useEffect } from "react";

import PartyProps from "./add_category_form/party_props";
import VenueRental from "./add_category_form/venue_rental";
import Balloons from "./add_category_form/balloons";
import Cakes from "./add_category_form/cakes";
import Catering from "./add_category_form/catering";
import Furniture from "./add_category_form/furniture";
import Inflatables from "./add_category_form/inflatables";
import Backdrops from "./add_category_form/backdrops";
import Entertainers from "./add_category_form/entertainers";
import Rentals from "./add_category_form/rentals";
import Hospitality from "./add_category_form/hospitality";
import Photography from "./add_category_form/photography";
import MunchSuch from "./add_category_form/munch_such";

const Add = (props) => {

    useEffect(() => {

    }, [props.category_name, props.category_key]);

    return _renderForm();

    function _renderForm() {
        switch(props.category_name) {
            case 'Party Props':
                return (
                    <PartyProps 
                        isOpen={props.isOpen} 
                        onClose={() => props.onClose()}
                        category_key={props.category_key}
                        category_name={props.category_name}
                        />
                )
                break;
            case 'Venue Rentals':
                return (
                    <VenueRental 
                        isOpen={props.isOpen} 
                        onClose={() => props.onClose()}
                        category_key={props.category_key}
                        category_name={props.category_name}
                        />
                )
                break;
            case 'Balloons':
                return (
                    <Balloons 
                        isOpen={props.isOpen} 
                        onClose={() => props.onClose()}
                        category_key={props.category_key}
                        category_name={props.category_name}
                        />
                );
                break;
            case 'Cakes':
                return (
                    <Cakes 
                        isOpen={props.isOpen} 
                        onClose={() => props.onClose()}
                        category_key={props.category_key}
                        category_name={props.category_name}
                        />
                );
                break;
            case 'Catering':
                return (
                    <Catering 
                        isOpen={props.isOpen} 
                        onClose={() => props.onClose()}
                        category_key={props.category_key}
                        category_name={props.category_name}
                        />
                );
                break;
            case 'Furniture':
                return (
                    <Furniture 
                        isOpen={props.isOpen} 
                        onClose={() => props.onClose()}
                        category_key={props.category_key}
                        category_name={props.category_name}
                        />
                );
                break;
            case 'Inflatables':
                return (
                    <Inflatables 
                        isOpen={props.isOpen} 
                        onClose={() => props.onClose()}
                        category_key={props.category_key}
                        category_name={props.category_name}
                        />
                );
                break;
            case 'Backdrops':
                return (
                    <Backdrops 
                        isOpen={props.isOpen} 
                        onClose={() => props.onClose()}
                        category_key={props.category_key}
                        category_name={props.category_name}
                        />
                );
                break;
            case 'Entertainers':
                return (
                    <Entertainers   
                        isOpen={props.isOpen} 
                        onClose={() => props.onClose()}
                        category_key={props.category_key}
                        category_name={props.category_name}
                        />
                )
                break;
            case 'Rentals':
                return (
                    <Rentals 
                        isOpen={props.isOpen} 
                        onClose={() => props.onClose()}
                        category_key={props.category_key}
                        category_name={props.category_name}
                        />
                );
                break;
            case 'Photography':
                return (
                    <Photography 
                        isOpen={props.isOpen} 
                        onClose={() => props.onClose()}
                        category_key={props.category_key}
                        category_name={props.category_name}
                        />
                );
                break;
            case 'Hospitality':
                return (
                    <Hospitality 
                        isOpen={props.isOpen} 
                        onClose={() => props.onClose()}
                        category_key={props.category_key}
                        category_name={props.category_name}
                        />
                );
                break;
            case 'Munch & Such':
                return (
                    <MunchSuch 
                        isOpen={props.isOpen} 
                        onClose={() => props.onClose()}
                        category_key={props.category_key}
                        category_name={props.category_name}
                        />
                );
                break;
            default:
                return null;
                break;
        }
    }
}

export default Add;