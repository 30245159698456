import React, { useEffect, useState } from "react";
import Header from "components/Headers/AdminHeader";
import DataTable from 'react-data-table-component';
import {createFilter} from 'react-search-input';

import {
    Card,
    CardHeader,
    CardBody,
    Container,
    Input,
    Button,
    ButtonGroup,
    Badge
} from "reactstrap";

import { useDispatch, useSelector } from 'react-redux';
import { getVendors, inputEdit } from 'redux/actions/vendorAction';

import cols from './cols';
import Add from './add';
import Edit from './edit';

const Vendors = (props) => {

    const dispatch = useDispatch();
    const vendor = useSelector(state => state.vendor);

    useEffect(() => {
        dispatch(getVendors());
    }, []);

    const [search, setSearch] = useState('');
    const [add, setAdd] = useState(false);
    const [edit, setEdit] = useState(false);
    
    const KEYS_TO_FILTERS = ['data.company_name', 'data.shop_name', 'data.cr_number']
    const filterData = vendor.data.filter(createFilter(search, KEYS_TO_FILTERS));
    
    return (
        <div>
            <Header />
            <Container className="mt--7" fluid>
                <Card>
                    <CardHeader>
                        <h3>Vendors</h3>
                    </CardHeader>
                    <CardBody>
                        <Button color="primary" onClick={() => setAdd(true)}>Add Vendors</Button>
                        <DataTable
                            columns={cols(_renderBtn, _renderStatus)}
                            data={filterData}
                            progressPending={vendor.loading}
                            subHeader
                            subHeaderComponent={
                                <Input
                                    placeholder="Search"
                                    onChange={(e) => setSearch(e.target.value)}
                                    style={{width: 250}}
                                    />
                            }
                            pagination
                            />
                    </CardBody>
                </Card>
            </Container>
            <Add
                isOpen={add}
                onClose={() => setAdd(false)}
                title="Add Vendor"
                />
            {vendor._key !== "" ? 
                <>
                    <Edit
                        isOpen={edit}
                        onClose={() => setEdit(false)}
                        title="Edit Vendor"
                        />
                </>
            : null}
        </div>
    )

    function _renderBtn(row) {
        return (
            <ButtonGroup>
                <Button size="sm" color="warning" onClick={() => _edit(row)}>Edit</Button>
            </ButtonGroup>
        );
    }

    function _renderStatus(row) {
        // switch(row.data.status) {
        //     case 'hide':
        //         return <Badge color="dark">Hidden</Badge>
        //         break;
        //     case 'show':
        //         return <Badge color="success">Visible</Badge>
        //         break;
        //     default:
        //         return <Badge color="warning">For Review</Badge>
        //         break;
        // } 
    }

    function _edit(row) {
       dispatch(inputEdit(row));
       setEdit(true);
    }
}

export default Vendors;