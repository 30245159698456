import { offersRef, offersStorage } from '../../database/firebase';
import { OFFERS_DATA, OFFERS_PHOTO } from '../types';
import moment from 'moment';

const getOffersItemsAdmin = () => async dispatch => {
    dispatch({type: OFFERS_DATA, payload: [], loading: true});
    offersRef.on('value', snap => {
        if(snap.val() === null) {
            dispatch({type: OFFERS_DATA, payload: [], loading: false});
        }else {
            let item = [];
            snap.forEach(child => {
                item.push({
                    _key: child.key,
                    data: child.val()
                })
            });
            dispatch({type: OFFERS_DATA, payload: item.reverse(), loading: false});
        }
    });
}

const getOffersItems = (vendor_key) => async dispatch => {
    dispatch({type: OFFERS_DATA, payload: [], loading: true});
    offersRef.orderByChild("vendor_key").equalTo(vendor_key).on('value', snap => {
        if(snap.val() === null) {
            dispatch({type: OFFERS_DATA, payload: [], loading: false});
        }else {
            let item = [];
            snap.forEach(child => {
                item.push({
                    _key: child.key,
                    data: child.val()
                })
            });
            dispatch({type: OFFERS_DATA, payload: item.reverse(), loading: false});
        }
    });
}

const getOffersByKey = (key) => async dispatch => {
    dispatch({type: OFFERS_PHOTO, payload: [], loading: true});
    offersRef.child(key).on('value', snap => {
        if(snap.val() !== null) {
            var photos = [];
            Object.keys(snap.val().photos).map(k => {
                photos.push({
                    _key: k,
                    data: snap.val().photos[k]
                });
            });
            dispatch({type: OFFERS_PHOTO, payload: photos, loading: false});
        }
    });
}

const addOffers = (state) => async dispatch => {
    return new Promise((resolve, reject) => {
        var id = Math.floor(10000 + Math.random() * 90000); 
        var ref = offersRef.push({
            id: id.toString(),
            vendor_key: state.vendor_key,
            category_key: state.category_key,
            category_name: state.category_name,
            target: state.target,
            title_en: state.title_en,
            title_ar: state.title_ar,
            desc_en: state.desc_en,
            desc_ar: state.desc_ar,
            price: state.price,
            currency: state.currency,
            rating: "0",
            status: 'pending',
            reason: '',
            commission: '',
            dateRange: state.dateRange,
            created_date: moment().format('YYYY-MM-DD hh:mm:ss')
        });

        if(state.options.length !== 0) {
            offersRef.child(ref.key).child('options').set(state.options);
        }else {
            offersRef.child(ref.key).update({options: ''});
        }

        for(var i=0; i<state.photos.length; i++) {
            var photoID = Math.floor(10000 + Math.random() * 90000);
            dispatch(uploadImage(ref.key, id, photoID, state.photos[i], resolve, reject));
        }
    });
}

const uploadImage = (key, id, photoID, photo, resolve, reject) => async dispatch => {
    var storageRef = offersStorage.child(id.toString()).child(photoID.toString()+'-offers.png').put(photo);
    storageRef.on('state_changed', function(snapshot) {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        if(progress === 100) {
            resolve('saved');
        }
    }, function(error) {
        resolve(error);
    }, function() {
        storageRef.snapshot.ref.getDownloadURL().then(function(downloadURL) {
            offersRef.child(key).child('photos').push({
                id: photoID.toString(),
                photo: downloadURL
            });
        });
    });
}

const editOffers = (state) => async dispatch => {
    return new Promise((resolve, reject) => {
        offersRef.child(state._key).update({
            category_key: state.category_key,
            category_name: state.category_name,
            target: state.target,
            title_en: state.title_en,
            title_ar: state.title_ar,
            desc_en: state.desc_en,
            desc_ar: state.desc_ar,
            price: state.price,
            dateRange: state.dateRange,
            options: state.options.length !== 0 ? state.options : ''
        });
        resolve('saved');
    });
}

const uploadImageItemOnly = (key, id, photo) => async dispatch => {
    return new Promise((resolve, reject) => {
        var photoID = Math.floor(10000 + Math.random() * 90000);
        var storageRef = offersStorage.child(id).child(photoID.toString()+'-offers.png').put(photo);
        storageRef.on('state_changed', function(snapshot) {
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            if(progress === 100) {
                resolve('saved');
            }
        }, function(error) {
            resolve(error);
        }, function() {
            storageRef.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                offersRef.child(key).child('photos').push({
                    id: photoID.toString(),
                    photo: downloadURL
                });
            });
        });
    });
}

const deleteImage = (key, id, photoKey, photoID) => async dispatch => {
    return new Promise((resolve, reject) => {
        offersStorage.child(id.toString()).child(photoID.toString()+'-offers.png').delete();
        offersRef.child(key).child('photos').child(photoKey).remove();
        resolve('saved');
    });
}

const deleteOffers = (_key, id, photos) => async dispatch => {
    return new Promise((resolve, reject) => {
        if(photos.length !== 0) {
            for(var i=0; i<photos.length; i++) {
                offersStorage.child(id).child(photos[i].data.id+'-offers.png').delete();
            }
        }
        offersRef.child(_key).remove();
        resolve('saved');
    });
}

const changeStatus = (state) => async dispatch => {
    return new Promise((resolve, reject) => {
        offersRef.child(state._key).update({
            status: state.status,
            reason: state.reason,
            commission: state.commission
        });
        resolve('saved');
    });
}

export {
    getOffersItemsAdmin,
    getOffersItems,
    addOffers,
    editOffers,
    uploadImageItemOnly,
    deleteImage,
    deleteOffers,
    getOffersByKey,
    changeStatus
}