import React, { useEffect, useState } from "react";

import {
    Modal, 
    ModalHeader, 
    ModalBody,
    ModalFooter,
    Form,
    FormGroup, 
    Label, 
    Input,
    Row,
    Col,
    Badge,
    Button,
    Carousel,
    CarouselItem,
    CarouselControl,
    InputGroup,
    InputGroupAddon,
    Spinner,
    ListGroup,
    ListGroupItem
} from "reactstrap";
import Resizer from "react-image-file-resizer";
import { toast } from 'react-toastify';
import { TwitterPicker } from 'react-color';
import { FaWeight } from 'react-icons/fa';
import { MdSpeed, MdOutlineInvertColors } from 'react-icons/md';
import { BiTimeFive } from 'react-icons/bi';
import { FiSettings } from 'react-icons/fi';
import { BsSpeedometer2, BsFillCloudHaze2Fill, BsCircle, BsCircleFill, BsSpeedometer } from 'react-icons/bs';
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import { IoIosArrowDown } from 'react-icons/io';
import { MdOutlinePower } from 'react-icons/md';
import { FaPencilRuler } from 'react-icons/fa';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import { useDispatch } from "react-redux";
import { getSubCatByCategory } from 'redux/actions/subcatAction';
import { getProductByKey, editProduct, uploadImageItemOnly, deleteImage, deleteProduct, getProductEntertainer, uploadSpecialtyImageOnly, deleteImageSpecialty } from "redux/actions/productAction";

const Entertainers = (props) => {

    let data = props.data;

    const dispatch = useDispatch();

    const categoryData = window.localStorage.getItem('@vendor_category');
    const categoryParse = JSON.parse(categoryData);

    const [subcat, setSubCat] = useState([]);
    const [sub_category_key, setSubCatKey] = useState(data.sub_category_key);
    const [sub_category_name, setSubCatName] = useState(data.sub_category_name);

    const [photo, setPhotos] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const [product_name, setProductName] = useState(data.product_name);
    const [address, setAddress] = useState(data.data.address);
    const [link, setLink] = useState(data.data.link);
    const [biography, setBiography] = useState(data.data.biography);
    const [specialty, setSpecialty] = useState([]);

    const [genre_txt, setGenreTxt] = useState('');
    const [genre, setGenre] = useState(data.data.genre);

    const [event_types_txt, setEventTypesTxt] = useState('');
    const [event_types, setEventTypes] = useState(data.data.event_types);

    const [service_details, setServiceDetails] = useState(data.data.service_details);
    const [minHours, setMinHours] = useState(data.minHours);

    const [requirements, setRequirements] = useState(
        !data.data.requirements ? [
            {
                title: 'Set Up Time',
                isIncluded: 'No',
                value: ''
            },
            {
                title: 'Electricity',
                isIncluded: 'No',
                value: ''
            },
            {
                title: 'Session Duration',
                isIncluded: 'No',
                value: ''
            },
            {
                title: 'Dimension',
                isIncluded: 'No',
                value: ''
            },
            {
                title: 'Pick Up Time',
                isIncluded: 'No',
                value: ''
            }
        ] : data.data.requirements
    );

    const [price, setPrice] = useState(data.price);
    const [btnDisabled, setDisabled] = useState(false);

    useEffect(() => {
        dispatch(getSubCatByCategory(data.category_key)).then(res => {
            if(res.length === 0) {
                setSubCatKey('---');
                setSubCatName('---');
            }else {
                setSubCat(res);
            }
        });
        _callImages()
        _callIcons()
    }, []);

    function _callImages() {
        dispatch(getProductByKey(props._key)).then(res => {
            setPhotos(res);
        });
    }

    function _callIcons() {
        dispatch(getProductEntertainer(props._key)).then(res => {
            setSpecialty(res);
        });
    }

    function _renderIcons(title) {
        switch(title) {
            case 'Set Up Time':
                return <BiTimeFive size={20}/>
            case 'Electricity':
                return <MdOutlinePower size={20}/>
            case 'Session Duration':
                return <BsSpeedometer size={20}/>
            case 'Dimension':
                return <FaPencilRuler size={20}/>
            case 'Pick Up Time':
                return <BiTimeFive size={20}/>
        }
    }

    return (
        <Modal isOpen={props.isOpen} toggle={() => _onClose()} size="lg">
            <ModalHeader toggle={() => _onClose()}>Edit Product</ModalHeader>
            <ModalBody>
                <Form>
                    {subcat.length !== 0 ? 
                        <FormGroup>
                            <Label>Sub Category</Label>
                            <Input 
                                type="select" 
                                name="sub_category_key"
                                onChange={(e) => _selectSubCategory(e)}
                                >
                                <option value="">Select Category</option>
                                {subcat.map((item, k) => {
                                    return <option key={k} value={item._key}>{item.data.name}</option>
                                })}
                            </Input>
                        </FormGroup>
                    : null}

                    <div>
                        {/* Photo */}
                        <div style={{backgroundColor: '#ecf0f1', width: '100%', height: 300, borderRadius: 10, marginBottom: 10}}>
                            {_renderPhoto()}
                        </div>
                        <FormGroup>
                            <Label>Upload Photo</Label>
                            <Input type="file" name="file" onChange={(e) => _uploadPhoto(e)} accept="image/*"/>
                        </FormGroup>

                        <Row>
                            <Col>
                                <FormGroup>
                                    <Input type="text" name="product_name" value={product_name} placeholder="Product Name" onChange={(e) => setProductName(e.target.value)}/>
                                </FormGroup>
                                <FormGroup>
                                    <Input 
                                        type="text"
                                        name="address"
                                        placeholder="Country, City"
                                        value={address}
                                        onChange={(e) => setAddress(e.target.value)}
                                        />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <p style={{textAlign: 'right'}}>
                                        <BsFillCloudHaze2Fill size={30}/>
                                    </p>
                                    <Input type="text" name="link" value={link} placeholder="Sound Cloud Link" onChange={(e) => setLink(e.target.value)}/>
                                </FormGroup>
                            </Col>
                        </Row>

                        <FormGroup>
                            <Label>Biography</Label>
                            <Input 
                                type="textarea"
                                name="biography"
                                value={biography}
                                onChange={(e) => setBiography(e.target.value)}
                                />
                        </FormGroup>

                        <h3>Specialty</h3>
                        {_renderSpecialty()}

                        <hr />

                        <h3>Genre</h3>
                        <FormGroup>
                            <Input 
                                type="text"
                                name="genre"
                                value={genre_txt}
                                onChange={(e) => setGenreTxt(e.target.value)}
                                placeholder="Enter Name"
                                />
                        </FormGroup>
                        {genre_txt !== '' ? 
                            <Button size="sm" color="primary" onClick={() => _submitGenre('add', '')} style={{marginBottom: 20}}>Add Genre</Button>
                        : null}
                        {genre.length !== 0 ? 
                            <ListGroup flush>
                                {genre.map((item, k) => {
                                    return (
                                        <ListGroupItem key={k}>
                                            <Row>
                                                <Col md="9" xs="8">{item}</Col>
                                                <Col md="3" xs="8" style={{textAlign: 'right'}}>
                                                    <Button size="sm" color="danger" onClick={() => _submitGenre('remove', k)}>Remove</Button>
                                                </Col>
                                            </Row>
                                        </ListGroupItem>
                                    )
                                })}
                            </ListGroup>
                        : null}

                        <hr />

                        <h3>Event Types</h3>
                        <FormGroup>
                            <Input 
                                type="text"
                                name="eventTypes"
                                value={event_types_txt}
                                onChange={(e) => setEventTypesTxt(e.target.value)}
                                placeholder="Enter Name"
                                />
                        </FormGroup>
                        {event_types_txt !== '' ? 
                            <Button size="sm" color="primary" onClick={() => _submitEventTypes('add', '')} style={{marginBottom: 20}}>Add Event Types</Button>
                        : null}
                        {event_types.length !== 0 ? 
                            <ListGroup flush>
                                {event_types.map((item, k) => {
                                    return (
                                        <ListGroupItem key={k}>
                                            <Row>
                                                <Col md="9" xs="8">{item}</Col>
                                                <Col md="3" xs="8" style={{textAlign: 'right'}}>
                                                    <Button size="sm" color="danger" onClick={() => _submitEventTypes('remove', k)}>Remove</Button>
                                                </Col>
                                            </Row>
                                        </ListGroupItem>
                                    )
                                })}
                            </ListGroup>
                        : null}

                        <hr />

                        {requirements.map((item, k) => {
                            return(
                                <Row key={k} style={{marginBottom: 10}}>
                                    <Col md={1}>
                                        {item.isIncluded === "Yes" ? 
                                            <BsCircleFill size={18} onClick={() => _onChangeRequirements(item, k, 'isIncluded', 'No')}/> : 
                                            <BsCircle size={18} onClick={() => _onChangeRequirements(item, k, 'isIncluded', 'Yes')}/>
                                        }
                                    </Col>
                                    <Col md={3}>
                                    {_renderIcons(item.title)} {item.title}
                                    </Col> 
                                    <Col>
                                        <Input 
                                            type="text"
                                            name="value"
                                            size="sm"
                                            value={item.value}
                                            onChange={(e) => _onChangeRequirements(item, k, e.target.name, e.target.value)}
                                            />
                                    </Col>
                                </Row>
                            );
                        })}

                        <hr />
                        
                        <FormGroup>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">SAR</InputGroupAddon>
                                <Input type="number" placeholder="Price" name="price" value={price} onChange={(e) => setPrice(e.target.value)}/>
                            </InputGroup>
                        </FormGroup>

                        <FormGroup>
                            <Label>Minimum of Hours</Label>
                            <Input type="number" name="minHours" value={minHours} onChange={(e) => setMinHours(e.target.value)}/>
                        </FormGroup>

                        <Accordion>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        <Row>
                                            <Col md="8" xs="4">
                                                Service Details
                                            </Col>
                                            <Col md="4" xs="4" style={{textAlign: "right"}}>
                                                <IoIosArrowDown size={30}/>
                                            </Col>
                                        </Row>
                                        <div className="bottomBorder"></div>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <Input placeholder="Type here..." type="textarea" name="service_details" value={service_details} onChange={(e) => setServiceDetails(e.target.value)}/>
                                    <br/>
                                </AccordionItemPanel>
                            </AccordionItem>
                        </Accordion>
                    </div>
                </Form>
            </ModalBody>
            <ModalFooter>
                {
                    btnDisabled === true ? <Spinner /> : 
                    <>
                        <Button color="primary" onClick={() => _submit()}>Save this Product</Button>{" "}
                        <Button color="danger" onClick={() => _delete()}>Delete Product</Button>
                    </>
                }
            </ModalFooter>
        </Modal>
    )

    function _onChangeRequirements(item, k, name, val) {
        var req = [...requirements];
        req[k][name] = val;
        setRequirements(req);
    }

    function _selectSubCategory(e) {
        let index = e.nativeEvent.target.selectedIndex;
        let label = e.nativeEvent.target[index].text;
        setSubCatKey(e.target.value);
        setSubCatName(label);
    }

    function _submit() {
        if(sub_category_key === '') {
            toast.error("Please select category", {
                theme: "colored"
            });
            return false;
        }

        if(product_name === '') {
            toast.error("Please enter the product name", {
                theme: "colored"
            });
            return false;
        }

        if(price === '') {
            toast.error("Please enter the price", {
                theme: "colored"
            });
            return false;
        }

        if(minHours === '') {
            toast.error("Please enter the minimum hours", {
                theme: "colored"
            });
            return false;
        }

        if(address === '') {
            toast.error("Please enter the address", {
                theme: "colored"
            });
            return false;
        }

        if(link === '') {
            toast.error("Please enter the sound cloud link", {
                theme: "colored"
            });
            return false;
        }

        if(biography === '') {
            toast.error("Please enter the biography", {
                theme: "colored"
            });
            return false;
        }

        if(genre.length === 0) {
            toast.error("Please add atleast 1 genre", {
                theme: "colored"
            });
            return false;
        }

        if(event_types.length === 0) {
            toast.error("Please add atleast 1 event types", {
                theme: "colored"
            });
            return false;
        }

        // if(setup_time === '') {
        //     toast.error("Please enter setup time", {
        //         theme: "colored"
        //     });
        //     return false;
        // }

        // if(requirements === '') {
        //     toast.error("Please enter requirements", {
        //         theme: "colored"
        //     });
        //     return false;
        // }

        if(service_details === '') {
            toast.error("Please enter service details", {
                theme: "colored"
            });
            return false;
        }

        var params = {
            _key: props._key,
            sub_category_key: sub_category_key,
            sub_category_name: sub_category_name,
            target: 'services',
            //photo: photo,
            product_name: product_name,
            description: '---',
            price: price,
            minHours: minHours,
            data: {
                address: address,
                link: link,
                biography: biography,
                //specialty: specialty,
                genre: genre,
                event_types: event_types,
                requirements: requirements,
                service_details: service_details
            }
        }

        setDisabled(true);
        dispatch(editProduct(params)).then(res => {
            if(res === 'saved') {
                setDisabled(false);
                toast.success("New product successfully added and it will review by Dibrah Team", {
                    theme: "colored"
                });
                _onClose();
            }else {
                setDisabled(false);
                toast.error("An error occur please trye again later", {
                    theme: "colored"
                });
                return false;
            }
        });
    }

    function _delete() {
        if(window.confirm('Are you sure you want to delete?')) {
            dispatch(deleteProduct(props._key, props.data.id, photo)).then(res => {
                if(res === 'saved') {
                    _onClose();
                }
            });
        }
    }

    function _renderSpecialty() {
        return (
            <>
                <FormGroup>
                    <Input 
                        type="file"
                        onChange={(e) => _submitFiles(e)}
                        accept="image/*"
                        />
                </FormGroup>
                {specialty.length !== 0 ? 
                    <Row>
                        {specialty.map((item, k) => {
                            return (
                                <Col md="3" xs="12" key={k}>
                                    <img src={item.data.photo} style={{width: '100%', height: 150, objectFit: 'cover', marginBottom: 10}}/>
                                    <Button block size="sm" color="danger" onClick={() => _deleteSpecialty(props._key, props.data.id, item._key, item.data.id)}>Remove</Button>
                                </Col>
                            )
                        })}
                    </Row>
                : null}
            </>
        )
    }

    function _renderPhoto() {
        if(photo.length !== 0) {
            return (
                <Carousel
                    activeIndex={activeIndex}
                    next={() => next()}
                    previous={() => previous()}
                    >
                    {photo.map((item, k) => {
                        return(
                            <CarouselItem key={k}>
                                <div>
                                    <img src={item.data.photo} style={{width: '100%', height: 300, objectFit: 'cover'}}/>
                                    {photo.length > 1 ?
                                        <Button
                                            color="danger"
                                            style={{position: 'relative', marginTop: -100, left: 10, zIndex: 100}}
                                            onClick={() => _deleteImage(props._key, props.data.id, item._key, item.data.id)}
                                            >
                                            Delete
                                        </Button>
                                    : null}
                                </div>
                            </CarouselItem>
                        )
                    })}
                    <CarouselControl direction="prev" directionText="Previous" onClickHandler={() => previous()} />
                    <CarouselControl direction="next" directionText="Next" onClickHandler={() => next()} />
                </Carousel>
            )
        }
    }

    function _uploadPhoto(e) {
        window.removeEventListener('focus', handleFocusBack);
        if(photo.length > 10) {
            toast.error("Photos cannot be more than 10", {
                theme: "colored"
            });
            return false;
        }

        Resizer.imageFileResizer(
            e.target.files[0], 400, 400, "png", 70, 0,
            (uri) => { 
                dispatch(uploadImageItemOnly(props._key, props.data.id, uri)).then(res => {
                    if(res === "saved") {
                        setDisabled(false);
                        toast.success("New photos successfully added", {
                            theme: "colored"
                        });
                        _callImages()
                    }
                })
            }, "blob", 300, 300
        );
    }

    function _deleteImage(key, id, photoKey, photoId) {
        dispatch(deleteImage(key, id, photoKey, photoId)).then(res => {
            if(res == 'saved') {
                toast.error("Photos has been deleted", {
                    theme: "colored"
                });
                _callImages()
            }
        });
    }

    function _submitFiles(e) {
        window.removeEventListener('focus', handleFocusBack);
        if(specialty.length > 20) {
            toast.error("Photos cannot be more than 20", {
                theme: "colored"
            });
            return false;
        }

        Resizer.imageFileResizer(
            e.target.files[0], 400, 400, "png", 70, 0,
            (uri) => { 
                dispatch(uploadSpecialtyImageOnly(props._key, props.data.id, uri)).then(res => {
                    if(res === "saved") {
                        setDisabled(false);
                        toast.success("New specialty successfully added", {
                            theme: "colored"
                        });
                        _callIcons()
                    }
                })
            }, "blob", 300, 300
        );
    }

    function _deleteSpecialty(key, id, photoKey, photoId) {
        dispatch(deleteImageSpecialty(key, id, photoKey, photoId)).then(res => {
            if(res == 'saved') {
                toast.error("Photos has been deleted", {
                    theme: "colored"
                });
                _callImages()
            }
        });
    }

    function _submitGenre(type, k) {
        var genreList = [...genre];
        if(type === "add") {
            genreList.push(genre_txt);
            setGenre(genreList);
            setGenreTxt('');
        }else {
            genreList.splice(k, 1);
            setGenre(genreList);
        }
    }

    function _submitEventTypes(type, k) {
        var eventTypeList = [...event_types];
        if(type === "add") {
            eventTypeList.push(event_types_txt);
            setEventTypes(eventTypeList);
            setEventTypesTxt('');
        }else {
            eventTypeList.splice(k, 1);
            setEventTypes(eventTypeList);
        }
    }

    function handleFocusBack(){
        window.removeEventListener('focus', handleFocusBack);
    }

    function _onClose() {
        props.onClose();
        setSubCat([]);
        setSubCatKey('');
        setSubCatName('');
        setPhotos([]);
        setActiveIndex(0);
        setProductName('');
        
        setAddress('');
        setLink('');
        setBiography('');
        setSpecialty([]);

        setGenreTxt('');
        setGenre([]);

        setEventTypesTxt('');
        setEventTypes([]);

        // setSetupTime('');
        // setRequirements('');
        setPrice('');
        setDisabled(false);
        setMinHours(0);
        setServiceDetails('');
    }

    function next() {
        const nextIndex = activeIndex === photo.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
      }
    
    function previous() {
        const nextIndex = activeIndex === 0 ? photo.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    }
}

export default Entertainers;