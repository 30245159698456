import React, { useEffect, useState } from "react";
import Header from "components/Headers/AdminHeader";
import DataTable from 'react-data-table-component';
import {createFilter} from 'react-search-input';

import {
    Card,
    CardHeader,
    CardBody,
    Container,
    Input,
    Button,
    ButtonGroup,
    Badge
} from "reactstrap";

import { useDispatch, useSelector } from 'react-redux';
import { getPackageOrders } from 'redux/actions/orderAction';
// import { getCountry } from 'redux/actions/countryAction';
// import { getIndustries } from 'redux/actions/industryAction';

import cols from './cols';
import ViewDetails from "./viewdetails";
// import Add from './add';
// import Edit from './edit';

const Orders = (props) => {

    const dispatch = useDispatch();
    const order = useSelector(state => state.order);

    useEffect(() => {
        dispatch(getPackageOrders());
    }, []);

    const [search, setSearch] = useState('');
    const [modal, setModal] = useState(false);
    const [selected, setSelected] = useState(null);
    
    const KEYS_TO_FILTERS = ['data.orderId']
    const filterData = order.package_orders.filter(createFilter(search, KEYS_TO_FILTERS));
    
    return (
        <div>
            <Header />
            <Container className="mt--7" fluid>
                <Card>
                    <CardHeader>
                        <h3>Package Orders</h3>
                    </CardHeader>
                    <CardBody>
                        <DataTable
                            columns={cols(_renderBtn, _renderStatus, _renderImg)}
                            data={filterData}
                            progressPending={order.loading}
                            subHeader
                            subHeaderComponent={
                                <Input
                                    placeholder="Search"
                                    onChange={(e) => setSearch(e.target.value)}
                                    style={{width: 250}}
                                    />
                            }
                            pagination
                            />
                    </CardBody>
                </Card>
            </Container>
            {selected !== null ? 
                <ViewDetails
                    isOpen={modal}
                    selected={selected}
                    onClose={() => {
                        setModal(false);
                        setSelected(null);
                    }}
                    />
            : null}
        </div>
    )

    function _renderImg(row) {
        if(row.data.data.photos !== undefined) {
            var photos = [];
            Object.keys(row.data.data.photos).map(k => {
                photos.push(row.data.data.photos[k]);
            });
            return <img src={photos[0].photo} alt="" style={{width: 50, height: 50, objectFit: 'cover'}} />
        }
    }

    function _renderBtn(row) {
        return (
            <ButtonGroup>
                <Button size="sm" color="warning" onClick={() => _edit(row)}>View</Button>
            </ButtonGroup>
        );
    }

    function _renderStatus(row) {
        // switch(row.data.status) {
        //     case 'hide':
        //         return <Badge color="dark">Hidden</Badge>
        //         break;
        //     case 'show':
        //         return <Badge color="success">Visible</Badge>
        //         break;
        //     default:
        //         return <Badge color="warning">For Review</Badge>
        //         break;
        // } 
    }

    function _edit(row) {
        setSelected(row);
        setModal(true);
    }   
}

export default Orders;