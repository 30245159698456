import React from 'react';

import {
    Container,
    Row,
    Col,
    NavbarBrand,
    Navbar,
    Nav,
    NavItem,
    NavLink,
} from 'reactstrap';
import { isMobile } from 'react-device-detect';
import { ToastContainer } from 'react-toastify';

const Footer = (props) => {

    return (
        <>
            {isMobile ? _renderMobile() : _renderWeb()}
            <ToastContainer/>
        </>
    )
    
    function _renderMobile() {
        return (
            <Container>
                <Row>
                    {/* <Col md="6">
                        <NavLink href="/about">
                            <span className="footerMenu">About Us</span>
                        </NavLink>
                    </Col> */}
                    <Col md="6">
                        <NavLink href="/terms">
                            <span className="footerMenu">Terms & Conditions</span>
                        </NavLink>
                    </Col>
                    <Col md="6">
                        <NavLink href="/policy">
                            <span className="footerMenu">Privacy Policy</span>
                        </NavLink>
                    </Col>
                    <Col md="6">
                        <NavLink href="/support">
                            <span className="footerMenu">Support</span>
                        </NavLink>
                    </Col>
                    <Col md="6">
                        <NavLink href="/">
                            <span className="footerMenu">© {new Date().getFullYear()}{" "}Dibrah</span>
                        </NavLink>
                    </Col>
                </Row>
                <br/><br/>
            </Container>
        )
    }

    function _renderWeb() {
        return (
            <Container>
                <Navbar
                    color="transparent"
                    expand="md"
                    light
                    >
                    <NavbarBrand href="/">
                        <span className="footerMenu">© {new Date().getFullYear()}{" "}Dibrah</span>
                    </NavbarBrand>
                    <Nav className="ml-auto">
                        {/* <NavItem>
                            <NavLink href="/about">
                                <span className="footerMenu">About Us</span>
                            </NavLink>
                        </NavItem> */}
                        <NavItem>
                            <NavLink href="/terms">
                                <span className="footerMenu">Terms & Conditions</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href="/policy">
                                <span className="footerMenu">Privacy Policy</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href="/support">
                                <span className="footerMenu">Support</span>
                            </NavLink>
                        </NavItem>
                    </Nav>
                </Navbar>
            </Container>
        )
    }
}

export default Footer;