const cols = (btn) =>  [
    {
        name: 'Icon/Photo',
        selector: row => <img src={row.data.photo} style={{width: 50, height: 50, objectFit: 'cover'}}/>,
    },
    {
        name: 'Category Name',
        selector: row => row.data.name,
    },
    {
        name: '(Arabic)',
        selector: row => row.data.name_ar,
    },
    {
        name: 'Page',
        selector: row => row.data.page,
    },
    {
        name: 'Type',
        selector: row => row.data.type,
    },
    {
        name: 'Target',
        selector: row => (!row.data.target) ? '---' : row.data.target,
    },
    {
        name: 'Commission',
        selector: row => (parseFloat(row.data.commission) * 100).toString()+'%',
    },
    {
        name: 'Status',
        selector: row => row.data.status,
    },
    {
        name: 'Date Created',
        selector: row => row.data.created_date,
    },
    {
        name: '',
        selector: row => btn(row),
        right: true
    },
];

export default cols