import { productsRef, productsStorage } from '../../database/firebase';
import { PRODUCT_DATA, PRODUCT_PHOTO, PRODUCT_ENTERTAINER_PHOTO, PRODUCT_HOSPITALITY_PHOTO } from '../types';
import moment from 'moment';

const getProductItemsAdmin = () => async dispatch => {
    dispatch({type: PRODUCT_DATA, payload: [], loading: true});
    productsRef.on('value', snap => {
        if(snap.val() === null) {
            dispatch({type: PRODUCT_DATA, payload: [], loading: false});
        }else {
            let item = [];
            snap.forEach(child => {
                item.push({
                    _key: child.key,
                    data: child.val()
                })
            });
            dispatch({type: PRODUCT_DATA, payload: item.reverse(), loading: false});
        }
    });
}

const getProductItems = (vendor_key) => async dispatch => {
    dispatch({type: PRODUCT_DATA, payload: [], loading: true});
    productsRef.orderByChild("vendor_key").equalTo(vendor_key).on('value', snap => {
        if(snap.val() === null) {
            dispatch({type: PRODUCT_DATA, payload: [], loading: false});
        }else {
            let item = [];
            snap.forEach(child => {
                item.push({
                    _key: child.key,
                    data: child.val()
                })
            });
            dispatch({type: PRODUCT_DATA, payload: item.reverse(), loading: false});
        }
    });
}

const getProductByKey = (key) => async dispatch => {
    return new Promise((resolve, reject) => {
        productsRef.child(key).on('value', snap => {
            if(snap.val() !== null) {
                var photos = [];
                if(snap.val().photos !== undefined) {
                    Object.keys(snap.val().photos).map(k => {
                        photos.push({
                            _key: k,
                            data: snap.val().photos[k]
                        });
                    });
                    resolve(photos);
                }
            }
        });
    });
}

const getProductByKeyPackage = (key) => async dispatch => {
    return new Promise((resolve, reject) => {
        productsRef.orderByChild('category_key').equalTo(key).once('value', snap => {
            if(snap.val() !== null) {
                let item = [];
                snap.forEach(child => {
                    item.push({
                        _key: child.key,
                        data: child.val()
                    })
                });
                resolve(item);
            }
        });
    });
}

const addProduct = (state) => async dispatch => {
    return new Promise((resolve, reject) => {
        let user = window.localStorage.getItem('@vendor');
        var id = Math.floor(10000 + Math.random() * 90000); 
        var ref = productsRef.push({
            id: id.toString(),
            vendor_key: user,
            currency: 'SAR',
            photo: '',
            status: 'Pending',
            created_date: moment().format('YYYY-MM-DD hh:mm:ss')
        });
        productsRef.child(ref.key).update(state);

        if(state.data.specialty !== undefined && state.data.specialty.length !== 0 ) {
            for(var i=0; i<state.data.specialty.length; i++) {
                var photoID = Math.floor(10000 + Math.random() * 90000);
                dispatch(uploadSpecialtyImage(ref.key, id, photoID, state.data.specialty[i], resolve, reject));
            }
        }

        if(state.data.outfits !== undefined && state.data.outfits.length !== 0 ) {
            for(var i=0; i<state.data.outfits.length; i++) {
                var photoID = Math.floor(10000 + Math.random() * 90000);
                dispatch(uploadOutfitsImage(ref.key, id, photoID, state.data.outfits[i], resolve, reject));
            }
        }

        if(state.data.shapes !== undefined && state.data.shapes.length !== 0 ) {
            for(var i=0; i<state.data.shapes.length; i++) {
                var photoID = Math.floor(10000 + Math.random() * 90000);
                dispatch(uploadCakeShapeImage(ref.key, id, photoID, state.data.shapes[i], resolve, reject));
            }
        }

        for(var i=0; i<state.photo.length; i++) {
            var photoID = Math.floor(10000 + Math.random() * 90000);
            dispatch(uploadImage(ref.key, id, photoID, state.photo[i], resolve, reject));
        }
    });
}

const uploadImage = (key, id, photoID, photo, resolve, reject) => async dispatch => {
    var storageRef = productsStorage.child(id.toString()).child(photoID.toString()+'-product.png').put(photo);
    storageRef.on('state_changed', function(snapshot) {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        if(progress === 100) {
            resolve('saved');
        }
    }, function(error) {
        resolve(error);
    }, function() {
        storageRef.snapshot.ref.getDownloadURL().then(function(downloadURL) {
            productsRef.child(key).child('photos').push({
                id: photoID.toString(),
                photo: downloadURL
            });
        });
    });
}

const editProduct = (state) => async dispatch => {
    return new Promise((resolve, reject) => {
        productsRef.child(state._key).update(state);
        resolve('saved');
    });
}

const changeStatus = (state) => async dispatch => {
    return new Promise((resolve, reject) => {
        productsRef.child(state._key).update({
            status: state.status
        });
        resolve('saved');
    });
}

const uploadImageItemOnly = (key, id, photo) => async dispatch => {
    return new Promise((resolve, reject) => {
        var photoID = Math.floor(10000 + Math.random() * 90000);
        var storageRef = productsStorage.child(id).child(photoID.toString()+'-product.png').put(photo);
        storageRef.on('state_changed', function(snapshot) {
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            if(progress === 100) {
                resolve('saved');
            }
        }, function(error) {
            resolve(error);
        }, function() {
            storageRef.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                productsRef.child(key).child('photos').push({
                    id: photoID.toString(),
                    photo: downloadURL
                });
            });
        });
    });
}

const deleteImage = (key, id, photoKey, photoID) => async dispatch => {
    return new Promise((resolve, reject) => {
        productsStorage.child(id.toString()).child(photoID.toString()+'-product.png').delete();
        productsRef.child(key).child('photos').child(photoKey).remove();
        resolve('saved');
    });
}

const deleteProduct = (_key, id, photos) => async dispatch => {
    return new Promise((resolve, reject) => {
        // if(icons.length !== 0) {
        //     for(var i=0; i<icons.length; i++) {
        //         productsStorage.child(id).child(icons[i].data.id+'-specialty.png').delete();
        //     }
        // }
        if(photos.length !== 0) {
            for(var i=0; i<photos.length; i++) {
                productsStorage.child(id).child(photos[i].data.id+'-product.png').delete();
            }
        }
        productsRef.child(_key).remove();
        resolve('saved');
    });
}

//
// Entertainers
//
const getProductEntertainer = (key) => async dispatch => {
    return new Promise((resolve, reject) => {
        productsRef.child(key).on('value', snap => {
            if(snap.val() !== null) {
                var photos = [];
                Object.keys(snap.val().entertainers_specialty_icons).map(k => {
                    photos.push({
                        _key: k,
                        data: snap.val().entertainers_specialty_icons[k]
                    });
                });
                resolve(photos);
            }
        });
    });
}

const uploadSpecialtyImage = (key, id, photoID, photo, resolve, reject) => async dispatch => {
    var storageRef = productsStorage.child(id.toString()).child(photoID.toString()+'-specialty.png').put(photo);
    storageRef.on('state_changed', function(snapshot) {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        if(progress === 100) {
            //resolve('saved');
        }
    }, function(error) {
        resolve(error);
    }, function() {
        storageRef.snapshot.ref.getDownloadURL().then(function(downloadURL) {
            productsRef.child(key).child('entertainers_specialty_icons').push({
                id: photoID.toString(),
                photo: downloadURL
            });
        });
    });
}

const uploadSpecialtyImageOnly = (key, id, photoID, photo) => async dispatch => {
    var storageRef = productsStorage.child(id.toString()).child(photoID.toString()+'-specialty.png').put(photo);
    storageRef.on('state_changed', function(snapshot) {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        if(progress === 100) {
            //resolve('saved');
        }
    }, function(error) {
        //resolve(error);
    }, function() {
        storageRef.snapshot.ref.getDownloadURL().then(function(downloadURL) {
            productsRef.child(key).child('entertainers_specialty_icons').push({
                id: photoID.toString(),
                photo: downloadURL
            });
        });
    });
}

const deleteImageSpecialty = (key, id, photoKey, photoID) => async dispatch => {
    return new Promise((resolve, reject) => {
        productsStorage.child(id.toString()).child(photoID.toString()+'-specialty.png').delete();
        productsRef.child(key).child('entertainers_specialty_icons').child(photoKey).remove();
        resolve('saved');
    });
}

//
// Hospitality
//
const getProductHospitality = (key) => async dispatch => {
    return new Promise((resolve, reject) => {
        productsRef.child(key).on('value', snap => {
            if(snap.val() !== null) {
                var photos = [];
                Object.keys(snap.val().hospitality_outfits).map(k => {
                    photos.push({
                        _key: k,
                        data: snap.val().hospitality_outfits[k]
                    });
                });
                resolve(photos);
            }
        });
    });
}

const uploadOutfitsImage = (key, id, photoID, photo, resolve, reject) => async dispatch => {
    var storageRef = productsStorage.child(id.toString()).child(photoID.toString()+'-outfits.png').put(photo);
    storageRef.on('state_changed', function(snapshot) {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        if(progress === 100) {
            //resolve('saved');
        }
    }, function(error) {
        resolve(error);
    }, function() {
        storageRef.snapshot.ref.getDownloadURL().then(function(downloadURL) {
            productsRef.child(key).child('hospitality_outfits').push({
                id: photoID.toString(),
                photo: downloadURL
            });
        });
    });
}

const uploadOutfitsImageOnly = (key, id, photoID, photo) => async dispatch => {
    var storageRef = productsStorage.child(id.toString()).child(photoID.toString()+'-outfits.png').put(photo);
    storageRef.on('state_changed', function(snapshot) {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        if(progress === 100) {
            //resolve('saved');
        }
    }, function(error) {
        //resolve(error);
    }, function() {
        storageRef.snapshot.ref.getDownloadURL().then(function(downloadURL) {
            productsRef.child(key).child('hospitality_outfits').push({
                id: photoID.toString(),
                photo: downloadURL
            });
        });
    });
}

const deleteImageHospitality = (key, id, photoKey, photoID) => async dispatch => {
    return new Promise((resolve, reject) => {
        productsStorage.child(id.toString()).child(photoID.toString()+'-outfits.png').delete();
        productsRef.child(key).child('hospitality_outfits').child(photoKey).remove();
        resolve('saved');
    });
}

//
// Cake
//
const getProductCakeShape = (key) => async dispatch => {
    return new Promise((resolve, reject) => {
        productsRef.child(key).on('value', snap => {
            if(snap.val() !== null) {
                if(snap.val().cakeshape !== undefined) {
                    var photos = [];
                    Object.keys(snap.val().cakeshape).map(k => {
                        photos.push({
                            _key: k,
                            data: snap.val().cakeshape[k]
                        });
                    });
                    resolve(photos);
                }
            }
        });
    });
}

const uploadCakeShapeImage = (key, id, photoID, photo, resolve, reject) => async dispatch => {
    var storageRef = productsStorage.child(id.toString()).child(photoID.toString()+'-cakeshape.png').put(photo);
    storageRef.on('state_changed', function(snapshot) {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        if(progress === 100) {
            //resolve('saved');
        }
    }, function(error) {
        resolve(error);
    }, function() {
        storageRef.snapshot.ref.getDownloadURL().then(function(downloadURL) {
            productsRef.child(key).child('cakeshape').push({
                id: photoID.toString(),
                photo: downloadURL
            });
        });
    });
}

const uploadCakeShapeImageOnly = (key, id, photoID, photo) => async dispatch => {
    var storageRef = productsStorage.child(id.toString()).child(photoID.toString()+'-cakeshape.png').put(photo);
    storageRef.on('state_changed', function(snapshot) {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        if(progress === 100) {
            //resolve('saved');
        }
    }, function(error) {
        //resolve(error);
    }, function() {
        storageRef.snapshot.ref.getDownloadURL().then(function(downloadURL) {
            productsRef.child(key).child('cakeshape').push({
                id: photoID.toString(),
                photo: downloadURL
            });
        });
    });
}

const deleteImageCakeSkape = (key, id, photoKey, photoID) => async dispatch => {
    return new Promise((resolve, reject) => {
        productsStorage.child(id.toString()).child(photoID.toString()+'-cakeshape.png').delete();
        productsRef.child(key).child('cakeshape').child(photoKey).remove();
        resolve('saved');
    });
}

export {
    getProductItemsAdmin,
    getProductItems,
    addProduct,
    editProduct,
    uploadImageItemOnly,
    deleteImage,
    deleteProduct,
    getProductByKey,

    getProductEntertainer,
    uploadSpecialtyImageOnly,
    deleteImageSpecialty,
    
    getProductHospitality,
    uploadOutfitsImageOnly,
    deleteImageHospitality,

    getProductCakeShape,
    uploadCakeShapeImageOnly,
    deleteImageCakeSkape,

    getProductByKeyPackage,
    changeStatus
}