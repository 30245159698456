import React, { useEffect, useState } from "react";

import {
    Modal, 
    ModalHeader, 
    ModalBody, 
    ModalFooter,
    Button,
    Form, 
    FormGroup, 
    Label, 
    Input,
    Spinner
} from "reactstrap";
import { toast } from 'react-toastify';
import Resizer from "react-image-file-resizer";
import { useDispatch, useSelector } from 'react-redux';
import { addCategory, inputAction, inputActionReset, inputActionFile } from 'redux/actions/subcatAction';

const Add = (props) => {

    const dispatch = useDispatch();
    const subcat = useSelector(state => state.subcat);
    const [loading, setloading] = useState(false);

    return (
        <Modal isOpen={props.isOpen}>
            <ModalHeader>Add Sub Category</ModalHeader>
            <ModalBody>
                <Form>
                    <FormGroup>
                        <Label>Category</Label>
                        <Input 
                            type="select" 
                            name="category_key"
                            onChange={(e) => dispatch(inputAction(e.target))}
                            value={subcat.category_key}
                            >
                            <option value="">Select Category</option>
                            {props.category.data.map((item, k) => {
                                if(item._key !== "-MuA9Y_mR1pK1hjaThG9") {
                                    return <option key={k} value={item._key}>{item.data.name}</option>
                                }
                            })}
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label>Icon/Photo</Label>
                        <Input 
                            type="file" 
                            name="photo" 
                            onChange={(e) => _onCropChange(e)}
                            accept="image/*"
                            />
                        <br/>
                        <p style={{fontSize: 12}}>Accepts .png file only for transparent background</p>
                    </FormGroup>
                    <FormGroup>
                        <Label>Name</Label>
                        <Input 
                            type="text" 
                            name="name" 
                            onChange={(e) => dispatch(inputAction(e.target))}
                            value={subcat.name}
                            />
                    </FormGroup>
                    <FormGroup>
                        <Label>Name (Arabic)</Label>
                        <Input 
                            type="text" 
                            name="name_ar" 
                            onChange={(e) => dispatch(inputAction(e.target))} 
                            style={{textAlign: "right"}}
                            value={subcat.name_ar}/>
                    </FormGroup>
                </Form>
            </ModalBody>
            <ModalFooter>
                {loading === true ? <Spinner /> : 
                    <>
                        <Button 
                            color="primary" 
                            onClick={() => _submit()}>
                                Save
                        </Button>{' '}
                        <Button color="secondary" onClick={() => _onClose()}>Cancel</Button>
                    </>
                }
            </ModalFooter>
        </Modal>
    )

    function _submit() {

        if(subcat.photo.length === 0) {
            toast.error("Please add sub category icon/photo", {
                theme: "colored"
            });
            return false;
        }

        if(subcat.name === '') {
            toast.error("Please enter sub category name", {
                theme: "colored"
            });
            return false;
        }
        
        if(subcat.name_ar === '') {
            toast.error("Please enter sub category name in arabic", {
                theme: "colored"
            });
            return false;
        }

        let params = {
            category_key: subcat.category_key,
            vendor_key: 'admin',
            photo: subcat.photo,
            name: subcat.name,
            name_ar: subcat.name_ar
        }

        setloading(true);

        dispatch(addCategory(params)).then(res => {
            if(res === 'saved') {
                dispatch(inputActionReset());
                setloading(false);
                props.onClose();
            }
        });
    }

    function _onCropChange(e) {
        window.removeEventListener('focus', handleFocusBack);
        Resizer.imageFileResizer(
            e.target.files[0],
            200, 200, "png", 70, 0,
            (uri) => {
                dispatch(inputActionFile(uri))
            },
            "blob",
            200,
            200
        );
    }

    function handleFocusBack(){
        window.removeEventListener('focus', handleFocusBack);
    }

    function _onClose() {
        dispatch(inputActionReset());
        props.onClose();
    }
}

export default Add;