/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import { Container, Row, Col } from "reactstrap";
import { ToastContainer } from 'react-toastify';

// core components
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import AuthFooter from "components/Footers/AuthFooter.js";

import { useDispatch } from "react-redux";
import { getVendorsByKey } from 'redux/actions/vendorAction';

import Login from "views/vendors/login";

const Auth = (props) => {
  const dispatch = useDispatch();
  const mainContent = React.useRef(null);
  const location = useLocation();

  React.useEffect(() => {
    //
    // check users login
    //
    let user = window.localStorage.getItem('@vendor');
    if(user !== null) {
      dispatch(getVendorsByKey(user)).then(res => {
        if(res !== null) {
          window.location.href = "/vendor";
        }else {
          window.localStorage.removeItem('@vendor');
        }
      });
    }

    document.body.classList.add("bg-default");
    return () => {
      document.body.classList.remove("bg-default");
    };
  }, []);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  return (
    <>
      <div className="main-content" ref={mainContent}>
        <div className="header bg-gradient-primary py-7 py-lg-8">
          <div className="separator separator-bottom separator-skew zindex-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="fill-default"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div>
        </div>
        {/* Page content */}
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Switch>
              <Route
                path="/vpanel/login"
                component={Login}
                />
              <Redirect from="*" to="/vpanel/login" />
            </Switch>
          </Row>
        </Container>
      </div>
      <AuthFooter />
      <ToastContainer />
    </>
  );
};

export default Auth;
