import React, { useEffect, useState } from "react";

import {
    Modal, 
    ModalHeader, 
    ModalBody, 
    ModalFooter,
    Button,
    Form, 
    FormGroup, 
    Label, 
    Input,
    Spinner,
    Row,
    Col
} from "reactstrap";
import Resizer from "react-image-file-resizer";
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import {
    inputData,
    inputFile,
    resetData,
    addVendors,
    checkAdminUsername
} from 'redux/actions/vendorAction';
import { getCategories } from 'redux/actions/categoryAction';
import Select from 'react-select';
// import axios from 'axios';
// import { notification } from '../../utils/services';

const Add = (props) => {

    const dispatch = useDispatch();
    const vendor = useSelector(state => state.vendor);
    const category = useSelector(state => state.category);
    const [btnDisabled, setDisabled] = useState(false);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        dispatch(getCategories());
    }, []);

    function _onChangeFile(e) {
        window.removeEventListener('focus', handleFocusBack);
        dispatch(inputFile(e.target.files[0]));
    }

    function handleFocusBack(){
        window.removeEventListener('focus', handleFocusBack);
    }

    let categoryList = [];
    if(category.data.length !== 0) {
        for(var i=0; i<category.data.length; i++) {
            categoryList.push({
                value: category.data[i]._key, 
                label: category.data[i].data.name, 
                label_ar: category.data[i].data.name_ar, 
            });
        }
    }

    return (
        <Modal isOpen={props.isOpen} size="lg">
            <ModalHeader>{props.title}</ModalHeader>
            <ModalBody>
                <Form>
                    <h1>Indentity</h1>
                    <FormGroup>
                        <Label>Category</Label>
                        <Select 
                            defaultValue={categories}
                            options={categoryList} 
                            isMulti
                            onChange={(val) => setCategories(val)}
                            name="categories"
                            />
                    </FormGroup>
                    {/* <FormGroup>
                        <Label>Category</Label>
                        <Input 
                            type="select" 
                            name="category_key"
                            onChange={(e) => dispatch(inputData(e.target))}
                            >
                            <option value="">Select Category</option>
                            {category.data.map((item, k) => {
                                if(item.data.status === "active") {
                                    return <option key={k} value={item._key}>{item.data.name}</option>
                                }
                            })}
                        </Input>
                    </FormGroup> */}
                    <Row>
                        <Col lg="6" md="6" sm="12" xs="12">
                            <FormGroup>
                                <Label>Company Name</Label>
                                <Input 
                                    type="text" 
                                    name="company_name"
                                    onChange={(e) => dispatch(inputData(e.target))}
                                    value={vendor.company_name}
                                    />
                            </FormGroup>
                        </Col>
                        <Col lg="6" md="6" sm="12" xs="12">
                            <FormGroup>
                                <Label>Company Name (Arabic)</Label>
                                <Input 
                                    type="text" 
                                    name="company_name_ar"
                                    onChange={(e) => dispatch(inputData(e.target))}
                                    value={vendor.company_name_ar}
                                    />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="6" md="6" sm="12" xs="12">
                            <FormGroup>
                                <Label>Company Registration Number (CR #)</Label>
                                <Input 
                                    type="number" 
                                    name="cr_number"
                                    onChange={(e) => dispatch(inputData(e.target))}
                                    value={vendor.cr_number}
                                    />
                            </FormGroup>
                        </Col>
                        <Col lg="6" md="6" sm="12" xs="12">
                            <FormGroup>
                                <Label>Upload CR file</Label>
                                <Input 
                                    type="file" 
                                    name="cr_file"
                                    accept="application/pdf,application/vnd.ms-excel"
                                    onChange={(e) => _onChangeFile(e)}
                                    />
                            </FormGroup>
                        </Col>
                    </Row>
                    <hr />

                    <h1>Shop Info</h1>
                    <Row>
                        <Col lg="6" md="6" sm="12" xs="12">
                            <FormGroup>
                                <Label>Shop Name</Label>
                                <Input 
                                    type="text" 
                                    name="shop_name"
                                    onChange={(e) => dispatch(inputData(e.target))}
                                    value={vendor.shop_name}
                                    />
                            </FormGroup>
                        </Col>
                        <Col lg="6" md="6" sm="12" xs="12">
                            <FormGroup>
                                <Label>Shop Name (Arabic)</Label>
                                <Input 
                                    type="text" 
                                    name="shop_name_ar"
                                    onChange={(e) => dispatch(inputData(e.target))}
                                    value={vendor.shop_name_ar}
                                    />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="6" md="6" sm="12" xs="12">
                            <FormGroup>
                                <Label>Country</Label>
                                <Input 
                                    type="text" 
                                    name="country"
                                    value={vendor.country}
                                    readOnly
                                    />
                            </FormGroup>
                        </Col>
                        <Col lg="6" md="6" sm="12" xs="12">
                            <FormGroup>
                                <Label>Number of Branches</Label>
                                <Input 
                                    type="number" 
                                    name="num_of_branches"
                                    onChange={(e) => dispatch(inputData(e.target))}
                                    value={vendor.num_of_branches}
                                    />
                            </FormGroup>
                        </Col>
                    </Row>
                    <FormGroup>
                        <Label>Address</Label>
                        <Input 
                            type="textarea" 
                            name="address"
                            onChange={(e) => dispatch(inputData(e.target))}
                            value={vendor.address}
                            />
                    </FormGroup>
                    <Row>
                        <Col lg="4" md="4" sm="12" xs="12">
                            <FormGroup>
                                <Label>Part of Franchise?</Label>
                                <Input 
                                    type="select" 
                                    name="part_of_franchise"
                                    onChange={(e) => dispatch(inputData(e.target))}
                                    value={vendor.part_of_franchise}
                                    >
                                    <option value="no">No</option>
                                    <option value="yes">Yes</option>
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col lg="4" md="4" sm="12" xs="12">
                            <FormGroup>
                                <Label>Have delivery Service?</Label>
                                <Input 
                                    type="select" 
                                    name="have_a_deliver_service"
                                    onChange={(e) => dispatch(inputData(e.target))}
                                    value={vendor.have_a_deliver_service}
                                    >
                                    <option value="no">No</option>
                                    <option value="yes">Yes</option>
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col lg="4" md="4" sm="12" xs="12">
                            <FormGroup>
                                <Label>Have existing delivery app?</Label>
                                <Input 
                                    type="select" 
                                    name="are_in_other_delivery_app"
                                    onChange={(e) => dispatch(inputData(e.target))}
                                    value={vendor.are_in_other_delivery_app}
                                    >
                                    <option value="no">No</option>
                                    <option value="yes">Yes</option>
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <hr />

                    <h1>Personal Info</h1>
                    <Row>
                        <Col lg="4" md="4" sm="12" xs="12">
                            <FormGroup>
                                <Label>Phone/Mobile Number</Label>
                                <Input 
                                    type="text" 
                                    name="phone_number"
                                    onChange={(e) => dispatch(inputData(e.target))}
                                    value={vendor.phone_number}
                                    />
                            </FormGroup>
                        </Col>
                        <Col lg="4" md="4" sm="12" xs="12">
                            <FormGroup>
                                <Label>Email Address</Label>
                                <Input 
                                    type="text" 
                                    name="email"
                                    onChange={(e) => dispatch(inputData(e.target))}
                                    value={vendor.email}
                                    />
                            </FormGroup>
                        </Col>
                        <Col lg="4" md="4" sm="12" xs="12">
                            <FormGroup>
                                <Label>Are you the owner?</Label>
                                <Input 
                                    type="select" 
                                    name="are_you_the_owner"
                                    onChange={(e) => dispatch(inputData(e.target))}
                                    value={vendor.are_you_the_owner}
                                    >
                                    <option value="no">No</option>
                                    <option value="yes">Yes</option>
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="6" md="6" sm="12" xs="12">
                            <FormGroup>
                                <Label>Username</Label>
                                <Input 
                                    type="text" 
                                    name="username"
                                    onChange={(e) => dispatch(inputData(e.target))}
                                    value={vendor.username}
                                    />
                            </FormGroup>
                        </Col>
                        <Col lg="6" md="6" sm="12" xs="12">
                            <FormGroup>
                                <Label>Password</Label>
                                <Input 
                                    type="password" 
                                    name="password"
                                    value={vendor.password}
                                    readOnly
                                    />
                                <i>Default Password (123456)</i>
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            </ModalBody>
            <ModalFooter>
                {btnDisabled === true ? <Spinner /> : 
                    <>
                        <Button color="primary" onClick={() => _submit()}>Save</Button>{' '}
                        <Button color="secondary" onClick={() => _onClose()}>Cancel</Button>
                    </>
                }
            </ModalFooter>
        </Modal>
    )

    function _submit() {
        if(categories.length === 0) {
            toast.error("Please select atleast 1 category", {
                theme: "colored"
            });
            return false;
        }

        if(vendor.company_name === '') {
            toast.error("Please add company name", {
                theme: "colored"
            });
            return false;
        }

        if(vendor.company_name_ar === '') {
            toast.error("Please add company name in Arabic", {
                theme: "colored"
            });
            return false;
        }

        if(vendor.cr_number === '') {
            toast.error("Please add company registration number", {
                theme: "colored"
            });
            return false;
        }

        if(vendor.cr_file.length === 0) {
            toast.error("Please add company registration file", {
                theme: "colored"
            });
            return false;
        }

        if(vendor.shop_name === '') {
            toast.error("Please add shop name", {
                theme: "colored"
            });
            return false;
        }

        if(vendor.shop_name_ar === '') {
            toast.error("Please add compshopany name in Arabic", {
                theme: "colored"
            });
            return false;
        }

        if(vendor.phone_number === '') {
            toast.error("Please add compshopany name in Arabic", {
                theme: "colored"
            });
            return false;
        }

        if(vendor.email === '') {
            toast.error("Please add compshopany name in Arabic", {
                theme: "colored"
            });
            return false;
        }

        var emailValid = vendor.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        if(emailValid === null) {
            toast.error("Please enter valid email address", {
                theme: "colored"
            });
            return false;
        }

        if(vendor.username === '') {
            toast.error("Please add compshopany name in Arabic", {
                theme: "colored"
            });
            return false;
        }

        setDisabled(true);

        let params = {
            categories: categories,
            company_name: vendor.company_name,
            company_name_ar: vendor.company_name_ar,
            cr_number: vendor.cr_number,
            cr_file: vendor.cr_file,
            shop_name: vendor.shop_name,
            shop_name_ar: vendor.shop_name_ar,
            country: vendor.country,
            address: vendor.address,
            num_of_branches: vendor.num_of_branches,
            part_of_franchise: vendor.part_of_franchise,
            have_a_deliver_service: vendor.have_a_deliver_service,
            are_in_other_delivery_app: vendor.are_in_other_delivery_app,
            phone_number: vendor.phone_number,
            email: vendor.email,
            are_you_the_owner: vendor.are_you_the_owner,
            username: vendor.username,
            password: vendor.password
        }

        dispatch(checkAdminUsername(vendor.username)).then(res => {
            if(res === 'not_available') {
                setDisabled(false);
                toast.error("Username already taken", {
                    theme: "colored"
                });
                return false;
            }else {
                _onSubmit(params);
            }
        });
    }

    function _onSubmit(params) {
        dispatch(addVendors(params)).then(res => {
            if(res === 'saved') {
                _onClose();
                setDisabled(false);
            }else {
                setDisabled(false);
                toast.error("An error occur please try again later", {
                    theme: "colored"
                });
                return false;
            }
        });
    }

    function _onClose() {
        dispatch(resetData());
        props.onClose();
    }
}

const styles = {
    fileupload: {
        width: '100%',
        height: 250,
        backgroundColor: '#ecf0f1',
        marginBottom: 20
    }
}

export default Add;