import React, { useEffect, useState } from "react";

import {
    Modal, 
    ModalHeader, 
    ModalBody,
    ModalFooter,
    Form,
    FormGroup, 
    Label, 
    Input,
    Row,
    Col,
    Badge,
    Button,
    Carousel,
    CarouselItem,
    CarouselControl,
    InputGroup,
    InputGroupAddon,
    Spinner
} from "reactstrap";
import { useDispatch, useSelector } from 'react-redux';
import { addPackages } from 'redux/actions/packagesAction';
import { getProductByKeyPackage } from 'redux/actions/productAction';
import { getCategories } from 'redux/actions/categoryAction';

import Resizer from "react-image-file-resizer";
import { toast } from 'react-toastify';
import Select from 'react-select'; 

import { BiTimeFive } from 'react-icons/bi';
import { FaPencilRuler } from 'react-icons/fa';
import { BsCircle, BsCircleFill, BsSpeedometer } from 'react-icons/bs';
import { AiFillStar } from 'react-icons/ai';
import { IoIosArrowDown } from 'react-icons/io';
import { MdOutlinePower } from 'react-icons/md';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

const Add = (props) => {

    const dispatch = useDispatch();
    const category = useSelector(state => state.category);

    const [photo, setPhotos] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);

    const [package_category_key, setPackageCategoryKey] = useState('');
    const [package_category_name, setPackageCategoryName] = useState('');

    const [package_name, setPackageName] = useState('');
    const [description, setDescription] = useState('');
    const [price, setPrice] = useState('');
    const [package_includes, setPackageIncludes] = useState([]);
    //const [requirements, setRequirements] = useState('');
    //const [space, setSpace] = useState('');
    const [package_details, setPackageDetails] = useState('');
    const [btnDisabled, setDisabled] = useState(false);

    const [category_key, setCategoryKey] = useState('');
    const [category_name, setCategoryName] = useState('');
    const [productList, setProductList] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState([]);

    const [requirements, setRequirements] = useState([
        {
            title: 'Set Up Time',
            isIncluded: 'No',
            value: ''
        },
        {
            title: 'Electricity',
            isIncluded: 'No',
            value: ''
        },
        {
            title: 'Session Duration',
            isIncluded: 'No',
            value: ''
        },
        {
            title: 'Dimension',
            isIncluded: 'No',
            value: ''
        },
        {
            title: 'Pick Up Time',
            isIncluded: 'No',
            value: ''
        }
    ]);

    useEffect(() => {
        dispatch(getCategories());
    }, []);

    let prodList = [];
    if(productList.length !== 0) {
        for(var i=0; i<productList.length; i++) {
            prodList.push({
                value: productList[i]._key, 
                label: productList[i].data.product_name+' - '+productList[i].data.price+' '+productList[i].data.currency, 
                _key: productList[i]._key, 
                data: productList[i].data, 
            });
        }
    }

    function _renderIcons(title) {
        switch(title) {
            case 'Set Up Time':
                return <BiTimeFive size={20}/>
            case 'Electricity':
                return <MdOutlinePower size={20}/>
            case 'Session Duration':
                return <BsSpeedometer size={20}/>
            case 'Dimension':
                return <FaPencilRuler size={20}/>
            case 'Pick Up Time':
                return <BiTimeFive size={20}/>
        }
    }

    return (
        <Modal isOpen={props.isOpen} size="lg">
            <ModalHeader>Add Package</ModalHeader>
            <ModalBody>

                <FormGroup>
                    <Label>Package Categories</Label>
                    <Input 
                        type="select"
                        name="package_category_key"
                        value={package_category_key}
                        onChange={(e) => _selectPackageCategory(e)}
                        >
                        <option value="">Select Pacakge Category</option>
                        <option value="game_night">Game Night</option>
                        <option value="corporate_events">Corporate Events</option>
                        <option value="movie_night">Movie Night</option>
                        <option value="jalsat">Jalsat</option>
                        <option value="casual_celebration">Casual Celebration (Birthday, Wedding, etc)</option>
                    </Input>
                </FormGroup>

                {/* Photo */}
                <div style={{backgroundColor: '#ecf0f1', width: '100%', height: 300, borderRadius: 10, marginBottom: 10}}>
                    {_renderPhoto()}
                </div>
                <FormGroup>
                    <Label>Upload Photo</Label>
                    <Input type="file" name="file" onChange={(e) => _uploadPhoto(e)} accept="image/*"/>
                </FormGroup>

                <Row>
                    <Col md="9" xs="9">
                        <FormGroup>
                            <Input type="text" name="package_name" value={package_name} placeholder="Package Name" onChange={(e) => setPackageName(e.target.value)}/>
                        </FormGroup>
                    </Col>
                    <Col md="3" xs="3" style={{textAlign: 'right'}}>
                        5.0 <AiFillStar />
                    </Col>
                </Row>

                <FormGroup>
                    <Label>Description</Label>
                    <Input type="textarea" name="description" value={description} onChange={(e) => setDescription(e.target.value)}/>
                </FormGroup>

                <FormGroup>
                    <InputGroup>
                        <Input type="number" placeholder="Price" name="price" value={price} onChange={(e) => setPrice(e.target.value)}/>
                        <InputGroupAddon addonType="prepend">Starting Price in SAR</InputGroupAddon>
                    </InputGroup>
                </FormGroup>

                <div style={{marginBottom: 30}}>
                    <h3>Package Includes</h3>

                    <FormGroup>
                        <Label>Categories</Label>
                        <Input 
                            type="select"
                            name="category_key"
                            value={category_key}
                            onChange={(e) => _selectCategory(e)}
                            >
                            <option value="">Select Category</option>
                            {category.data.map((item, k) => {
                                return <option key={k} value={item._key}>{item.data.name}</option>
                            })}
                        </Input>
                    </FormGroup>

                    <FormGroup>
                        <Label>Products</Label>
                        <Select 
                            defaultValue={selectedProduct}
                            value={selectedProduct}
                            options={prodList} 
                            isMulti
                            onChange={(val) => setSelectedProduct(val)}
                            name="categories"
                            />
                    </FormGroup>
                    
                    <Button size="sm" color="warning" onClick={() => _addPackage()}>Add This Product</Button>

                    {_renderPackageIncludes()}
                </div>

                <hr />

                {requirements.map((item, k) => {
                    return(
                        <Row key={k} style={{marginBottom: 10}}>
                            <Col md={1}>
                                {item.isIncluded === "Yes" ? 
                                    <BsCircleFill size={18} onClick={() => _onChangeRequirements(item, k, 'isIncluded', 'No')}/> : 
                                    <BsCircle size={18} onClick={() => _onChangeRequirements(item, k, 'isIncluded', 'Yes')}/>
                                }
                            </Col>
                            <Col md={3}>
                                {_renderIcons(item.title)} {item.title}
                            </Col> 
                            <Col>
                                <Input 
                                    type="text"
                                    name="value"
                                    size="sm"
                                    value={item.value}
                                    onChange={(e) => _onChangeRequirements(item, k, e.target.name, e.target.value)}
                                    />
                            </Col>
                        </Row>
                    );
                })}

                <hr />

                <Accordion>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                <Row>
                                    <Col md="8" xs="4">
                                        Package Details
                                    </Col>
                                    <Col md="4" xs="4" style={{textAlign: "right"}}>
                                        <IoIosArrowDown size={30}/>
                                    </Col>
                                </Row>
                                <div className="bottomBorder"></div>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <Input placeholder="Type here..." type="textarea" name="package_details" value={package_details} onChange={(e) => setPackageDetails(e.target.value)}/>
                            <br/>
                        </AccordionItemPanel>
                    </AccordionItem>
                </Accordion>
            </ModalBody>
            <ModalFooter>
                {btnDisabled === true ? <Spinner /> : 
                    <>
                        <Button color="primary" onClick={() => _submit()}>Save</Button>{' '}
                        <Button color="secondary" onClick={() => _onClose()}>Cancel</Button>
                    </>
                }
            </ModalFooter>
        </Modal>
    )

    function _onChangeRequirements(item, k, name, val) {
        var req = [...requirements];
        req[k][name] = val;
        setRequirements(req);
    }

    function _selectPackageCategory(e) {
        let index = e.nativeEvent.target.selectedIndex;
        let label = e.nativeEvent.target[index].text;
        setPackageCategoryKey(e.target.value);
        setPackageCategoryName(label);
    }

    function _renderPackageIncludes() {
        if(package_includes.length === 0) {
            return (
                <div style={{backgroundColor: '#ecf0f1', padding: 20, borderRadius: 10, marginTop: 20}}>
                    <h3>No Package Included</h3>
                </div>
            );
        }

        return package_includes.map((item, k) => {
            return (
                <div key={k} style={{backgroundColor: '#ecf0f1', padding: 20, borderRadius: 10, marginTop: 20}}>
                    <h3>{item.category_name}</h3>
                    {item.products.map((d, k) => {
                        return (
                            <div key={k}>
                                - {d.data.product_name+' - '+d.data.price+' '+d.data.currency}
                            </div>
                        )
                    })}
                    <br/>
                    <Button size="sm" color="danger" onClick={() => _deletePackage(k)}>Delete</Button>
                </div>
            );
        });
    }

    function _selectCategory(e) {
        let index = e.nativeEvent.target.selectedIndex;
        let label = e.nativeEvent.target[index].text;

        setCategoryKey(e.target.value);
        setCategoryName(label);

        dispatch(getProductByKeyPackage(e.target.value)).then((res => {
            setProductList(res)
        }));
    }

    function _addPackage() {
        if(category_key === '') {
            toast.error("Please select product category", {
                theme: "colored"
            });
            return false;
        }

        if(selectedProduct.length === 0) {
            toast.error("Please select atleast 1 product", {
                theme: "colored"
            });
            return false;
        }

        var params = {
            category_key: category_key,
            category_name: category_name,
            products: selectedProduct
        }

        var newPackage = [...package_includes, params];
        setPackageIncludes(newPackage);

        prodList = [];
        setCategoryKey('');
        setCategoryName('');
        setProductList([]);
        setSelectedProduct([]);
    }  
    
    function _deletePackage(k) {
        var newPackage = [...package_includes];
        newPackage.splice(k, 1);
        setPackageIncludes(newPackage);
    }

    function _submit() {
        if(photo.length === 0) {
            toast.error("Please add atleast 1 photo", {
                theme: "colored"
            });
            return false;
        }

        if(package_category_key === '') {
            toast.error("Please select the package category", {
                theme: "colored"
            });
            return false;
        }

        if(package_name === '') {
            toast.error("Please enter the package name", {
                theme: "colored"
            });
            return false;
        }

        if(description === '') {
            toast.error("Please enter the description", {
                theme: "colored"
            });
            return false;
        }

        if(price === '') {
            toast.error("Please enter the price", {
                theme: "colored"
            });
            return false;
        }

        if(package_includes.length === 0) {
            toast.error("Please add atleast 1 package included", {
                theme: "colored"
            });
            return false;
        }

        if(package_details === '') {
            toast.error("Please enter the package details", {
                theme: "colored"
            });
            return false;
        }

        var params = {
            photo: photo,
            package_category_key: package_category_key,
            package_category_name: package_category_name,
            package_name: package_name,
            description: description,
            price: price,
            requirements: requirements,
            package_details: package_details,
            package_includes: package_includes
        }

        setDisabled(true);
        dispatch(addPackages(params)).then(res => {
            if(res === 'saved') {
                setDisabled(false);
                toast.success("New package successfully added", {
                    theme: "colored"
                });
                _onClose();
            }else {
                setDisabled(false);
                toast.error("An error occur please trye again later", {
                    theme: "colored"
                });
                return false;
            }
        });
    }

    function _renderPhoto() {
        if(photo.length !== 0) {
            return (
                <Carousel
                    activeIndex={activeIndex}
                    next={() => next()}
                    previous={() => previous()}
                    >
                    {photo.map((item, k) => {
                        return(
                            <CarouselItem key={k}>
                                <img src={URL.createObjectURL(item)} style={{width: '100%', height: 300, objectFit: 'cover'}}/>
                            </CarouselItem>
                        )
                    })}
                    <CarouselControl direction="prev" directionText="Previous" onClickHandler={() => previous()} />
                    <CarouselControl direction="next" directionText="Next" onClickHandler={() => next()} />
                </Carousel>
            )
        }else {
            return (
                <div className="imageSize">
                    <h3>Image size must be 400x400 pixel</h3>
                </div>
            )
        }
    }

    function _uploadPhoto(e) {
        window.removeEventListener('focus', handleFocusBack);
        if(photo.length > 10) {
            toast.error("Photos cannot be more than 10", {
                theme: "colored"
            });
            return false;
        }

        Resizer.imageFileResizer(
            e.target.files[0], 400, 400, "png", 70, 0,
            (uri) => { 
                setPhotos([...photo, uri]); 
            }, "blob", 300, 300
        );
    }

    function handleFocusBack(){
        window.removeEventListener('focus', handleFocusBack);
    }

    function _onClose() {
        props.onClose();
        setPhotos([]);
        setPackageCategoryKey('');
        setPackageCategoryName('');
        setPackageName('');
        setDescription('');
        setPrice('');
        setPackageIncludes([]);
        setPackageDetails('');
        setDisabled(false);
    }

    function next() {
        const nextIndex = activeIndex === photo.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    }
    
    function previous() {
        const nextIndex = activeIndex === 0 ? photo.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    }
}

export default Add;