import React, { useEffect, useState } from "react";

import {
    Modal, 
    ModalHeader, 
    ModalBody, 
    ModalFooter,
    Button,
    Form, 
    FormGroup, 
    Label, 
    Input,
    Spinner,
    Row,
    Col,
    Badge,
    ListGroup,
    ListGroupItem
} from "reactstrap";
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { getDiscountsByKey, changeStatus } from 'redux/actions/discountsAction';

const ViewData = (props) => {

    const { selected } = props;

    const dispatch = useDispatch();
    const discounts = useSelector(state => state.discounts);

    const [_key, setKey] = useState('');
    const [id, setID] = useState('');
    const [photos, setPhotos] = useState([]);
    const [title_en, setTitleEn] = useState('');
    const [title_ar, setTitleAr] = useState('');
    const [desc_en, setDescEn] = useState('');
    const [desc_ar, setDescAr] = useState('');
    const [options, setOptions] = useState([]);
    const [price, setPrice] = useState('');
    const [currency, setCurrency] = useState('');
    
    const [commission, setCommission] = useState('');
    const [reason, setReason] = useState('');
    const [btnDisabled, setDisabled] = useState(false);

    useEffect(() => {
        setKey(selected._key);
        setID(selected.data.id);
        setTitleEn(selected.data.title_en);
        setTitleAr(selected.data.title_ar);
        setDescEn(selected.data.desc_en);
        setDescAr(selected.data.desc_ar);
        setPrice(selected.data.price);
        setCurrency(selected.data.currency);
        setOptions(selected.data.options);

        dispatch(getDiscountsByKey(selected._key));

    }, [selected]);

    return (
        <Modal isOpen={props.isOpen} size="lg">
            <ModalHeader>Discount</ModalHeader>
            <ModalBody>
                <div className="flex-item" style={{backgroundColor: '#ecf0f1', marginBottom: 10, padding: 20, marginTop: 10, borderRadius: 10}}>    
                    <Row>
                        <Col md="4" xs="12">
                            {discounts.photos.length !== 0 ? 
                                discounts.photos.map((item, k) => {
                                    return (
                                        <img key={k} src={item.data.photo} alt="" style={{width: '100%', height: 200, objectFit: 'cover', marginBottom: 10}}/>
                                    )
                                })
                            : null}
                        </Col>
                        <Col md="8" xs="12">
                            <ListGroup>
                                <ListGroupItem>{title_en}</ListGroupItem>
                                <ListGroupItem style={{textAlign: 'right'}}>{title_ar}</ListGroupItem>
                                <ListGroupItem>{desc_en}</ListGroupItem>
                                <ListGroupItem style={{textAlign: 'right'}}>{desc_ar}</ListGroupItem>
                                <ListGroupItem>{price+' '+currency}</ListGroupItem>
                            </ListGroup>
                            <hr />
                            <ListGroup>
                                {options.length !== 0 ? 
                                    options.map((i, key) => {
                                        return (
                                            <div key={key}>
                                                <ListGroupItem>
                                                    <strong>{i.optionLabelEn} {_renderAction(i)}</strong><br/>
                                                    {i.optionList.map((j, jkey) => {
                                                        return (
                                                            <Badge key={jkey} color="primary" style={{color: '#fff', marginRight: 10}}>
                                                                {j.label_en}
                                                            </Badge>
                                                        )
                                                    })}
                                                </ListGroupItem>
                                                
                                            </div>
                                        )
                                    })
                                : "No Options"}
                            </ListGroup>
                        </Col>
                    </Row>
                </div>
                <FormGroup>
                    <Label>Commission</Label>
                    <Input 
                        type="number" 
                        name="commission" 
                        onChange={(e) => setCommission(e.target.value)}
                        value={commission}/>
                    <i style={{fontSize: 12}}>Put 0. on front to calculate dynamically. Example 0.05 = 5%</i>
                </FormGroup>
                <FormGroup>
                    <Label>Rejection reason</Label>
                    <Input 
                        type="textarea"
                        name="reason"
                        value={reason}
                        onChange={(e) => setReason(e.target.value)}
                        bsSize="sm"
                        />
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                {btnDisabled === true ? <Spinner /> : 
                    <>
                        <Button color="primary" onClick={() => _action('approved')}>Approve</Button>{' '}
                        <Button color="danger" onClick={() => _action('rejected')}>Reject</Button>{' '}
                        <Button color="secondary" onClick={() => _onClose()}>Cancel</Button>
                    </>
                }
            </ModalFooter>
        </Modal>
    )

    function _renderAction(i) {
        switch(i.optionAction) {
            case 'multiple_selection':
                return '- (Multiple Selection)'
                break;
            case 'single_selection':
                return '- (Single Selection)'
                break;
            default:
                return '- (Display Only)'
                break;
        }
    }

    function _action(status) {

        if(status === "rejected") {
            if(reason === "") {
                toast.error("Please add reason", {
                    theme: "colored"
                });
                return false;
            }
        }

        setDisabled(true);

        var params = {
            _key: _key,
            reason: reason,
            commission: commission,
            status: status
        }

        dispatch(changeStatus(params)).then(res => {
            if(res === 'saved') {
                _onClose();
            }
        });
    }

    function _onClose() {
        setKey('');
        setID('');
        setPhotos([]);
        setTitleEn('');
        setTitleAr('');
        setDescEn('');
        setDescAr('');
        setOptions([]);
        setPrice('');
        setCurrency('');
        setDisabled(false);
        props.onClose();
    }
}

export default ViewData;