//
// Admin
//
export const AUTH_ADMIN = 'AUTH_ADMIN';
export const AUTH_VENDOR = 'AUTH_VENDOR';

export const ADMIN_DATA = 'ADMIN_DATA';
export const ADMIN_ACTION_INPUT = 'ADMIN_ACTION_INPUT';
export const ADMIN_ACTION_RESET = 'ADMIN_ACTION_RESET';
export const ADMIN_ACTION_EDIT = 'ADMIN_ACTION_EDIT';

export const VENDOR_DATA = 'VENDOR_DATA';
export const VENDOR_ACTION_INPUT = 'VENDOR_ACTION_INPUT';
export const VENDOR_ACTION_FILE = 'VENDOR_ACTION_FILE';
export const VENDOR_ACTION_RESET = 'VENDOR_ACTION_RESET';
export const VENDOR_ACTION_EDIT = 'VENDOR_ACTION_EDIT';

export const PRODUCT_DATA = 'PRODUCT_DATA';
export const PRODUCT_PHOTO = 'PRODUCT_PHOTO';
export const PRODUCT_ENTERTAINER_PHOTO = 'PRODUCT_ENTERTAINER_PHOTO';
export const PRODUCT_HOSPITALITY_PHOTO = 'PRODUCT_HOSPITALITY_PHOTO';
export const PRODUCT_ACTION_INPUT = 'PRODUCT_ACTION_INPUT';
export const PRODUCT_ACTION_FILE = 'PRODUCT_ACTION_FILE';
export const PRODUCT_ACTION_CHECK = 'PRODUCT_ACTION_CHECK';
export const PRODUCT_ACTION_RESET = 'PRODUCT_ACTION_RESET';
export const PRODUCT_ACTION_EDIT = 'PRODUCT_ACTION_EDIT';

export const PRODUCT_ACTION_ADDFORM = 'PRODUCT_ACTION_ADDFORM';
export const PRODUCT_ACTION_REMOVEFORM = 'PRODUCT_ACTION_REMOVEFORM';
export const PRODUCT_ACTION_ONCHANGE = 'PRODUCT_ACTION_ONCHANGE';

export const ORDER_DATA = 'ORDER_DATA';
export const PACAKGE_ORDER_DATA = 'PACAKGE_ORDER_DATA';
export const ORDER_DATA_DONE = 'ORDER_DATA_DONE';
export const ORDER_DATA_SCAN = 'ORDER_DATA_SCAN';

export const CATEGORY_DATA = 'CATEGORY_DATA';
export const CATEGORY_ACTION_FILE = 'CATEGORY_ACTION_FILE';
export const CATEGORY_ACTION_COLOR = 'CATEGORY_ACTION_COLOR';
export const CATEGORY_ACTION_INPUT = 'CATEGORY_ACTION_INPUT';
export const CATEGORY_ACTION_RESET = 'CATEGORY_ACTION_RESET';
export const CATEGORY_ACTION_EDIT = 'CATEGORY_ACTION_EDIT';

export const SUB_CATEGORY_DATA = 'SUB_CATEGORY_DATA';
export const SUB_CATEGORY_ACTION_FILE = 'SUB_CATEGORY_ACTION_FILE';
export const SUB_CATEGORY_ACTION_INPUT = 'SUB_CATEGORY_ACTION_INPUT';
export const SUB_CATEGORY_ACTION_RESET = 'SUB_CATEGORY_ACTION_RESET';
export const SUB_CATEGORY_ACTION_EDIT = 'SUB_CATEGORY_ACTION_EDIT';

export const CATERING_SUBCAT_DATA = 'CATERING_SUBCAT_DATA';
export const CATERING_SUBCAT_INPUT = 'CATERING_SUBCAT_INPUT';
export const CATERING_SUBCAT_RESET = 'CATERING_SUBCAT_RESET';
export const CATERING_SUBCAT_EDIT = 'CATERING_SUBCAT_EDIT';

export const CUSTOMER_DATA = 'CUSTOMER_DATA';

export const MOOD_DATA = 'MOOD_DATA';
export const MOOD_ACTION_INPUT = 'MOOD_ACTION_INPUT';
export const MOOD_ACTION_RESET = 'MOOD_ACTION_RESET';
export const MOOD_ACTION_EDIT = 'MOOD_ACTION_EDIT';

export const FORM_DATA = 'FORM_DATA';

export const OFFERS_DATA = 'OFFERS_DATA';
export const OFFERS_PHOTO = 'OFFERS_PHOTO';

export const PACKAGES_DATA = 'PACKAGES_DATA';
export const PACKAGES_PHOTO = 'PACKAGES_PHOTO';

export const DISCOUNTS_DATA = 'DISCOUNTS_DATA';
export const DISCOUNTS_PHOTO = 'DISCOUNTS_PHOTO';

export const ADMIN_PACKAGES_DATA = 'ADMIN_PACKAGES_DATA';
export const ADMIN_PACKAGES_PHOTO = 'ADMIN_PACKAGES_PHOTO';

export const BANNER_DATA = 'BANNER_DATA';
export const BANNER_PHOTO = 'BANNER_PHOTO';

export const SETTINGS_DATA = 'SETTINGS_DATA';





