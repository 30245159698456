import { ordersRef, packageOrdersRef } from '../../database/firebase';
import { PACAKGE_ORDER_DATA, ORDER_DATA, ORDER_DATA_DONE, ORDER_DATA_SCAN } from '../types';

const getOrders = () => async dispatch => {
    dispatch({type: ORDER_DATA, payload: [], loading: true});
    ordersRef.on('value', snap => {
        if(snap.val() === null) {
            dispatch({type: ORDER_DATA, payload: [], loading: false});
        }else {
            let item = [];
            snap.forEach(child => {
                item.push({
                    _key: child.key,
                    data: child.val()
                })
            });
            dispatch({type: ORDER_DATA, payload: item.reverse(), loading: false});
        }
    });
}

const getPackageOrders = () => async dispatch => {
    dispatch({type: PACAKGE_ORDER_DATA, payload: [], loading: true});
    packageOrdersRef.on('value', snap => {
        if(snap.val() === null) {
            dispatch({type: PACAKGE_ORDER_DATA, payload: [], loading: false});
        }else {
            let item = [];
            snap.forEach(child => {
                item.push({
                    _key: child.key,
                    data: child.val()
                })
            });
            dispatch({type: PACAKGE_ORDER_DATA, payload: item.reverse(), loading: false});
        }
    });
}

const getOrdersByVendor = (key, type) => async dispatch => {
    dispatch({type: ORDER_DATA, payload: [], loading: true});
    ordersRef.orderByChild('vendor_key').equalTo(key).on('value', snap => {
        if(snap.val() === null) {
            dispatch({type: ORDER_DATA, payload: [], loading: false});
        }else {
            let item = [];
            snap.forEach(child => {
                if(type === child.val().target) {

                    var photos = [];
                    Object.keys(child.val().photos).map(k => {
                        photos.push(child.val().photos[k].photo);
                    });

                    item.push({
                        _key: child.key,
                        data: child.val(),
                        photo: photos[0]
                    })
                }
            });
            dispatch({type: ORDER_DATA, payload: item.reverse(), loading: false});
        }
    });
}

const orderChangeStatus = (key, status) => async dispatch => {
    return new Promise((resolve, reject) => {
        ordersRef.child(key).update({
            status: status
        });
        resolve('saved');
    });
}

const orderPackageChangeStatus = (key, status) => async dispatch => {
    return new Promise((resolve, reject) => {
        packageOrdersRef.child(key).update({
            status: status
        });
        resolve('saved');
    });
}

const getOrdersTotal = (key) => async dispatch => {
    dispatch({type: ORDER_DATA, payload: [], loading: true});
    ordersRef.orderByChild('vendor_key').equalTo(key).on('value', snap => {
        if(snap.val() === null) {
            dispatch({type: ORDER_DATA, payload: [], loading: false});
        }else {
            let item = [];
            snap.forEach(child => {
                item.push({
                    _key: child.key,
                    data: child.val()
                })
            });
            dispatch({type: ORDER_DATA, payload: item.reverse(), loading: false});
        }
    });
}

const getOrderDone = () => async dispatch => {
    dispatch({type: ORDER_DATA_DONE, payload: [], loading: true});
    ordersRef.orderByChild('status').equalTo('done').on('value', snap => {
        if(snap.val() === null) {
            dispatch({type: ORDER_DATA_DONE, payload: [], loading: false});
        }else {
            let item = [];
            snap.forEach(child => {
                item.push({
                    _key: child.key,
                    data: child.val()
                })
            });
            dispatch({type: ORDER_DATA_DONE, payload: item.reverse(), loading: false});
        }
    });
}

const getOrderScan = () => async dispatch => {
    dispatch({type: ORDER_DATA_SCAN, payload: [], loading: true});
    ordersRef.orderByChild('status').equalTo('scanned').on('value', snap => {
        if(snap.val() === null) {
            dispatch({type: ORDER_DATA_SCAN, payload: [], loading: false});
        }else {
            let item = [];
            snap.forEach(child => {
                item.push({
                    _key: child.key,
                    data: child.val()
                })
            });
            dispatch({type: ORDER_DATA_SCAN, payload: item.reverse(), loading: false});
        }
    });
}

export {
    getOrders,
    getPackageOrders,
    getOrdersByVendor,
    getOrdersTotal,
    getOrderDone,
    getOrderScan,
    orderChangeStatus,
    orderPackageChangeStatus
}