import React, { useEffect, useState } from "react";

import {
    Modal, 
    ModalHeader, 
    ModalBody,
    ModalFooter,
    Form,
    FormGroup, 
    Label, 
    Input,
    Row,
    Col,
    Badge,
    Button,
    Carousel,
    CarouselItem,
    CarouselControl,
    InputGroup,
    InputGroupAddon,
    Spinner
} from "reactstrap";
import Resizer from "react-image-file-resizer";
import { toast } from 'react-toastify';
import { TwitterPicker } from 'react-color';
import { BiTimeFive } from 'react-icons/bi';
import { FaPencilRuler } from 'react-icons/fa';
import { BsSpeedometer2, BsCircle, BsCircleFill, BsSpeedometer } from 'react-icons/bs';
import { AiFillStar, AiFillDelete } from 'react-icons/ai';
import { IoIosArrowDown } from 'react-icons/io';
import { MdOutlinePower } from 'react-icons/md';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import { useDispatch } from "react-redux";
import { addProduct } from "redux/actions/productAction"; 
import { getSubCatByCategory } from 'redux/actions/subcatAction';

const Backdrops = (props) => {

    const dispatch = useDispatch();

    const categoryData = window.localStorage.getItem('@vendor_category');
    const categoryParse = JSON.parse(categoryData);

    const [subcat, setSubCat] = useState([]);
    const [sub_category_key, setSubCatKey] = useState('');
    const [sub_category_name, setSubCatName] = useState('');

    const [photo, setPhotos] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const [product_name, setProductName] = useState('');
    const [description, setDescription] = useState('');

    const [colour, setColour] = useState([])

    const [small_height, setSmallHeight] = useState('');
    const [small_width, setSmallWidth] = useState('');

    const [medium_height, setMediumHeight] = useState('');
    const [medium_width, setMediumWidth] = useState('');

    const [large_height, setLargeHeight] = useState('');
    const [large_width, setLargeWidth] = useState('');

    const [requirements, setRequirements] = useState([
        {
            title: 'Set Up Time',
            isIncluded: 'No',
            value: ''
        },
        {
            title: 'Electricity',
            isIncluded: 'No',
            value: ''
        },
        {
            title: 'Session Duration',
            isIncluded: 'No',
            value: ''
        },
        {
            title: 'Dimension',
            isIncluded: 'No',
            value: ''
        },
        {
            title: 'Pick Up Time',
            isIncluded: 'No',
            value: ''
        }
    ]);

    const [price, setPrice] = useState('');
    const [btnDisabled, setDisabled] = useState(false);

    const [product_details, setProductDetails] = useState('');
    const [minQty, setMinQty] = useState(0);
    const [maxQty, setMaxQty] = useState(0);
    const [stocks, setStocks] = useState(0);

    const [selectedColour, setSelectedColour] = useState('');

    useEffect(() => {
        dispatch(getSubCatByCategory(props.category_key)).then(res => {
            if(res.length === 0) {
                setSubCatKey('---');
                setSubCatName('---');
            }else {
                setSubCat(res);
            }
        });
    }, [props.category_key, props.category_name]);

    function _renderIcons(title) {
        switch(title) {
            case 'Set Up Time':
                return <BiTimeFive size={20}/>
            case 'Electricity':
                return <MdOutlinePower size={20}/>
            case 'Session Duration':
                return <BsSpeedometer size={20}/>
            case 'Dimension':
                return <FaPencilRuler size={20}/>
            case 'Pick Up Time':
                return <BiTimeFive size={20}/>
        }
    }

    return (
        <Modal isOpen={props.isOpen} toggle={() => _onClose()} size="lg">
            <ModalHeader toggle={() => _onClose()}>Add Product</ModalHeader>
            <ModalBody>
                <Form>
                    {subcat.length !== 0 ? 
                        <FormGroup>
                            <Label>Sub Category</Label>
                            <Input 
                                type="select" 
                                name="sub_category_key"
                                onChange={(e) => _selectSubCategory(e)}
                                >
                                <option value="">Select Category</option>
                                {subcat.map((item, k) => {
                                    return <option key={k} value={item._key}>{item.data.name}</option>
                                })}
                            </Input>
                        </FormGroup>
                    : null}

                    <div>
                        {/* Photo */}
                        <div style={{backgroundColor: '#ecf0f1', width: '100%', height: 300, borderRadius: 10, marginBottom: 10}}>
                            {_renderPhoto()}
                        </div>
                        <FormGroup>
                            <Label>Upload Photo</Label>
                            <Input type="file" name="file" onChange={(e) => _uploadPhoto(e)} accept="image/*"/>
                        </FormGroup>
                        
                        <Row>
                            <Col md="9" xs="9">
                                <FormGroup>
                                    <Input type="text" name="product_name" value={product_name} placeholder="Product Name" onChange={(e) => setProductName(e.target.value)}/>
                                </FormGroup>
                            </Col>
                            <Col md="3" xs="3" style={{textAlign: 'right'}}>
                                5.0 <AiFillStar />
                            </Col>
                        </Row>

                        <FormGroup>
                            <Label>Description</Label>
                            <Input type="textarea" name="description" value={description} onChange={(e) => setDescription(e.target.value)}/>
                        </FormGroup>

                        <FormGroup>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">SAR</InputGroupAddon>
                                <Input type="number" placeholder="Price" name="price" value={price} onChange={(e) => setPrice(e.target.value)}/>
                            </InputGroup>
                        </FormGroup>

                        <p>Colours</p>
                        {colour.length !== 0 ? 
                            <div style={{marginBottom: 20}}>
                                {colour.map((item, k) => {
                                    return (
                                        <Badge key={k} pill
                                            style={{marginRight: 10, cursor: 'pointer', backgroundColor: item}} 
                                            onClick={() => _selectedColour('remove', k)}
                                            >
                                            &nbsp;
                                        </Badge>
                                    )
                                })}
                            </div>
                        : null}
                        <TwitterPicker onChangeComplete={e => setSelectedColour(e.hex)}/> <br/>
                        {selectedColour !== '' ? 
                            <>
                                <Button size="sm" color="primary" onClick={() => _selectedColour('add', '')}>Add Colour</Button><br/><br/>
                            </>
                        : null}

                        <FormGroup>
                            <Label>Minimum Quantity</Label>
                            <Input type="number" name="minQty" value={minQty} onChange={(e) => setMinQty(e.target.value)}/>
                        </FormGroup>

                        <FormGroup>
                            <Label>Maximum Quantity</Label>
                            <Input type="number" name="maxQty" value={maxQty} onChange={(e) => setMaxQty(e.target.value)}/>
                        </FormGroup>

                        <FormGroup>
                            <Label>Inventory Stocks</Label>
                            <Input type="number" name="stocks" value={stocks} onChange={(e) => setStocks(e.target.value)}/>
                        </FormGroup>

                        <FormGroup>
                            <Label>Small</Label>
                            <Row>
                                <Col md="6" xs="12">
                                    <InputGroup>
                                        <Input type="text" name="small_height" value={small_height} onChange={(e) => setSmallHeight(e.target.value)}/>
                                        <InputGroupAddon addonType="prepend">Height</InputGroupAddon>
                                    </InputGroup>
                                </Col>
                                <Col md="6" xs="12">
                                    <InputGroup>
                                        <Input type="text" name="small_width" value={small_width} onChange={(e) => setSmallWidth(e.target.value)}/>
                                        <InputGroupAddon addonType="prepend">Width</InputGroupAddon>
                                    </InputGroup>
                                </Col>
                            </Row>
                        </FormGroup>

                        <FormGroup>
                            <Label>Medium</Label>
                            <Row>
                                <Col md="6" xs="12">
                                    <InputGroup>
                                        <Input type="text" name="medium_height" value={medium_height} onChange={(e) => setMediumHeight(e.target.value)}/>
                                        <InputGroupAddon addonType="prepend">Height</InputGroupAddon>
                                    </InputGroup>
                                </Col>
                                <Col md="6" xs="12">
                                    <InputGroup>
                                        <Input type="text" name="medium_width" value={medium_width} onChange={(e) => setMediumWidth(e.target.value)}/>
                                        <InputGroupAddon addonType="prepend">Width</InputGroupAddon>
                                    </InputGroup>
                                </Col>
                            </Row>
                        </FormGroup>

                        <FormGroup>
                            <Label>Large</Label>
                            <Row>
                                <Col md="6" xs="12">
                                    <InputGroup>
                                        <Input type="text" name="large_height" value={large_height} onChange={(e) => setLargeHeight(e.target.value)}/>
                                        <InputGroupAddon addonType="prepend">Height</InputGroupAddon>
                                    </InputGroup>
                                </Col>
                                <Col md="6" xs="12">
                                    <InputGroup>
                                        <Input type="text" name="large_width" value={large_width} onChange={(e) => setLargeWidth(e.target.value)}/>
                                        <InputGroupAddon addonType="prepend">Width</InputGroupAddon>
                                    </InputGroup>
                                </Col>
                            </Row>
                        </FormGroup>

                        <hr />

                        {requirements.map((item, k) => {
                            return(
                                <Row key={k} style={{marginBottom: 10}}>
                                    <Col md={1}>
                                        {item.isIncluded === "Yes" ? 
                                            <BsCircleFill size={18} onClick={() => _onChangeRequirements(item, k, 'isIncluded', 'No')}/> : 
                                            <BsCircle size={18} onClick={() => _onChangeRequirements(item, k, 'isIncluded', 'Yes')}/>
                                        }
                                    </Col>
                                    <Col md={3}>
                                        {_renderIcons(item.title)} {item.title}
                                    </Col> 
                                    <Col>
                                        <Input 
                                            type="text"
                                            name="value"
                                            size="sm"
                                            value={item.value}
                                            onChange={(e) => _onChangeRequirements(item, k, e.target.name, e.target.value)}
                                            />
                                    </Col>
                                </Row>
                            );
                        })}

                        <hr />

                        <Accordion>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        <Row>
                                            <Col md="8" xs="4">
                                                Product Details
                                            </Col>
                                            <Col md="4" xs="4" style={{textAlign: "right"}}>
                                                <IoIosArrowDown size={30}/>
                                            </Col>
                                        </Row>
                                        <div className="bottomBorder"></div>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <Input placeholder="Type here..." type="textarea" name="product_details" value={product_details} onChange={(e) => setProductDetails(e.target.value)}/>
                                    <br/>
                                </AccordionItemPanel>
                            </AccordionItem>
                        </Accordion>
                    </div>
                </Form>
            </ModalBody>
            <ModalFooter>
                {
                    btnDisabled === true ? <Spinner /> : 
                    <Button color="primary" onClick={() => _submit()}>Save this Product</Button>
                }
            </ModalFooter>
        </Modal>
    )

    function _onChangeRequirements(item, k, name, val) {
        var req = [...requirements];
        req[k][name] = val;
        setRequirements(req);
    }

    function _selectSubCategory(e) {
        let index = e.nativeEvent.target.selectedIndex;
        let label = e.nativeEvent.target[index].text;
        setSubCatKey(e.target.value);
        setSubCatName(label);
    }

    function _submit() {
        if(sub_category_key === '') {
            toast.error("Please select category", {
                theme: "colored"
            });
            return false;
        }

        if(photo.length === 0) {
            toast.error("Please add atleast 1 photo", {
                theme: "colored"
            });
            return false;
        }

        if(product_name === '') {
            toast.error("Please enter the product name", {
                theme: "colored"
            });
            return false;
        }

        if(colour.length === 0) {
            toast.error("Please add atleast 1 colour", {
                theme: "colored"
            });
            return false;
        }

        if(price === '') {
            toast.error("Please enter the price", {
                theme: "colored"
            });
            return false;
        }

        if(minQty === '') {
            toast.error("Please enter the minimum quantity", {
                theme: "colored"
            });
            return false;
        }

        if(maxQty === '') {
            toast.error("Please enter the maximum quantity", {
                theme: "colored"
            });
            return false;
        }

        if(stocks === '') {
            toast.error("Please enter the inventory stocks", {
                theme: "colored"
            });
            return false;
        }

        if(small_height === '') {
            toast.error("Please add small height", {
                theme: "colored"
            });
            return false;
        }

        if(small_width === '') {
            toast.error("Please add small width", {
                theme: "colored"
            });
            return false;
        }

        if(medium_height === '') {
            toast.error("Please add medium height", {
                theme: "colored"
            });
            return false;
        }

        if(medium_width === '') {
            toast.error("Please add medium width", {
                theme: "colored"
            });
            return false;
        }

        if(large_height === '') {
            toast.error("Please add large height", {
                theme: "colored"
            });
            return false;
        }

        if(large_width === '') {
            toast.error("Please add large width", {
                theme: "colored"
            });
            return false;
        }

        // if(setup_time === '') {
        //     toast.error("Please add setup time", {
        //         theme: "colored"
        //     });
        //     return false;
        // }

        // if(dimention === '') {
        //     toast.error("Please add dimention", {
        //         theme: "colored"
        //     });
        //     return false;
        // }

        if(product_details === '') {
            toast.error("Please add product details", {
                theme: "colored"
            });
            return false;
        }

        var params = {
            category_key: props.category_key,
            category_name: props.category_name,
            sub_category_key: sub_category_key,
            sub_category_name: sub_category_name,
            target: 'product',
            photo: photo,
            product_name: product_name,
            description: description,
            price: price,
            minQty: minQty,
            maxQty: maxQty,
            stocks: stocks,
            data: {
                product_details: product_details,
                colour: colour,
                requirements: requirements,
                small_height: small_height,
                small_width: small_width,
                medium_height: medium_height,
                medium_width: medium_width,
                large_height: large_height,
                large_width: large_width
            }
        }

        setDisabled(true);
        dispatch(addProduct(params)).then(res => {
            if(res === 'saved') {
                setDisabled(false);
                toast.success("New product successfully added and it will review by Dibrah Team", {
                    theme: "colored"
                });
                _onClose();
            }else {
                setDisabled(false);
                toast.error("An error occur please trye again later", {
                    theme: "colored"
                });
                return false;
            }
        });
    }

    function _renderPhoto() {
        if(photo.length !== 0) {
            return (
                <Carousel
                    activeIndex={activeIndex}
                    next={() => next()}
                    previous={() => previous()}
                    >
                    {photo.map((item, k) => {
                        return(
                            <CarouselItem key={k}>
                                <img src={URL.createObjectURL(item)} style={{width: '100%', height: 300, objectFit: 'cover'}}/>
                            </CarouselItem>
                        )
                    })}
                    <CarouselControl direction="prev" directionText="Previous" onClickHandler={() => previous()} />
                    <CarouselControl direction="next" directionText="Next" onClickHandler={() => next()} />
                </Carousel>
            )
        }else {
            return (
                <div className="imageSize">
                    <h3>Image size must be 400x400 pixel</h3>
                </div>
            )
        }
    }

    function _uploadPhoto(e) {
        window.removeEventListener('focus', handleFocusBack);
        if(photo.length > 10) {
            toast.error("Photos cannot be more than 10", {
                theme: "colored"
            });
            return false;
        }

        Resizer.imageFileResizer(
            e.target.files[0], 400, 400, "png", 70, 0,
            (uri) => { 
                setPhotos([...photo, uri]); 
            }, "blob", 300, 300
        );
    }

    function _selectedColour(type, k) {
        var colours = [...colour];
        if(type === "add") {
            colours.push(selectedColour);
            setColour(colours);
            setSelectedColour('');
        }else {
            colours.splice(k, 1);
            setColour(colours);
        }
    }

    function handleFocusBack(){
        window.removeEventListener('focus', handleFocusBack);
    }

    function _onClose() {
        props.onClose();
        setSubCat([]);
        setSubCatKey('');
        setSubCatName('');
        setPhotos([]);
        setActiveIndex(0);
        setProductName('');
        setDescription('');
        setColour([]);
        setPrice('');
        setDisabled(false);
        setSelectedColour('');
        setSmallHeight('');
        setSmallWidth('');
        setMediumHeight('');
        setMediumWidth('');
        setLargeHeight('');
        setLargeWidth('');
        setMinQty(0);
        setMaxQty(0);
        setStocks(0);
        setProductDetails('');
    }

    function next() {
        const nextIndex = activeIndex === photo.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    }
    
    function previous() {
        const nextIndex = activeIndex === 0 ? photo.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    }
}

export default Backdrops;