import React, { useEffect, useState } from "react";
import Header from "components/Headers/AdminHeader";

import {
    Card,
    CardHeader,
    CardBody,
    Container,
    Form, 
    FormGroup, 
    Label, 
    Input,
    Row,
    Col,
    Button,
    CardFooter,
    Table,
    Spinner
} from "reactstrap";
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { addForms } from 'redux/actions/formAction';
import { getCategories, getCategoryByKey } from 'redux/actions/categoryAction';

import NewForms from "./newforms";

const AddForm = (props) => {

    const dispatch = useDispatch();
    const category = useSelector(state => state.category);

    useEffect(() => {
        dispatch(getCategories());
    }, []);

    const [category_key, setCategory]        = useState('');
    const [category_name, setCategoryName]   = useState('');

    const [forms, setForms]             = useState([]);
    const [addNewForm, setNewForm]      = useState(false);
    const [isDateRequired, setIsDateRequired]   = useState(false);

    return (
        <div>
            <Header />
            <Container className="mt--7" fluid>
                <Card>
                    <CardHeader>
                        <h3>Forms</h3>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col md="6" xs="12">
                                <FormGroup>
                                    <Label>Category</Label>
                                    <Input 
                                        type="select" 
                                        name="category_key"
                                        onChange={(e) => _selectCategory(e)}
                                        value={category_key}
                                        bsSize="sm"
                                        >
                                        <option value="">Select</option>
                                        {category.data.map((item, k) => {
                                            return <option key={k} value={item._key}>{item.data.name}</option>
                                        })}
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col md="6" xs="12"></Col>
                        </Row>

                        <Button color="primary" onClick={() => setNewForm(true)}>Add Forms List</Button>
                        
                        <br/><br/>

                        <Table>
                            <thead>
                                <tr>
                                    <th>
                                        Type
                                    </th>
                                    <th>
                                        Label
                                    </th>
                                    <th>
                                        Label in Arabic
                                    </th>
                                    <th>
                                        Identifier
                                    </th>
                                    <th>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                {forms.map((item, k) => {
                                    return (
                                        <tr key={k}>
                                            <td>{item.type}</td>
                                            <td>{item.label_en}</td>
                                            <td>{item.label_ar}</td>
                                            <td>{item.name_en}{item.type === 'file' ? ", Multiple Upload: "+item.isMultiple : ""}</td>
                                            <td>
                                                <Button
                                                    color="danger" 
                                                    size="sm" 
                                                    onClick={() => _removeFormData(k)}>Remove
                                                </Button>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </CardBody>
                    {forms.length !== 0 ? 
                        <CardFooter>
                            <Button color="primary" onClick={() => _submit()}>Submit</Button>
                        </CardFooter>
                    : null}
                </Card>
            </Container>
            <NewForms 
                isOpen={addNewForm}
                onClose={() => setNewForm(false)}
                addForms={(forms) => _addForm(forms)}
                />
        </div>
    )

    function _selectCategory(e) {
        setCategory(e.target.value);
        dispatch(getCategoryByKey(e.target.value)).then(res => {
            if(res.type === "scheduled") setIsDateRequired(true);
            setCategoryName(res.name);
        });
    }

    function _removeFormData(index) {
        const list = [...forms];
        list.splice(index, 1);
        setForms(list);
    }

    function _addForm(newForms) {
        let newForm = [...forms, newForms];
        setForms(newForm);
    }

    function _submit() {

        if(category_key === "") {
            toast.error("Please select the category", {
                theme: "colored"
            });
            return false
        }

        var params = {
            category_key: category_key,
            category_name: category_name,
            isDateRequired: isDateRequired,
            forms: forms
        }

        dispatch(addForms(params)).then(res => {
            if(res === "saved") {
                window.location.href = "/admin/forms"
            }else {
                toast.error("An error occur please try again", {
                    theme: "colored"
                });
            }
        })
    }
}

export default AddForm;