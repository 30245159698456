import React, { useEffect, useState } from "react";
import Header from "components/Headers/AdminHeader";
import DataTable from 'react-data-table-component';
import {createFilter} from 'react-search-input';

import {
    Card,
    CardHeader,
    CardBody,
    Container,
    Input,
    Button,
    ButtonGroup
} from "reactstrap";

import { useDispatch, useSelector } from 'react-redux';
import { getProductItemsAdmin, changeStatus } from 'redux/actions/productAction';

import cols from './cols';
// import Add from './add';
// import Edit from './edit';

const Products = (props) => {

    const dispatch = useDispatch();
    const product = useSelector(state => state.product);

    useEffect(() => {
        dispatch(getProductItemsAdmin());
    }, []);

    const [search, setSearch] = useState('');
    const [add, setAdd] = useState(false);
    const [edit, setEdit] = useState(false);
    const [selected, setSelected] = useState(null);
    
    const KEYS_TO_FILTERS = ['data.title_en', 'data.title_ar']
    const filterData = product.data.filter(createFilter(search, KEYS_TO_FILTERS));
    
    return (
        <div>
            <Header />
            <Container className="mt--7" fluid>
                <Card>
                    <CardHeader>
                        <h3>Products</h3>
                    </CardHeader>
                    <CardBody>
                        {/* <Button color="primary" onClick={() => setAdd(true)}>Add Products</Button> */}
                        <DataTable
                            columns={cols(_renderBtn, _renderImg)}
                            data={filterData}
                            progressPending={product.loading}
                            subHeader
                            subHeaderComponent={
                                <Input
                                    placeholder="Search"
                                    onChange={(e) => setSearch(e.target.value)}
                                    style={{width: 250}}
                                    />
                            }
                            pagination
                            />
                    </CardBody>
                </Card>
            </Container>
            {/* <Add
                isOpen={add}
                onClose={() => setAdd(false)}
                />
            {selected !== null ? 
                <Edit
                    isOpen={edit}
                    selected={selected}
                    onClose={() => setEdit(false)}
                    />
            : null} */}
        </div>
    )

    function _renderImg(row) {
        if(row.data.photos !== undefined) {
            var photos = [];
            Object.keys(row.data.photos).map(k => {
                photos.push(row.data.photos[k]);
            });
            return <img src={photos[0].photo} alt="" style={{width: 50, height: 50, objectFit: 'cover'}} />
        }
    }

    function _renderBtn(row) {
        switch(row.data.status) {
            case "Pending":
                return <Button size="sm" color="primary" onClick={() => _changeStatus(row, 'Approved')}>Approve</Button>
            case "Approved":
                return <Button size="sm" color="warning" onClick={() => _changeStatus(row, 'Hide')}>Hide</Button>
            case "Hide":
                return <Button size="sm" color="info" onClick={() => _changeStatus(row, 'Approved')}>Show</Button>
        }
    }

    function _changeStatus(row, status) {
        let params = {
            _key: row._key,
            status: status
        }
        dispatch(changeStatus(params));
    }
}

export default Products;