const cols = (btn) =>  [
    {
        name: 'Type',
        selector: row => row.data.type,
    },
    {
        name: 'Name',
        selector: row => row.data.name,
    },
    {
        name: '(Arabic)',
        selector: row => row.data.name_ar,
    },
    {
        name: 'Date Created',
        selector: row => row.data.created_date,
    },
    {
        name: '',
        selector: row => btn(row),
        right: true
    },
];

export default cols