import { 
    DISCOUNTS_DATA,
    DISCOUNTS_PHOTO
} from '../types';

const initialState = {
    loading: false,
    data: [],
    photos: []
}

const discountsReducer = (state = initialState, action) => {
    switch(action.type) {
        case DISCOUNTS_DATA:
            return {
                ...state,
                data: action.payload,
                loading: action.loading
            }
            break;
        case DISCOUNTS_PHOTO:
            return {
                ...state,
                photos: action.payload,
                loading: action.loading
            }
            break;
        default:
            return state;
            break;
    }
}   

export default discountsReducer;