import { vendorsRef, vendorStorage } from '../../database/firebase';
import { 
    VENDOR_DATA,
    VENDOR_ACTION_INPUT,
    VENDOR_ACTION_FILE,
    VENDOR_ACTION_RESET,
    VENDOR_ACTION_EDIT
} from '../types';
import moment from 'moment';
import { Base64 } from 'js-base64';
import { salt, pepper } from '../../utils/password';

const getVendors = () => async dispatch => {
    dispatch({type: VENDOR_DATA, payload: [], loading: true});
    vendorsRef.on('value', snap => {
        if(snap.val() === null) {
            dispatch({type: VENDOR_DATA, payload: [], loading: false});
        }else {
            let item = [];
            snap.forEach(child => {
                if(child.val().status === "active") {
                    item.push({
                        _key: child.key,
                        data: child.val()
                    })
                }
            });
            dispatch({type: VENDOR_DATA, payload: item.reverse(), loading: false});
        }
    });
}

const getVendorsByKey = (key) => async dispatch => {
    return new Promise((resolve, reject) => {
        vendorsRef.child(key).once('value', snap => {
            resolve(snap.val())
        });
    });
}

const checkAdminUsername = (username) => async dispatch => {
    return new Promise((resolve, reject) => {
        vendorsRef.orderByChild('username').equalTo(username).once('value', snap => {
            if(snap.val() === null) {
                resolve('available');
            }else {
                resolve('not_available');
            }
        });
    });
}

const addVendors = (state) => async dispatch => {
    return new Promise((resolve, reject) => {
        var id = Math.floor(10000 + Math.random() * 90000);
        var ref = vendorsRef.push({
            id: id.toString(),
            cr_file: "",
            status: 'active',
            created_date: moment().format('YYYY-MM-DD hh:mm:ss')
        });
        vendorsRef.child(ref.key).update(state);
        dispatch(uploadFile(ref.key, id, state, resolve, reject));
    });
}

const uploadFile = (key, id, state, resolve, reject) => async dispatch => {
    var storageRef = vendorStorage.child(id.toString()).child(state.cr_file.name).put(state.cr_file);
    storageRef.on('state_changed', function(snapshot) {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        if(progress === 100) {
            resolve('saved');
        }
    }, function(error) {
        resolve(error);
    }, function() {
        storageRef.snapshot.ref.getDownloadURL().then(function(downloadURL) {
            let password = salt+Base64.encode('123456')+pepper;
            vendorsRef.child(key).update({
                cr_file: downloadURL,
                access: state.username+'_'+password
            });
        });
    });
}

const editVendors = (state) => async dispatch => {
    return new Promise((resolve, reject) => {
        vendorsRef.child(state._key).update(state);
        resolve('saved');
    });
}

const approveVendors = (state) => async dispatch => {
    return new Promise((resolve, reject) => {
        vendorsRef.child(state._key).update({status: 'hide'});
        resolve('saved');
    });
}

const uploadImageItemOnly = (state, file) => async dispatch => {
    return new Promise((resolve, reject) => {
        var storageRef = vendorStorage.child(state.id.toString()).child(state.id.toString()+'-merchant.png').put(file);
        storageRef.on('state_changed', function(snapshot) {
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            if(progress === 100) {
                resolve('saved');
            }
        }, function(error) {
            resolve(error);
        }, function() {
            storageRef.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                vendorsRef.child(state._key).update({
                    photo: downloadURL
                });
            });
        });
    });
}

const deleteVendors = (state) => async dispatch => {
    return new Promise((resolve, reject) => {
        vendorsRef.child(state._key).update({
            status: 'hide'
        });
        resolve('saved');
    });
}

const inputData = (state) => {
    return {
        type: VENDOR_ACTION_INPUT,
        payload: state
    }
}

const inputFile = (state) => {
    return {
        type: VENDOR_ACTION_FILE,
        payload: state
    }
}

const resetData = () => {
    return {
        type: VENDOR_ACTION_RESET,
        payload: {}
    }
}

const inputEdit = (state) => {
    return {
        type: VENDOR_ACTION_EDIT,
        payload: state
    }
}

export {
    getVendors,
    getVendorsByKey,
    addVendors,
    editVendors,
    uploadImageItemOnly,
    deleteVendors,
    approveVendors,
    inputData,
    inputFile,
    resetData,
    inputEdit,
    checkAdminUsername
}