import React, { useEffect, useState, useRef, useCallback } from "react";

import {
    Modal, 
    ModalHeader, 
    ModalBody,
    ModalFooter,
    Form,
    FormGroup, 
    Label, 
    Input,
    Row,
    Col,
    Button,
    Carousel,
    CarouselItem,
    CarouselControl,
    InputGroup,
    InputGroupAddon,
    Spinner,
} from "reactstrap";
import Resizer from "react-image-file-resizer";
import { toast } from 'react-toastify';
import '../styles.css';

import { FaToilet, FaSwimmingPool } from 'react-icons/fa';
import { BiTimeFive } from 'react-icons/bi';
import { FaPencilRuler } from 'react-icons/fa';
import { BsSpeedometer2, BsCircle, BsCircleFill, BsSpeedometer } from 'react-icons/bs';
import { AiFillStar, AiFillDelete } from 'react-icons/ai';
import { IoIosArrowDown } from 'react-icons/io';
import { MdOutlinePower, MdPeople, MdKingBed, MdKitchen } from 'react-icons/md';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import { useDispatch } from "react-redux";
import { addProduct } from "redux/actions/productAction";
import { getSubCatByCategory } from 'redux/actions/subcatAction';
import Map, { Marker, NavigationControl } from 'react-map-gl';
import { MAPBOX_TOKEN } from "utils/mapbox";
import Geocoder from 'react-mapbox-gl-geocoder';
import axios from "axios";

const VenueRental = (props) => {
    const dispatch = useDispatch();

    const categoryData = window.localStorage.getItem('@vendor_category');
    const categoryParse = JSON.parse(categoryData);

    const [subcat, setSubCat] = useState([]);
    const [sub_category_key, setSubCatKey] = useState('');
    const [sub_category_name, setSubCatName] = useState('');

    const [photo, setPhotos] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const [product_name, setProductName] = useState('');
    const [address, setAddress] = useState('');
    const [guest, setGuest] = useState(0);
    const [bedroom, setBedroom] = useState(0);
    const [bathroom, setBathroom] = useState(0);
    const [kitchen, setKitchen] = useState('no');
    const [private_pool, setPrivatePool] = useState('no');
    
    const [description, setDescription] = useState('');
    const [property_facility, setPropertyFacility] = useState('');
    const [property_policy, setPropertyPolicy] = useState('');
    const [cancellation_policy, setCancellationPolicy] = useState('');
    const [price, setPrice] = useState('');
    const [btnDisabled, setDisabled] = useState(false);

    const [requirements, setRequirements] = useState([
        {
            title: 'Set Up Time',
            isIncluded: 'No',
            value: ''
        },
        {
            title: 'Electricity',
            isIncluded: 'No',
            value: ''
        },
        {
            title: 'Session Duration',
            isIncluded: 'No',
            value: ''
        },
        {
            title: 'Dimension',
            isIncluded: 'No',
            value: ''
        },
        {
            title: 'Pick Up Time',
            isIncluded: 'No',
            value: ''
        }
    ]);

    const [viewport, setViewport] = useState({
        longitude: 39.187332584,
        latitude: 21.488498046,
        zoom: 9
    });

    useEffect(() => {
        dispatch(getSubCatByCategory(props.category_key)).then(res => {
            if(res.length === 0) {
                setSubCatKey('---');
                setSubCatName('---');
            }else {
                setSubCat(res);
            }
        });
    }, [props.category_key, props.category_name]);

    function _renderIcons(title) {
        switch(title) {
            case 'Set Up Time':
                return <BiTimeFive size={20}/>
            case 'Electricity':
                return <MdOutlinePower size={20}/>
            case 'Session Duration':
                return <BsSpeedometer size={20}/>
            case 'Dimension':
                return <FaPencilRuler size={20}/>
            case 'Pick Up Time':
                return <BiTimeFive size={20}/>
        }
    }

    function _reverseGeocode(lat, lng) {
        axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?access_token=${MAPBOX_TOKEN}`).then(res => {
            setAddress(res.data.features[0].place_name);
        });
    }

    return (
        <Modal isOpen={props.isOpen} toggle={() => _onClose()} size="lg">
            <ModalHeader toggle={() => _onClose()}>Add Product</ModalHeader>
            <ModalBody>
                <Form>
                    {subcat.length !== 0 ? 
                        <FormGroup>
                            <Label>Sub Category</Label>
                            <Input 
                                type="select" 
                                name="sub_category_key"
                                onChange={(e) => _selectSubCategory(e)}
                                >
                                <option value="">Select Category</option>
                                {subcat.map((item, k) => {
                                    return <option key={k} value={item._key}>{item.data.name}</option>
                                })}
                            </Input>
                        </FormGroup>
                    : null}

                    <div>
                        {/* Photo */}
                        <div style={{backgroundColor: '#ecf0f1', width: '100%', height: 300, borderRadius: 10, marginBottom: 10}}>
                            {_renderPhoto()}
                        </div>
                        <FormGroup>
                            <Label>Upload Photo</Label>
                            <Input type="file" name="file" onChange={(e) => _uploadPhoto(e)} accept="image/*"/>
                        </FormGroup>

                        <Row>
                            <Col md="9" xs="9">
                                <FormGroup>
                                    <Input type="text" name="product_name" value={product_name} placeholder="Product Name" onChange={(e) => setProductName(e.target.value)}/>
                                </FormGroup>
                            </Col>
                            <Col md="3" xs="3" style={{textAlign: 'right'}}>
                                5.0 <AiFillStar />
                            </Col>
                        </Row>

                        <div className="box">
                            <div className="box-inner">
                                <FormGroup>
                                    <MdPeople /> <br/>
                                    <Label>Guest</Label>
                                    <Input 
                                        type="number"
                                        name="guest"
                                        value={guest}
                                        onChange={(e) => setGuest(e.target.value)}
                                        bsSize="sm"
                                        />
                                </FormGroup>
                            </div>
                            <div className="box-inner">
                                <FormGroup>
                                    <MdKingBed /> <br/>
                                    <Label>Bedrooms</Label>
                                    <Input 
                                        type="number"
                                        name="bedroom"
                                        value={bedroom}
                                        onChange={(e) => setBedroom(e.target.value)}
                                        bsSize="sm"
                                        />
                                </FormGroup>
                            </div>
                            <div className="box-inner">
                                <FormGroup>
                                    <FaToilet /> <br/>
                                    <Label>Bathrooms</Label>
                                    <Input 
                                        type="number"
                                        name="bathroom"
                                        value={bathroom}
                                        onChange={(e) => setBathroom(e.target.value)}
                                        bsSize="sm"
                                        />
                                </FormGroup>
                            </div>
                            <div className="box-inner">
                                <FormGroup>
                                    <MdKitchen /> <br/>
                                    <Label>Kitchen</Label>
                                    <Input 
                                        type="select"
                                        name="kitchen"
                                        value={kitchen}
                                        onChange={(e) => setKitchen(e.target.value)}
                                        bsSize="sm"
                                        >
                                        <option value="no">No</option>
                                        <option value="yes">Yes</option>
                                    </Input>
                                </FormGroup>
                            </div>
                            <div className="box-inner">
                                <FormGroup>
                                    <FaSwimmingPool /> <br/>
                                    <Label>Private Pool</Label>
                                    <Input 
                                        type="select"
                                        name="private_pool"
                                        value={private_pool}
                                        onChange={(e) => setPrivatePool(e.target.value)}
                                        bsSize="sm"
                                        >
                                        <option value="no">No</option>
                                        <option value="yes">Yes</option>
                                    </Input>
                                </FormGroup>
                            </div>
                        </div>

                        <hr/>

                        <div>
                            <FormGroup>
                                <Label>Search Address</Label>
                                <Geocoder
                                    mapboxApiAccessToken={MAPBOX_TOKEN}
                                    onSelected={(v, item) => {
                                        setViewport({
                                            longitude: v.longitude,
                                            latitude: v.latitude,
                                            zoom: 9
                                        });
                                        setAddress(item.place_name)
                                    }} 
                                    viewport={viewport} 
                                    hideOnSelect={true}
                                    queryParams={{country: 'sa'}}
                                    placeholder="Search Address"
                                    />
                            </FormGroup>
                        </div>

                        <Map
                            initialViewState={viewport}
                            style={{width: '100%', height: 400, marginBottom: 30}}
                            mapStyle="mapbox://styles/mapbox/streets-v9"
                            mapboxAccessToken={MAPBOX_TOKEN}
                            onClick={(e) => {
                                setViewport({
                                    longitude: e.lngLat.lng,
                                    latitude: e.lngLat.lat,
                                    zoom: 9
                                });
                                _reverseGeocode(e.lngLat.lat, e.lngLat.lng);
                            }}
                            onMoveEnd={(e) => {
                                setViewport({
                                    longitude: e.viewState.longitude,
                                    latitude: e.viewState.latitude,
                                    zoom: e.viewState.zoom,
                                });
                                _reverseGeocode(e.viewState.latitude, e.viewState.longitude);
                            }}
                            >
                            <NavigationControl />
                            <Marker latitude={viewport.latitude} longitude={viewport.longitude}/>
                        </Map>

                        <FormGroup>
                            <Label>Detailed Address</Label>
                            <Input 
                                type="textarea"
                                name="address"
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                                />
                        </FormGroup>
                       
                        <FormGroup>
                            <Label>Description</Label>
                            <Input type="textarea" name="description" value={description} onChange={(e) => setDescription(e.target.value)}/>
                        </FormGroup>

                        <hr />

                        {requirements.map((item, k) => {
                            return(
                                <Row key={k} style={{marginBottom: 10}}>
                                    <Col md={1}>
                                        {item.isIncluded === "Yes" ? 
                                            <BsCircleFill size={18} onClick={() => _onChangeRequirements(item, k, 'isIncluded', 'No')}/> : 
                                            <BsCircle size={18} onClick={() => _onChangeRequirements(item, k, 'isIncluded', 'Yes')}/>
                                        }
                                    </Col>
                                    <Col md={3}>
                                        {_renderIcons(item.title)} {item.title}
                                    </Col> 
                                    <Col>
                                        <Input 
                                            type="text"
                                            name="value"
                                            size="sm"
                                            value={item.value}
                                            onChange={(e) => _onChangeRequirements(item, k, e.target.name, e.target.value)}
                                            />
                                    </Col>
                                </Row>
                            );
                        })}

                        <hr />

                        <Accordion>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        <Row>
                                            <Col md="8" xs="4">
                                                Property Facility
                                            </Col>
                                            <Col md="4" xs="4" style={{textAlign: "right"}}>
                                                <IoIosArrowDown size={30}/>
                                            </Col>
                                        </Row>
                                        <div className="bottomBorder"></div>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <Input placeholder="Type here..." type="textarea" name="property_facility" value={property_facility} onChange={(e) => setPropertyFacility(e.target.value)}/>
                                    <br/>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        <Row>
                                            <Col md="8" xs="4">
                                                Property Policy
                                            </Col>
                                            <Col md="4" xs="4" style={{textAlign: "right"}}>
                                                <IoIosArrowDown size={30}/>
                                            </Col>
                                        </Row>
                                        <div className="bottomBorder"></div>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <Input placeholder="Type here..." type="textarea" name="property_policy" value={property_policy} onChange={(e) => setPropertyPolicy(e.target.value)}/>
                                    <br/>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        <Row>
                                            <Col md="8" xs="4">
                                                Cancellation Policy
                                            </Col>
                                            <Col md="4" xs="4" style={{textAlign: "right"}}>
                                                <IoIosArrowDown size={30}/>
                                            </Col>
                                        </Row>
                                        <div className="bottomBorder"></div>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <Input placeholder="Type here..." type="textarea" name="cancellation_policy" value={cancellation_policy} onChange={(e) => setCancellationPolicy(e.target.value)}/>
                                </AccordionItemPanel>
                            </AccordionItem>
                        </Accordion>
                        
                        <br/><br/>

                        <FormGroup>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">SAR</InputGroupAddon>
                                <Input type="number" placeholder="Price" name="price" value={price} onChange={(e) => setPrice(e.target.value)}/>
                            </InputGroup>
                        </FormGroup>
                    </div>
                </Form>
            </ModalBody>
            <ModalFooter>
                {
                    btnDisabled === true ? <Spinner /> : 
                    <Button color="primary" onClick={() => _submit()}>Save this Product</Button>
                }
            </ModalFooter>
        </Modal>
    )

    function _onChangeRequirements(item, k, name, val) {
        var req = [...requirements];
        req[k][name] = val;
        setRequirements(req);
    }

    function _selectSubCategory(e) {
        let index = e.nativeEvent.target.selectedIndex;
        let label = e.nativeEvent.target[index].text;
        setSubCatKey(e.target.value);
        setSubCatName(label);
    }

    function _submit() {
        if(sub_category_key === '') {
            toast.error("Please select category", {
                theme: "colored"
            });
            return false;
        }

        if(photo.length === 0) {
            toast.error("Please add atleast 1 photo", {
                theme: "colored"
            });
            return false;
        }

        if(product_name === '') {
            toast.error("Please enter the product name", {
                theme: "colored"
            });
            return false;
        }

        if(address === '') {
            toast.error("Please enter or search in map the address location", {
                theme: "colored"
            });
            return false;
        }

        if(guest === '') {
            toast.error("Please enter the guest", {
                theme: "colored"
            });
            return false;
        }

        if(bedroom === '') {
            toast.error("Please enter the bedroom", {
                theme: "colored"
            });
            return false;
        }

        if(bathroom === '') {
            toast.error("Please enter the bathroom", {
                theme: "colored"
            });
            return false;
        }

        if(description === '') {
            toast.error("Please enter description", {
                theme: "colored"
            });
            return false;
        }

        if(property_facility === '') {
            toast.error("Please enter property facility", {
                theme: "colored"
            });
            return false;
        }

        if(property_policy === '') {
            toast.error("Please enter property policy", {
                theme: "colored"
            });
            return false;
        }

        if(cancellation_policy === '') {
            toast.error("Please enter cancellation policy", {
                theme: "colored"
            });
            return false;
        }

        if(price === '') {
            toast.error("Please enter the price", {
                theme: "colored"
            });
            return false;
        }

        var params = {
            category_key: props.category_key,
            category_name: props.category_name,
            sub_category_key: sub_category_key,
            sub_category_name: sub_category_name,
            target: 'rental',
            photo: photo,
            product_name: product_name,
            description: description,
            price: price,
            data: {
                address: address,
                viewport: viewport,
                guest: guest,
                bedroom: bedroom,
                bathroom: bathroom,
                kitchen: kitchen,
                private_pool: private_pool,
                property_facility: property_facility,
                property_policy: property_policy,
                cancellation_policy: cancellation_policy,
                requirements: requirements
            }
        }

        setDisabled(true);
        dispatch(addProduct(params)).then(res => {
            if(res === 'saved') {
                setDisabled(false);
                toast.success("New product successfully added and it will review by Dibrah Team", {
                    theme: "colored"
                });
                _onClose();
            }else {
                setDisabled(false);
                toast.error("An error occur please trye again later", {
                    theme: "colored"
                });
                return false;
            }
        });
    }

    function _renderPhoto() {
        if(photo.length !== 0) {
            return (
                <Carousel
                    activeIndex={activeIndex}
                    next={() => next()}
                    previous={() => previous()}
                    >
                    {photo.map((item, k) => {
                        return(
                            <CarouselItem key={k}>
                                <img src={URL.createObjectURL(item)} style={{width: '100%', height: 300, objectFit: 'cover'}}/>
                            </CarouselItem>
                        )
                    })}
                    <CarouselControl direction="prev" directionText="Previous" onClickHandler={() => previous()} />
                    <CarouselControl direction="next" directionText="Next" onClickHandler={() => next()} />
                </Carousel>
            )
        }else {
            return (
                <div className="imageSize">
                    <h3>Image size must be 400x400 pixel</h3>
                </div>
            )
        }
    }

    function _uploadPhoto(e) {
        window.removeEventListener('focus', handleFocusBack);
        if(photo.length > 10) {
            toast.error("Photos cannot be more than 10", {
                theme: "colored"
            });
            return false;
        }

        Resizer.imageFileResizer(
            e.target.files[0], 400, 400, "png", 70, 0,
            (uri) => { 
                setPhotos([...photo, uri]); 
            }, "blob", 300, 300
        );
    }

    function handleFocusBack(){
        window.removeEventListener('focus', handleFocusBack);
    }

    function _onClose() {
        props.onClose();
        setSubCat([]);
        setSubCatKey('');
        setSubCatName('');
        setPhotos([]);
        setActiveIndex(0);
        setProductName('');
        setAddress('');
        setGuest(0);
        setBedroom(0);
        setBathroom(0);
        setKitchen('no');
        setPrivatePool('no');
        setDescription('');
        setPropertyFacility('');
        setPropertyPolicy('');
        setCancellationPolicy('');
        setPrice('');
    }

    function next() {
        const nextIndex = activeIndex === photo.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    }
    
    function previous() {
        const nextIndex = activeIndex === 0 ? photo.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    }
}

export default VenueRental;