import React, { useEffect, useState } from "react";

import {
    Modal, 
    ModalHeader, 
    ModalBody, 
    ModalFooter,
    Button,
    Form, 
    FormGroup, 
    Label, 
    Input,
    Spinner
} from "reactstrap";
import { toast } from 'react-toastify';
import Resizer from "react-image-file-resizer";
import { useDispatch, useSelector } from 'react-redux';
import { addCategory, inputAction, inputActionReset, inputActionFile, inputActionColor } from 'redux/actions/categoryAction';
import { HexColorPicker } from "react-colorful";

const Add = (props) => {

    const dispatch = useDispatch();
    const category = useSelector(state => state.category);
    const [loading, setloading] = useState(false);

    return (
        <Modal isOpen={props.isOpen}>
            <ModalHeader>Add Category</ModalHeader>
            <ModalBody>
                <Form>
                    <FormGroup>
                        <Label>Icon/Photo</Label>
                        <Input 
                            type="file" 
                            name="photo" 
                            onChange={(e) => _onCropChange(e)}
                            accept="image/*"
                            />
                        <br/>
                        <p style={{fontSize: 12}}>Accepts .png file only for transparent background</p>
                    </FormGroup>
                    <FormGroup>
                        <Label>Category Name</Label>
                        <Input 
                            type="text" 
                            name="name" 
                            onChange={(e) => dispatch(inputAction(e.target))}
                            value={category.name}
                            />
                    </FormGroup>
                    <FormGroup>
                        <Label>Category Name (Arabic)</Label>
                        <Input 
                            type="text" 
                            name="name_ar" 
                            onChange={(e) => dispatch(inputAction(e.target))} 
                            style={{textAlign: "right"}}
                            value={category.name_ar}/>
                    </FormGroup>
                    <FormGroup>
                        <Label>Page Type</Label>
                        <Input 
                            type="select" 
                            name="page" 
                            onChange={(e) => dispatch(inputAction(e.target))}
                            value={category.page}>
                            <option value="shop">Shop</option>
                            <option value="categorize">Categorize</option>
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label>Category Type</Label>
                        <Input 
                            type="select" 
                            name="type" 
                            onChange={(e) => dispatch(inputAction(e.target))}
                            value={category.type}>
                            <option value="both">Both</option>
                            <option value="same_date">Same Day</option>
                            <option value="scheduled">Scheduled</option>
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label>Target</Label>
                        <Input 
                            type="select" 
                            name="target" 
                            onChange={(e) => dispatch(inputAction(e.target))}
                            value={category.target}>
                            <option value="product">Product</option>
                            <option value="services">Services</option>
                            <option value="rental">Rental</option>
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label>Commission</Label>
                        <Input 
                            type="number" 
                            name="commission" 
                            onChange={(e) => dispatch(inputAction(e.target))}
                            value={category.commission}/>
                        <i style={{fontSize: 12}}>Put 0. on front to calculate dynamically. Example 0.05 = 5%</i>
                    </FormGroup>
                    {/* <FormGroup>
                        <Label>Category Color</Label>
                        <HexColorPicker 
                            color={category.color} 
                            onChange={(color) => dispatch(inputActionColor(color))} />
                    </FormGroup> */}
                </Form>
            </ModalBody>
            <ModalFooter>
                {loading === true ? <Spinner /> : 
                    <>
                        <Button 
                            color="primary" 
                            onClick={() => _submit()}>
                                Save
                        </Button>{' '}
                        <Button color="secondary" onClick={() => _onClose()}>Cancel</Button>
                    </>
                }
            </ModalFooter>
        </Modal>
    )

    function _submit() {

        if(category.photo.length === 0) {
            toast.error("Please add category icon/photo", {
                theme: "colored"
            });
            return false;
        }

        if(category.name === '') {
            toast.error("Please enter category", {
                theme: "colored"
            });
            return false;
        }
        
        if(category.name_ar === '') {
            toast.error("Please enter category in arabic", {
                theme: "colored"
            });
            return false;
        }

        if(category.commission === '') {
            toast.error("Please enter category commission", {
                theme: "colored"
            });
            return false;
        }

        if(category.color === '') {
            toast.error("Please select category color", {
                theme: "colored"
            });
            return false;
        }

        setloading(true);

        let params = {
            photo: category.photo,
            name: category.name,
            name_ar: category.name_ar,
            page: category.page,
            type: category.type,
            target: category.target,
            commission: category.commission,
            color: category.color
        }

        dispatch(addCategory(params)).then(res => {
            if(res === 'saved') {
                dispatch(inputActionReset());
                props.onClose();
                setloading(false);
            }else {
                toast.error("An error occur", {
                    theme: "colored"
                });
                setloading(false);
            }
        });
    }

    function _onCropChange(e) {
        window.removeEventListener('focus', handleFocusBack);
        Resizer.imageFileResizer(
            e.target.files[0],
            200, 200, "png", 70, 0,
            (uri) => {
                dispatch(inputActionFile(uri))
            },
            "blob",
            200,
            200
        );
    }

    function handleFocusBack(){
        window.removeEventListener('focus', handleFocusBack);
    }

    function _onClose() {
        dispatch(inputActionReset());
        props.onClose();
    }
}

export default Add;