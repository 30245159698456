/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { Link } from "react-router-dom";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  NavItem,
  NavLink,
  Navbar,
  Nav,
  Container,
  Media,
} from "reactstrap";

const AdminNavbar = (props) => {
  return (
    <>
      <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
        <Container fluid>
          <Link
            className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
            to="/"
          >
            {props.brandText}
          </Link>
          <Nav className="align-items-center d-none d-md-flex" navbar>
            <NavItem>
              <NavLink style={{cursor: 'pointer'}} className="nav-link-icon" onClick={() => _logout()}>
                <i className="ni ni-button-power" />
                <span className="mb-0 text-sm font-weight-bold">Logout</span>
              </NavLink>
            </NavItem>
          </Nav>
        </Container>
      </Navbar>
    </>
  );

  function _logout() {
    window.localStorage.removeItem('@dibrah');
    window.localStorage.removeItem('@role');
    window.location.href = "/cpanel";
  }
};

export default AdminNavbar;
