import React, { useEffect, useState } from "react";
import Header from "components/Headers/AdminHeader";
import DataTable from 'react-data-table-component';
import {createFilter} from 'react-search-input';

import {
    Card,
    CardHeader,
    CardBody,
    Container,
    Input,
    Button,
    ButtonGroup,
    Badge
} from "reactstrap";

import { useDispatch, useSelector } from 'react-redux';
import { getPackagesItemsAdmin } from 'redux/actions/packagesAction';

import cols from './cols';
import Add from './add';
import Edit from './edit';

const Packages = (props) => {

    const dispatch = useDispatch();
    const packages = useSelector(state => state.packages);

    useEffect(() => {
        dispatch(getPackagesItemsAdmin());
    }, []);

    const [search, setSearch] = useState('');
    const [add, setAdd] = useState(false);
    const [edit, setEdit] = useState(false);
    const [selected, setSelected] = useState(null);
    
    const KEYS_TO_FILTERS = ['data.title_en', 'data.title_ar']
    const filterData = packages.data.filter(createFilter(search, KEYS_TO_FILTERS));
    
    return (
        <div>
            <Header />
            <Container className="mt--7" fluid>
                <Card>
                    <CardHeader>
                        <h3>Packages</h3>
                    </CardHeader>
                    <CardBody>
                        <Button color="primary" onClick={() => setAdd(true)}>Add Package</Button>
                        <DataTable
                            columns={cols(_renderBtn, _renderImg, _renderStatus)}
                            data={filterData}
                            progressPending={packages.loading}
                            subHeader
                            subHeaderComponent={
                                <Input
                                    placeholder="Search"
                                    onChange={(e) => setSearch(e.target.value)}
                                    style={{width: 250}}
                                    />
                            }
                            pagination
                            />
                    </CardBody>
                </Card>
            </Container>
            <Add
                isOpen={add}
                onClose={() => _onClose('add')}
                />
            {selected !== null ? 
                <Edit
                    isOpen={edit}
                    data={selected}
                    onClose={() => _onClose('edit')}
                    />
            : null}
        </div>
    )

    function _renderImg(row) {
        if(row.data.photos !== undefined) {
            var photos = [];
            Object.keys(row.data.photos).map(k => {
                photos.push(row.data.photos[k]);
            });
            return <img src={photos[0].photo} alt="" style={{width: 50, height: 50, objectFit: 'cover'}} />
        }
    }

    function _renderBtn(row) {
        return (
            <ButtonGroup>
                <Button size="sm" color="warning" onClick={() => _edit(row)}>Edit</Button>
                {/* <Button size="sm" color="info" onClick={() => _viewRating(row)}>Rating</Button> */}
            </ButtonGroup>
        );
    }

    function _renderStatus(row) {
        switch(row.data.status) {
            case 'pending':
                return <Badge color="warning">Pending</Badge>
                break;
            case 'rejected':
                return (
                    <>
                        <Badge color="danger">Rejected</Badge>
                        <br/>
                        <span>{row.data.reason}</span>
                    </>
                )
                break;
            default:
                return <Badge color="success">Approved</Badge>
                break;
        } 
    }

    function _edit(row) {
        setSelected(row);
        setEdit(true);
    }

    function _onClose(type) {
        if(type === "add") {
            setAdd(false);
        }else {
            setSelected(null);
            setEdit(false);
        }
    }
}

export default Packages;