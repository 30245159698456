import { 
    CATEGORY_DATA, 
    CATEGORY_ACTION_INPUT, 
    CATEGORY_ACTION_RESET,
    CATEGORY_ACTION_FILE,
    CATEGORY_ACTION_COLOR,
    CATEGORY_ACTION_EDIT
} from '../types';

const initialState = {
    loading: true,
    data: [],
    _key: '',
    id: '',
    photo: [],
    photo_view: '',
    name: '',
    name_ar: '',
    page: 'shop',
    type: 'both',
    target: 'product',
    commission: '',
    color: '#000000',
    status: ''
}

const categoryReducer = (state = initialState, action) => {
    switch(action.type) {
        case CATEGORY_DATA:
            return {...state, data: action.payload, loading: action.loading}
            break;
        case CATEGORY_ACTION_INPUT:
            return {...state, [action.payload.name]: action.payload.value}
            break;
        case CATEGORY_ACTION_FILE:
            return {...state, photo: action.payload}
            break;
        case CATEGORY_ACTION_COLOR:
            return {...state, color: action.payload}
            break;
        case CATEGORY_ACTION_RESET:
            return {
                ...state, 
                _key: '',
                id: '',
                photo: [],
                photo_view: '',
                name: '',
                name_ar: '',
                page: 'shop',
                type: 'both',
                target: 'product',
                commission: '',
                color: '#000000',
                status: ''
            }
            break;
        case CATEGORY_ACTION_EDIT:
            return {
                ...state, 
                _key: action.payload._key,
                id: action.payload.data.id,
                photo_view: action.payload.data.photo,
                name: action.payload.data.name,
                name_ar: action.payload.data.name_ar,
                page: action.payload.data.page,
                type: action.payload.data.type,
                target: (!action.payload.data.target) ? 'product' : action.payload.data.target,
                commission: action.payload.data.commission,
                color: action.payload.data.color,
                status: action.payload.data.status
            }
            break;
        default:
            return state;
            break;
    }
}

export default categoryReducer;