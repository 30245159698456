import React, { useEffect, useState } from "react";

import {
    Modal, 
    ModalHeader, 
    ModalBody,
    ModalFooter,
    Form,
    FormGroup, 
    Label, 
    Input,
    Row,
    Col,
    Badge,
    Button,
    Carousel,
    CarouselItem,
    CarouselControl,
    InputGroup,
    InputGroupAddon,
    Spinner
} from "reactstrap";
import Resizer from "react-image-file-resizer";
import { toast } from 'react-toastify';
import { TwitterPicker } from 'react-color';
import { BiTimeFive } from 'react-icons/bi';
import { FaPencilRuler } from 'react-icons/fa';
import { BsSpeedometer2, BsCircle, BsCircleFill, BsSpeedometer } from 'react-icons/bs';
import { AiFillStar, AiFillDelete, AiOutlineClose } from 'react-icons/ai';
import { IoIosArrowDown } from 'react-icons/io';
import { MdOutlinePower } from 'react-icons/md';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import { useDispatch } from "react-redux";
import { addProduct } from "redux/actions/productAction"; 
import { getSubCatByCategory } from 'redux/actions/subcatAction';

const Cakes = (props) => {

    const dispatch = useDispatch();

    const categoryData = window.localStorage.getItem('@vendor_category');
    const categoryParse = JSON.parse(categoryData);

    const [type, setType] = useState('ready_to_order'); // ready_to_order, customize

    const [subcat, setSubCat] = useState([]);
    const [sub_category_key, setSubCatKey] = useState('');
    const [sub_category_name, setSubCatName] = useState('');

    const [photo, setPhotos] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const [product_name, setProductName] = useState('');
    const [description, setDescription] = useState('');

    const [cakeColour, setCakeColour] = useState([]);
    const [messageColour, setMessageColour] = useState([]);

    const [special_msg_txt, setSpecialMsgTxt] = useState('');
    const [special_msg, setSpecialMsg] = useState([]);
    
    //
    // for customize
    //
    const [shapes, setShapes] = useState([]);
    const [levels_txt, setLevelsTxt] = useState('');
    const [levels, setLevels] = useState([]);
    const [flavour_txt, setFlavourTxt] = useState('');
    const [flavours, setFlavours] = useState([]);

    const [min, setMin] = useState(0);
    const [jump, setJump] = useState(0);
    const [max, setMax] = useState(0);

    const [price, setPrice] = useState('');
    const [btnDisabled, setDisabled] = useState(false);
    const [product_details, setProductDetails] = useState('');

    const [selectedCakeColour, setSelectedCakeColour] = useState('');
    const [selectedSpecialMsgColour, setSelectedSpecialMsgColour] = useState('');

    const [requirements, setRequirements] = useState([
        {
            title: 'Set Up Time',
            isIncluded: 'No',
            value: ''
        },
        {
            title: 'Electricity',
            isIncluded: 'No',
            value: ''
        },
        {
            title: 'Session Duration',
            isIncluded: 'No',
            value: ''
        },
        {
            title: 'Dimension',
            isIncluded: 'No',
            value: ''
        },
        {
            title: 'Pick Up Time',
            isIncluded: 'No',
            value: ''
        }
    ]);

    useEffect(() => {
        dispatch(getSubCatByCategory(props.category_key)).then(res => {
            if(res.length === 0) {
                setSubCatKey('---');
                setSubCatName('---');
            }else {
                setSubCat(res);
            }
        });
    }, [props.category_key, props.category_name]);

    function _renderIcons(title) {
        switch(title) {
            case 'Set Up Time':
                return <BiTimeFive size={20}/>
            case 'Electricity':
                return <MdOutlinePower size={20}/>
            case 'Session Duration':
                return <BsSpeedometer size={20}/>
            case 'Dimension':
                return <FaPencilRuler size={20}/>
            case 'Pick Up Time':
                return <BiTimeFive size={20}/>
        }
    }

    return (
        <Modal isOpen={props.isOpen} toggle={() => _onClose()} size="lg">
            <ModalHeader toggle={() => _onClose()}>Add Product</ModalHeader>
            <ModalBody>
                <Form>

                    <FormGroup>
                        <Label>Cake Type</Label>
                        <Input 
                            type="select" 
                            name="type"
                            onChange={(e) => setType(e.target.value)}
                            >
                            <option value="ready_to_order">Ready to Order</option>
                            <option value="customize">Customizable</option>
                        </Input>
                    </FormGroup>

                    {subcat.length !== 0 && type === "ready_to_order" ? 
                        <FormGroup>
                            <Label>Sub Category</Label>
                            <Input 
                                type="select" 
                                name="sub_category_key"
                                onChange={(e) => _selectSubCategory(e)}
                                >
                                <option value="">Select Sub Category</option>
                                {subcat.map((item, k) => {
                                    return <option key={k} value={item._key}>{item.data.name}</option>
                                })}
                            </Input>
                        </FormGroup>
                    : null}

                    <div>
                        {/* Photo */}
                        <div style={{backgroundColor: '#ecf0f1', width: '100%', height: 300, borderRadius: 10, marginBottom: 10}}>
                            {_renderPhoto()}
                        </div>
                        <FormGroup>
                            <Label>Upload Photo</Label>
                            <Input type="file" name="file" onChange={(e) => _uploadPhoto(e)} accept="image/*"/>
                        </FormGroup>
                        
                        <Row>
                            <Col md="9" xs="9">
                                <FormGroup>
                                    <Input type="text" name="product_name" value={product_name} placeholder="Product Name" onChange={(e) => setProductName(e.target.value)}/>
                                </FormGroup>
                            </Col>
                            <Col md="3" xs="3" style={{textAlign: 'right'}}>
                                5.0 <AiFillStar />
                            </Col>
                        </Row>

                        <FormGroup>
                            <Label>Description</Label>
                            <Input type="textarea" name="description" value={description} onChange={(e) => setDescription(e.target.value)}/>
                        </FormGroup>

                        <FormGroup>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">SAR</InputGroupAddon>
                                <Input type="number" placeholder="Price" name="price" value={price} onChange={(e) => setPrice(e.target.value)}/>
                            </InputGroup>
                        </FormGroup>

                        {type === "customize" ? 
                            <>
                                <h3>Servings Range</h3>
                                <Row>
                                    <Col md="4">
                                        <FormGroup>
                                            <Label>Minimum</Label>
                                            <Input type="number" name="min" value={min} onChange={(e) => setMin(e.target.value)}/>
                                        </FormGroup>
                                    </Col>
                                    <Col md="4">
                                        <FormGroup>
                                            <Label>Jump Range</Label>
                                            <Input type="number" name="jump" value={jump} onChange={(e) => setMin(e.target.value)}/>
                                            <i style={{fontSize: 10}}>(How many steps to jump on next range)</i>
                                        </FormGroup>
                                    </Col>
                                    <Col md="4">
                                        <FormGroup>
                                            <Label>Maximum</Label>
                                            <Input type="number" name="max" value={max} onChange={(e) => setMin(e.target.value)}/>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                
                                <h3>Cake Shapes</h3>
                                {_renderCakeShapes()}

                                <br />

                                <FormGroup>
                                    <Label>Add Cake Levels</Label>
                                    <InputGroup style={{marginBottom: 10}}>
                                        <Input type="number" name="levels_txt" value={levels_txt} onChange={(e) => setLevelsTxt(e.target.value)}/>
                                        <Button onClick={() => {
                                            setLevels([...levels, levels_txt]);
                                            setLevelsTxt('');
                                        }}>Add</Button>
                                    </InputGroup>
                                    {levels.map((item, k) => {
                                        return (
                                            <div key={k}>
                                                <FormGroup check>
                                                    <Input name="radio1" type="radio"/>{' '}
                                                    <Label check>
                                                        {item} <AiOutlineClose style={{cursor: 'pointer'}} onClick={() => {
                                                            var data = [...levels];
                                                            data.splice(k, 1);
                                                            setLevels(data);
                                                        }}/>
                                                    </Label>
                                                </FormGroup>
                                            </div>
                                        )
                                    })}
                                </FormGroup>

                                <FormGroup>
                                    <Label>Cake Flavours</Label>
                                    <InputGroup style={{marginBottom: 10}}>
                                        <Input type="text" name="flavour_txt" value={flavour_txt} onChange={(e) => setFlavourTxt(e.target.value)}/>
                                        <Button onClick={() => {
                                            setFlavours([...flavours, flavour_txt]);
                                            setFlavourTxt('');
                                        }}>Add</Button>
                                    </InputGroup>
                                    {flavours.map((item, k) => {
                                        return (
                                            <div key={k}>
                                                <FormGroup check>
                                                    <Input name="radio1" type="radio"/>{' '}
                                                    <Label check>
                                                        {item} <AiOutlineClose style={{cursor: 'pointer'}} onClick={() => {
                                                            var data = [...flavours];
                                                            data.splice(k, 1);
                                                            setFlavours(data);
                                                        }}/>
                                                    </Label>
                                                </FormGroup>
                                            </div>
                                        )
                                    })}
                                </FormGroup>
                            </>
                        : null}

                        <p>Cake Colours</p>
                        {cakeColour.length !== 0 ? 
                            <div style={{marginBottom: 20}}>
                                {cakeColour.map((item, k) => {
                                    return (
                                        <Badge key={k} pill
                                            style={{marginRight: 10, cursor: 'pointer', backgroundColor: item}} 
                                            onClick={() => _selectedCakeColour('remove', k)}
                                            >
                                            &nbsp;
                                        </Badge>
                                    )
                                })}
                            </div>
                        : null}
                        <TwitterPicker onChangeComplete={e => setSelectedCakeColour(e.hex)}/> <br/>
                        {selectedCakeColour !== '' ? 
                            <>
                                <Button size="sm" color="primary" onClick={() => _selectedCakeColour('add', '')}>Add Colour</Button><br/><br/>
                            </>
                        : null}

                        <FormGroup>
                            <Label>Special Message</Label>
                            <InputGroup style={{marginBottom: 10}}>
                                <Input type="text" name="special_msg_txt" value={special_msg_txt} onChange={(e) => setSpecialMsgTxt(e.target.value)}/>
                                <Button onClick={() => {
                                    setSpecialMsg([...special_msg, special_msg_txt]);
                                    setSpecialMsgTxt('');
                                }}>Add</Button>
                            </InputGroup>
                            {special_msg.map((item, k) => {
                                return (
                                    <div key={k}>
                                        {item} <AiOutlineClose style={{cursor: 'pointer'}} onClick={() => {
                                            var data = [...special_msg];
                                            data.splice(k, 1);
                                            setSpecialMsg(data);
                                        }}/>
                                        <br/>
                                    </div>
                                )
                            })}
                        </FormGroup>

                        <p>Special Message Colours</p>
                        {messageColour.length !== 0 ? 
                            <div style={{marginBottom: 20}}>
                                {messageColour.map((item, k) => {
                                    return (
                                        <Badge key={k} pill
                                            style={{marginRight: 10, cursor: 'pointer', backgroundColor: item}} 
                                            onClick={() => _selectedSpecialMsgColour('remove', k)}
                                            >
                                            &nbsp;
                                        </Badge>
                                    )
                                })}
                            </div>
                        : null}
                        <TwitterPicker onChangeComplete={e => setSelectedSpecialMsgColour(e.hex)}/> <br/>
                        {selectedSpecialMsgColour !== '' ? 
                            <>
                                <Button size="sm" color="primary" onClick={() => _selectedSpecialMsgColour('add', '')}>Add Colour</Button><br/><br/>
                            </>
                        : null}

                        <hr />

                        {requirements.map((item, k) => {
                            return(
                                <Row key={k} style={{marginBottom: 10}}>
                                    <Col md={1}>
                                        {item.isIncluded === "Yes" ? 
                                            <BsCircleFill size={18} onClick={() => _onChangeRequirements(item, k, 'isIncluded', 'No')}/> : 
                                            <BsCircle size={18} onClick={() => _onChangeRequirements(item, k, 'isIncluded', 'Yes')}/>
                                        }
                                    </Col>
                                    <Col md={3}>
                                        {_renderIcons(item.title)} {item.title}
                                    </Col> 
                                    <Col>
                                        <Input 
                                            type="text"
                                            name="value"
                                            size="sm"
                                            value={item.value}
                                            onChange={(e) => _onChangeRequirements(item, k, e.target.name, e.target.value)}
                                            />
                                    </Col>
                                </Row>
                            );
                        })}

                        <hr />

                        <Accordion>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        <Row>
                                            <Col md="8" xs="4">
                                                Product Details
                                            </Col>
                                            <Col md="4" xs="4" style={{textAlign: "right"}}>
                                                <IoIosArrowDown size={30}/>
                                            </Col>
                                        </Row>
                                        <div className="bottomBorder"></div>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <Input placeholder="Type here..." type="textarea" name="product_details" value={product_details} onChange={(e) => setProductDetails(e.target.value)}/>
                                    <br/>
                                </AccordionItemPanel>
                            </AccordionItem>
                        </Accordion>    
                    </div>
                </Form>
            </ModalBody>
            <ModalFooter>
                {
                    btnDisabled === true ? <Spinner /> : 
                    <Button color="primary" onClick={() => _submit()}>Save this Product</Button>
                }
            </ModalFooter>
        </Modal>
    )

    function _onChangeRequirements(item, k, name, val) {
        var req = [...requirements];
        req[k][name] = val;
        setRequirements(req);
    }

    function _selectSubCategory(e) {
        let index = e.nativeEvent.target.selectedIndex;
        let label = e.nativeEvent.target[index].text;
        setSubCatKey(e.target.value);
        setSubCatName(label);
    }

    function _submit() {
        if(type === "ready_to_order") {
            if(sub_category_key === '') {
                toast.error("Please select category", {
                    theme: "colored"
                });
                return false;
            }
        }

        if(photo.length === 0) {
            toast.error("Please add atleast 1 photo", {
                theme: "colored"
            });
            return false;
        }

        if(product_name === '') {
            toast.error("Please enter the product name", {
                theme: "colored"
            });
            return false;
        }

        if(price === '') {
            toast.error("Please enter the price", {
                theme: "colored"
            });
            return false;
        }

        if(type === "ready_to_order") {
            if(cakeColour.length === 0) {
                toast.error("Please add atleast 1 cake colour", {
                    theme: "colored"
                });
                return false;
            }

            if(messageColour.length === 0) {
                toast.error("Please add atleast 1 special message colour", {
                    theme: "colored"
                });
                return false;
            }

            if(special_msg.length === 0) {
                toast.error("Please add atleast 1 special message", {
                    theme: "colored"
                });
                return false;
            }
        }else {
            if(min === '' || min === 0) {
                toast.error("Please enter minimum range", {
                    theme: "colored"
                });
                return false;
            }
    
            if(jump === '' || jump === 0) {
                toast.error("Please enter jump range", {
                    theme: "colored"
                });
                return false;
            }
    
            if(max === '' || max === 0) {
                toast.error("Please enter maximum range", {
                    theme: "colored"
                });
                return false;
            }

            if(shapes.length === 0) {
                toast.error("Please add atleast 1 shapes", {
                    theme: "colored"
                });
                return false;
            }

            if(levels.length === 0) {
                toast.error("Please add atleast 1 levels", {
                    theme: "colored"
                });
                return false;
            }

            if(flavours.length === 0) {
                toast.error("Please add atleast 1 flavours", {
                    theme: "colored"
                });
                return false;
            }
        }
        
        var params = {
            type: type,
            category_key: props.category_key,
            category_name: props.category_name,
            sub_category_key: sub_category_key,
            sub_category_name: sub_category_name,
            target: 'product',
            photo: photo,
            product_name: product_name,
            description: description,
            price: price,
            data: {
                product_details: product_details,
                cakeColour: cakeColour,
                messageColour: messageColour,
                special_msg: special_msg,
                shapes: shapes,
                levels: levels,
                flavours: flavours,
                min: min,
                jump: jump,
                max: max,
                requirements: requirements,
            }
        }

        setDisabled(true);
        dispatch(addProduct(params)).then(res => {
            if(res === 'saved') {
                setDisabled(false);
                toast.success("New product successfully added and it will review by Dibrah Team", {
                    theme: "colored"
                });
                _onClose();
            }else {
                setDisabled(false);
                toast.error("An error occur please trye again later", {
                    theme: "colored"
                });
                return false;
            }
        });
    }

    function _renderPhoto() {
        if(photo.length !== 0) {
            return (
                <Carousel
                    activeIndex={activeIndex}
                    next={() => next()}
                    previous={() => previous()}
                    >
                    {photo.map((item, k) => {
                        return(
                            <CarouselItem key={k}>
                                <img src={URL.createObjectURL(item)} style={{width: '100%', height: 300, objectFit: 'cover'}}/>
                            </CarouselItem>
                        )
                    })}
                    <CarouselControl direction="prev" directionText="Previous" onClickHandler={() => previous()} />
                    <CarouselControl direction="next" directionText="Next" onClickHandler={() => next()} />
                </Carousel>
            )
        }else {
            return (
                <div className="imageSize">
                    <h3>Image size must be 400x400 pixel</h3>
                </div>
            )
        }
    }

    function _renderCakeShapes() {
        return (
            <>
                <FormGroup>
                    <Input 
                        type="file"
                        onChange={(e) => _submitFiles('add', e, '')}
                        accept="image/*"
                        />
                </FormGroup>
                {shapes.length !== 0 ? 
                    <Row>
                        {shapes.map((item, k) => {
                            return (
                                <Col md="3" xs="12" key={k}>
                                    <img src={URL.createObjectURL(item)} style={{width: '100%', height: 150, objectFit: 'cover', marginBottom: 10}}/>
                                    <Button block size="sm" color="danger" onClick={() => _submitFiles('remove', '', k)}>Remove</Button>
                                </Col>
                            )
                        })}
                    </Row>
                : null}
            </>
        )
    }

    function _uploadPhoto(e) {
        window.removeEventListener('focus', handleFocusBack);
        if(photo.length > 10) {
            toast.error("Photos cannot be more than 10", {
                theme: "colored"
            });
            return false;
        }

        Resizer.imageFileResizer(
            e.target.files[0], 400, 400, "png", 70, 0,
            (uri) => { 
                setPhotos([...photo, uri]); 
            }, "blob", 300, 300
        );
    }

    function _submitFiles(type, e, k) {
        window.removeEventListener('focus', handleFocusBack);
        var list = [...shapes];
        if(type === "add") {
            if(shapes.length > 20) {
                toast.error("Photos cannot be more than 20", {
                    theme: "colored"
                });
                return false;
            }
    
            Resizer.imageFileResizer(
                e.target.files[0], 400, 400, "png", 70, 0,
                (uri) => { 
                    setShapes([...shapes, uri]); 
                }, "blob", 300, 300
            );
        }else {
            list.splice(k, 1);
            setShapes(list);
        }
    }

    function _selectedCakeColour(type, k) {
        var colours = [...cakeColour];
        if(type === "add") {
            colours.push(selectedCakeColour);
            setCakeColour(colours);
            setSelectedCakeColour('');
        }else {
            colours.splice(k, 1);
            setCakeColour(colours);
        }
    }

    function _selectedSpecialMsgColour(type, k) {
        var colours = [...messageColour];
        if(type === "add") {
            colours.push(selectedSpecialMsgColour);
            setMessageColour(colours);
            setSelectedSpecialMsgColour('');
        }else {
            colours.splice(k, 1);
            setMessageColour(colours);
        }
    }

    function handleFocusBack(){
        window.removeEventListener('focus', handleFocusBack);
    }

    function _onClose() {
        props.onClose();
        setSubCat([]);
        setSubCatKey('');
        setSubCatName('');
        setPhotos([]);
        setActiveIndex(0);
        setProductName('');
        setDescription('');
        setCakeColour([]);
        setMessageColour([]);
        setSpecialMsgTxt('');
        setSpecialMsg([]);
        
        setMin('');
        setJump('');
        setMax('');

        setLevelsTxt('');
        setLevels([]);

        setShapes([]);
        setFlavourTxt('');
        setFlavours([]);
        setPrice('');
        setDisabled(false);
        setProductDetails('');
    }

    function next() {
        const nextIndex = activeIndex === photo.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    }
    
    function previous() {
        const nextIndex = activeIndex === 0 ? photo.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    }
}

export default Cakes;