const cols = (btn, _renderCountry, _renderIndustry, _renderStatus) =>  [
    {
        name: 'Photo',
        width: '100px',
        selector: row => <img src={(row.photo === "") ? 'https://st3.depositphotos.com/23594922/31822/v/600/depositphotos_318221368-stock-illustration-missing-picture-page-for-website.jpg' : row.photo} width="50" height="50" style={{objectFit: 'contain'}}/>,
    },
    {
        name: 'Order ID',
        selector: row => row.data.orderId,
    },
    {
        name: 'Name',
        selector: row => row.data.product_name,
    },
    {
        name: 'Qty',
        selector: row => row.data.qty,
    },
    {
        name: 'Delivery',
        selector: row => row.data.deliveryMethod,
    },
    {
        name: 'Total Price',
        selector: row => row.data.currency+' '+row.data.total,
    },
    {
        name: 'Date Created',
        selector: row => row.data.created_date,
    },
    {
        name: '',
        selector: row => btn(row),
        right: true
    },
];

export default cols