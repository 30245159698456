import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';

import authReducer from './reducers/authReducer';
import customerReducer from './reducers/customerReducer';
import adminReducer from './reducers/adminReducer';
import orderReducer from './reducers/orderReducer';
import categoryReducer from './reducers/categoryReducer';
import subcatReducer from './reducers/subcatReducer';
import vendorReducer from './reducers/vendorReducer';
import productReducer from './reducers/productReducer';
import moodReducer from './reducers/moodReducer';
import formReducer from './reducers/formReducer';
import offersReducer from './reducers/offersReducer';
import packagesReducer from './reducers/packagesReducer';
import discountsReducer from './reducers/discountsReducer';
import bannersReducer from './reducers/bannersReducer';
import adminPackReducer from './reducers/adminPackReducer';
import settingsReducer from './reducers/settingsReducer';
import cateringReducer from './reducers/cateringReducer';

const rootReducer = combineReducers({
    auth: authReducer,
    admin: adminReducer,
    order: orderReducer,
    category: categoryReducer,
    subcat: subcatReducer,
    customer: customerReducer,
    vendor: vendorReducer,
    product: productReducer,
    mood: moodReducer,
    form: formReducer,
    offers: offersReducer,
    packages: packagesReducer,
    discounts: discountsReducer,
    banners: bannersReducer,
    apackages: adminPackReducer,
    settings: settingsReducer,
    catering: cateringReducer
});

const store = createStore(rootReducer, applyMiddleware(thunkMiddleware));
export { store };