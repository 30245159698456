import { 
    PACKAGES_DATA,
    PACKAGES_PHOTO
} from '../types';

const initialState = {
    loading: false,
    data: [],
    photos: []
}

const packagesReducer = (state = initialState, action) => {
    switch(action.type) {
        case PACKAGES_DATA:
            return {
                ...state,
                data: action.payload,
                loading: action.loading
            }
            break;
        case PACKAGES_PHOTO:
            return {
                ...state,
                photos: action.payload,
                loading: action.loading
            }
            break;
        default:
            return state;
            break;
    }
}   

export default packagesReducer;